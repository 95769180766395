import { ReactElement, ReactNode, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Box } from 'components/box';
import { useUnmountEffect } from 'hooks';

import {
  contextualNavCollapsedState,
  contextualNavHiddenState,
  useGlobalLayoutContext,
} from './GlobalLayoutState';

export type RenderContextualNavChildrenOptions = {
  isCollapsed: boolean;
};

export type ContextualNavProps = {
  children?:
    | ReactElement
    | ReactElement[]
    | ((options: RenderContextualNavChildrenOptions) => ReactNode);
};

export function ContextualNav(props: ContextualNavProps) {
  const { children } = props;
  const { contextualNavRef } = useGlobalLayoutContext();
  const isCollapsed = useRecoilValue(contextualNavCollapsedState);
  const setHidden = useSetRecoilState(contextualNavHiddenState);

  useLayoutEffect(() => {
    setHidden(!contextualNavRef?.current?.hasChildNodes());
  });

  useUnmountEffect(() => {
    setHidden(true);
  });

  if (!contextualNavRef?.current) {
    return null;
  }

  const content = (
    <Box css={{ width: '100%', height: '100%', py: '$5' }}>
      {typeof children === 'function' ? children({ isCollapsed }) : children}
    </Box>
  );

  return createPortal(content, contextualNavRef.current);
}
