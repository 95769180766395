import { useCallback, useRef } from 'react';

import { UseOpenStateOpts, useOpenState } from './useOpenState';
import { useUnmountEffect } from './useUnmountEffect';

/**
 * Like `useOpenState`, but with the ability to schedule the state changes.
 * @param opts The `UseOpenState` options.
 * @returns
 */
export function useDelayedOpenState(opts: UseOpenStateOpts = {}) {
  const { isOpen, open, close } = useOpenState(opts);

  const openTimerRef = useRef(-1);
  const closeTimerRef = useRef(-1);

  const scheduleOpen = useCallback(
    (delay = 0) => {
      window.clearTimeout(openTimerRef.current);
      window.clearTimeout(closeTimerRef.current);

      if (delay) {
        openTimerRef.current = window.setTimeout(() => {
          open();
        }, delay);
      } else {
        open();
      }
    },
    [open]
  );

  const scheduleClose = useCallback(
    (delay = 0, check?: () => boolean) => {
      window.clearTimeout(openTimerRef.current);
      window.clearTimeout(closeTimerRef.current);

      if (delay) {
        closeTimerRef.current = window.setTimeout(() => {
          if (typeof check === 'function' && !check()) {
            return;
          }

          close();
        }, delay);
      } else {
        if (typeof check === 'function' && !check()) {
          return;
        }

        close();
      }
    },
    [close]
  );

  const reset = useCallback(() => {
    window.clearTimeout(openTimerRef.current);
    window.clearTimeout(closeTimerRef.current);
  }, []);

  useUnmountEffect(() => {
    reset();
  });

  return {
    /**
     * Whether the state is open or not.
     */
    isOpen,
    open: scheduleOpen,
    close: scheduleClose,
    reset,
  };
}

export type UseDelayedOpenStateReturn = ReturnType<typeof useDelayedOpenState>;
