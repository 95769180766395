import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

/**
 * Basic flex component.
 *
 * @example
 * <Flex>...</Flex>
 */
export const Flex = styled('div', gutters, {
  display: 'flex',
  alignItems: 'normal',
  justifyContent: 'normal',

  variants: {
    direction: {
      row: { flexDirection: 'row' },
      column: { flexDirection: 'column' },
      rowReverse: { flexDirection: 'row-reverse' },
      columnReverse: { flexDirection: 'column-reverse' },
    },
    align: {
      start: { alignItems: 'flex-start' },
      center: { alignItems: 'center' },
      end: { alignItems: 'flex-end' },
      stretch: { alignItems: 'stretch' },
      baseline: { alignItems: 'baseline' },
      normal: { alignItems: 'normal' },
    },
    justify: {
      start: { justifyContent: 'flex-start' },
      center: { justifyContent: 'center' },
      end: { justifyContent: 'flex-end' },
      normal: { flexDirection: 'normal' },
      'space-between': { justifyContent: 'space-between' },
    },
    wrap: {
      noWrap: { flexWrap: 'nowrap' },
      wrap: { flexWrap: 'wrap' },
      wrapReverse: { flexWrap: 'wrap-reverse' },
    },
    gap: {
      1: { gap: '$1' },
      2: { gap: '$2' },
      3: { gap: '$3' },
      4: { gap: '$4' },
      5: { gap: '$5' },
      6: { gap: '$6' },
      7: { gap: '$7' },
      8: { gap: '$8' },
      9: { gap: '$9' },
      10: { gap: '$10' },
      11: { gap: '$11' },
      12: { gap: '$12' },
    },
    marginBottom: {
      1: { marginBottom: '$1' },
      2: { marginBottom: '$2' },
      3: { marginBottom: '$3' },
      4: { marginBottom: '$4' },
      5: { marginBottom: '$5' },
      6: { marginBottom: '$6' },
      7: { marginBottom: '$7' },
      8: { marginBottom: '$8' },
      9: { marginBottom: '$9' },
      10: { marginBottom: '$10' },
      11: { marginBottom: '$11' },
      12: { marginBottom: '$12' },
    },
  },

  defaultVariants: {
    direction: 'row',
    align: 'center',
    wrap: 'noWrap',
  },
});

export const FlexItem = styled('div', gutters, {
  flexBasis: 'auto',

  variants: {
    shrink: {
      0: { flexShrink: 0 },
      1: { flexShrink: 1 },
      true: { flexShrink: 1 },
    },
    grow: {
      0: { flexGrow: 0 },
      1: { flexGrow: 1 },
      true: { flexGrow: 1 },
    },
    basis: {
      0: { flexBasis: '0' },
    },
    flex: {
      '1': { flex: '1' },
    },
  },
});
