import { ReactElement, cloneElement, forwardRef } from 'react';

import { chainEventHandlers } from 'utils';

import { usePopoverContext } from './PopoverContext';

export type DrawerDismissProps = {
  children: ReactElement;
};

/**
 * Component that wraps an element that will dismiss the current popover when clicked.
 */
export const PopoverDismiss = forwardRef<HTMLElement, DrawerDismissProps>(function PopoverDismiss(
  props,
  forwardedRef
) {
  const { children } = props;

  const { onClose } = usePopoverContext();

  return cloneElement(children, {
    ref: forwardedRef,
    onClick: chainEventHandlers(onClose, children.props.onClick),
  });
});
