import { TableAccessControlId, TagId } from 'interfaces';

/**
 * A strongly-typed Data View ID.
 */
export type DataViewId = number;

/**
 * Represents the Data View entity.
 */
export interface DataView {
  /**
   * Gets the data view ID.
   */
  readonly id: DataViewId;

  /**
   * Gets the data view name.
   */
  name: string;

  /**
   * Gets the data view category ID.
   */
  categoryId: number | null;

  /**
   * Gets the data view icon ID.
   */
  iconId: number | null;

  /**
   * Gets the data view icon URI.
   */
  iconUri: string | null;

  /**
   * Gets the data view connection ID.
   */
  connectionId: number;

  /**
   * Gets the related data view ID.
   */
  relatedFieldId: number | null;

  /**
   * Gets the data view description.
   */
  description: string | null;

  /**
   * Gets the data view table.
   */
  tableName: string;

  /**
   * Gets the static data view filter.
   */
  filter: string | null;

  /**
   * Gets the data view tags.
   */
  tags: string[];

  /**
   * Whether the data view is a user favorite.
   */
  isFavorite: boolean;

  /**
   * Whether the data view is MTP.
   */
  isMtp: boolean;

  /**
   * The table access control ID.
   */
  tableAccessControlId: TableAccessControlId | null;

  ordering: string | null;
  readOnly: boolean;
  refreshOnSave: boolean;
  saveWithoutOverrides: boolean;

  postSaveStoredProcedure: string | null;

  addNewRow: boolean;
  allowRowCloning: boolean;
  allowRowDeletes: boolean;
  fields: DataViewField[];
  graphs: DataViewGraph[];
  createdAtUtc: string;
  updatedAtUtc: string;
  sortOrder: number | null;
}

/**
 * Request payload used to create the data view.
 */
export type CreateDataViewPayload = Omit<
  DataView,
  | 'id'
  | 'iconUri'
  | 'createdAtUtc'
  | 'updatedAtUtc'
  | 'isFavorite'
  | 'readOnly'
  | 'tags'
  | 'graphs'
  | 'fields'
> & {
  tagIds: TagId[];
  fields: Omit<DataViewField, 'id'>[];
  graphs: Omit<DataViewGraph, 'id'>[];
};

/**
 * Represents the Data View Field Metadata entity.
 */
export interface DataViewFieldMetadata {
  [key: string]: any;
}

/**
 * Represents the Data View Field Type values
 */
export enum DataViewFieldType {
  Filter = 0,
  Variable = 1,
}

/**
 * A strongly-typed Data View Field ID.
 */
export type DataViewFieldId = number;

/**
 * Represents the Data View Field entity.
 */
export interface DataViewField {
  /**
   * Gets the field ID.
   */
  readonly id: DataViewFieldId;

  /**
   * Gets the name of the field.
   */
  readonly name: string;

  /**
   * Gets the column of the field.
   */
  readonly column: string;

  /**
   * Gets the field display name, which is either the name or column if the name is not set.
   */
  readonly displayName: string;

  /**
   * Gets the description of the field.
   */
  readonly description: string;

  /**
   * Gets the data type of the field.
   */
  readonly dataType: string;

  /**
   * Whether the field is dynamic
   */
  readonly isDynamicFilter: boolean;

  /**
   * Whether the field is a graph filter
   */
  readonly isGraphFilter: boolean;

  /**
   * Whether the field is read only.
   */
  readonly isReadonly: boolean;

  /**
   * Whether the field is a view filter
   */
  readonly isViewFilter: boolean;

  /**
   * Whether the field is hidden.
   */
  readonly isHidden: boolean;

  /**
   * Gets the fields sort order.
   */
  readonly sortOrder: number;

  /**
   * Gets the field type.
   */
  readonly type: DataViewFieldType;

  /**
   * Gets the field metadata.
   */
  readonly metadata: DataViewFieldMetadata;
}

/**
 * A strongly-typed Data View Graph Series ID.
 */
export type DataViewGraphSeriesId = number;

/**
 * Represents the  Data View Graph Series entity.
 */
export interface DataViewGraphSeries {
  /**
   * Gets the series ID.
   */
  readonly id?: DataViewGraphSeriesId;

  /**
   * Gets the series name.
   */
  readonly name: string;

  /**
   * Whether the graph is secondary
   */
  readonly isSecondary: boolean;

  /**
   * Gets the graph's data.
   */
  readonly data: string;

  /**
   * Gets the type of graph.
   */
  readonly type: 'area' | 'line' | 'column';

  /**
   * Gets the graphs colour.
   */
  readonly color: string | null;

  /**
   * Gets the graphs sort order.
   */
  readonly sortOrder: number;
}

/**
 * A strongly-typed Data View Graph ID.
 */
export type DataViewGraphId = number;

/**
 * Represents the  Data View Graph entity.
 */
export interface DataViewGraph {
  /**
   * Gets the graph ID.
   */
  readonly id: DataViewGraphId;

  /**
   * Gets the graph name.
   */
  readonly name: string;

  /**
   * Gets the graph's xAvis.
   */
  readonly xAxis: string;

  /**
   * Gets the graph's sort order
   */
  readonly sortOrder: number;

  /**
   * Gets the graph's associated series
   */
  readonly series: DataViewGraphSeries[];
}

/**
 * A strongly-typed Data View STP Detail ID.
 */
export type DataViewStpDetailId = number;

/**
 * Represents the data view STP detail entity.
 */
export interface DataViewStpDetail {
  /**
   * Gets the STP detail ID.
   */
  readonly ID: DataViewStpDetailId;

  readonly [key: string]: any;
}

/**
 * Request payload used to update an existing data view.
 */
export type UpdateDataViewPayload = CreateDataViewPayload;

export type SetDataViewFavoritedPayload = {
  isFavorite: boolean;
};

export interface ValidateSQLViewResult {
  readonly addRowStatus: number;

  readonly message: string;
}
