export type DataSourceId = number;

export interface DataSource {
  id: DataSourceId;
  name: string;
  database: string;
  server: string;
  status: DataSourceStatus;
  statusUpdated: string | null;
  userName: string;
  password: string | null;
  created: string;
  updated: string;
}

export interface CreateDataSourcePayload {
  name: string;
  database: string;
  server: string;
  userName: string;
  password: string | null;
}

export type UpdateDataSourcePayload = CreateDataSourcePayload;

export interface DataSourceSchemaColumn {
  columnName: string;
  dataType: string;
  isUniqueKey: boolean;
  isNullable: boolean;
  isUpdatable: boolean;
}

export interface DataSourceSchemaTable {
  tableName: string;
  columns: DataSourceSchemaColumn[];
  isView: boolean;
}

export type DataSourceSchema = DataSourceSchemaTable[];

export type DataSourceRecords = {
  data: Array<{
    Id: number;
    [key: string]: any;
  }>;
};

// Represents a data source's connection status.
export enum DataSourceStatus {
  // The data source connection status has yet to be determined.
  Unknown = 0,

  // The data source connection status is currently being checked.
  Pending = 1,

  // The data source connection status could not be established.
  Error = 2,

  // The data source connection status was established successfully.
  Success = 3,
}
