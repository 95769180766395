import { Flex } from 'components/flex';
import { Paginator } from 'components/paginator';

import { useEntityListContext } from './EntityListContext';

export function EntityListPagination() {
  const { query, pageCount, setPage } = useEntityListContext();

  if (!pageCount) {
    return null;
  }

  return (
    <Flex justify="center" gutterBottom="lg">
      <Paginator page={query.page} pageCount={pageCount} onPageChange={setPage} />
    </Flex>
  );
}
