import { ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';

import { useIsAdmin, useSignOut, useUserDisplayName } from 'auth';
import { Avatar } from 'components/avatar';
import { Badge } from 'components/badge';
import { Box } from 'components/box';
import { IconButton, IconButtonLink } from 'components/button';
import { Divider } from 'components/divider';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { ReactComponent as Bell } from 'components/icon/lindua/bell2.svg';
import { ReactComponent as Book } from 'components/icon/lindua/book.svg';
import { ReactComponent as Compass } from 'components/icon/lindua/compass3.svg';
import { ReactComponent as Anvil } from 'components/icon/lindua/equalizer2.svg';
import { Logo } from 'components/logo';
import { Menu, MenuItem, MenuLink, MenuList, MenuTitle, MenuTrigger } from 'components/menu';
import { Text } from 'components/text';
import { Tooltip } from 'components/tooltip';
import { useNotifications } from 'data/notifications';
import { useAppVersionQuery } from 'data/settings';
import { styled, useTheme } from 'stitches';

const Navbar = styled('nav', {
  backgroundColor: '$navbar-bg',
  color: '$navbar-text',

  justifyContent: 'space-between',
  flexShrink: 0,
  display: 'flex',

  variants: {
    orientation: {
      vertical: {
        flexDirection: 'column',
        padding: '$5 0',
        width: '4rem',
      },
      horizontal: {
        flexDirection: 'row',
        padding: '0 $5',
        height: '4rem',
      },
    },
  },
});

type GlobalNavProps = {
  children?: ReactNode;
  orientation: 'horizontal' | 'vertical';
  onDrawerSelect?(drawer: string): void;
  onPreferences?(): void;
};

export function GlobalNav(props: GlobalNavProps) {
  const { children, orientation, onDrawerSelect, onPreferences } = props;

  const theme = useTheme();
  const signOut = useSignOut();
  const userDisplayName = useUserDisplayName();
  const isAdmin = useIsAdmin();
  const { unreadCount } = useNotifications();
  const { appVersion } = useAppVersionQuery();

  useHotkeys('ctrl+q', () => onDrawerSelect?.('quickNavigation'));

  const flexDirection = orientation === 'vertical' ? 'column' : 'row';

  return (
    <Navbar orientation={orientation}>
      <Flex direction={flexDirection} align="center" gap="5">
        <Box css={{ p: '$1' }}>
          <Link to="/">
            <Logo url={(theme as any)?.logo.uri?.value} size="$8" />
          </Link>
        </Box>

        <Tooltip placement="auto" title="Quick navigation (Ctrl + Q)">
          <IconButton
            aria-label="Quick navigation"
            onClick={() => onDrawerSelect?.('quickNavigation')}
          >
            <Icon svg={<Compass />} />
          </IconButton>
        </Tooltip>
      </Flex>

      <Flex direction={flexDirection} align="center" gap="5">
        <Tooltip placement="auto" title="Documentation">
          <IconButtonLink to="/docs" target="_blank">
            <Icon svg={<Book />} />
          </IconButtonLink>
        </Tooltip>

        {isAdmin && (
          <IconButtonLink to="/admin" aria-label="Admin">
            <Icon svg={<Anvil />} />
          </IconButtonLink>
        )}

        <Tooltip placement="auto" title="Notifications">
          <Badge badgeContent={unreadCount} colorScheme="danger">
            <IconButton
              aria-label="Notifications"
              onClick={() => onDrawerSelect?.('notifications')}
            >
              <Icon svg={<Bell />} />
            </IconButton>
          </Badge>
        </Tooltip>

        <MenuTrigger>
          <Avatar
            alt={userDisplayName}
            rounded
            css={{ backgroundColor: 'white', size: '$9', fontSize: '$xs' }}
          />

          <Menu>
            <MenuList>
              <MenuTitle>
                {userDisplayName}
                <Text css={{ mt: 4 }} as="div" variant="metadata" size="xs" textTransform="none">
                  v{appVersion.version}
                </Text>
              </MenuTitle>

              <MenuLink to="/account">Profile</MenuLink>
              <MenuItem onClick={onPreferences}>Preferences</MenuItem>

              <Divider gutter />

              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </MenuTrigger>
      </Flex>

      {children}
    </Navbar>
  );
}
