import {
  format,
  formatDistanceToNow,
  formatISO,
  isAfter,
  isValid,
  parseISO,
  parseJSON,
} from 'date-fns';

export type DateLike = Date | string | number;

export type DateFormatAlias =
  | 'short'
  | 'medium'
  | 'long'
  | 'full'
  | 'shortDate'
  | 'mediumDate'
  | 'longDate'
  | 'fullDate'
  | 'input';

const formatAliases: Record<DateFormatAlias, string> = {
  short: 'M/d/yy, h:mm a',
  medium: 'MMM d, y, h:mm:ss a',
  long: 'MMMM d, y, h:mm:ss a z',
  full: 'EEEE, MMMM d, y, h:mm:ss a zzzz',
  shortDate: 'M/d/yy',
  mediumDate: 'MMM d, y',
  longDate: 'MMMM d, y',
  fullDate: 'EEEE, MMMM d, y',
  input: 'yyyy-MM-dd',
};

/**
 * Parses the given date.
 * @param date The date to parse.
 * @returns A new `Date` object.
 */
export function parseDate(date: DateLike): Date | null {
  if (date instanceof Date) {
    return date;
  } else if (Number.isInteger(date)) {
    return new Date(date);
  }

  let parsedDate: Date | null = parseJSON(date);
  if (!isValid(parsedDate)) {
    try {
      parsedDate = parseISO(date as string);
    } catch {
      parsedDate = null;
    }
  }

  return parsedDate;
}

/**
 * Formats a date.
 * @param date The date to format.
 * @param dateFormat The date format.
 * @returns The formatted date string.
 */
export function formatDate(
  date: DateLike,
  dateFormat: DateFormatAlias | string = 'mediumDate'
): string | null {
  const alias = formatAliases[dateFormat as DateFormatAlias];
  dateFormat = alias || dateFormat;

  const parsedDate = parseDate(date);
  if (!parsedDate) {
    return null;
  }

  return format(parsedDate, dateFormat);
}

/**
 * Formats a date distance relative to now.
 * @param date The date.
 * @returns The formatted date string.
 */
export function formatDateDistanceToNow(date: DateLike) {
  const parsedDate = parseDate(date);
  if (!parsedDate) {
    return null;
  }

  return formatDistanceToNow(parsedDate, {});
}

/**
 * Formats a date in ISO 8601 format.
 * @param date The date.
 * @returns The formatted date string.
 */
export function formatDateISO(date: DateLike) {
  const parsedDate = parseDate(date);
  if (!parsedDate) {
    return null;
  }

  return formatISO(parsedDate);
}

/**
 * Determines whether the given date occurs after the comparison date.
 * @param date The given date.
 * @param compareDate The date to compare to.
 * @returns True if the `date` occurs after `compareDate`; otherwise false.
 */
export function isDateAfter(date: DateLike, compareDate: DateLike) {
  const parsedDate = parseDate(date);
  const parsedCompareDate = parseDate(compareDate);
  if (!parsedDate || !parsedCompareDate) {
    return false;
  }

  return isAfter(parsedDate, parsedCompareDate);
}
