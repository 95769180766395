import { Suspense, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useRecoilState } from 'recoil';

import { currentWorkflowIdState } from 'app';
import { Box } from 'components/box';
import { IconButton } from 'components/button';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { List, ListItem } from 'components/list';
import { LoadingSpinner } from 'components/loading-spinner';
import { Text } from 'components/text';
import { Tooltip } from 'components/tooltip';
import { useWorkflowsQuery } from 'data/workflows';
import { Application, Workflow } from 'interfaces';

import { WorkflowResources } from './WorkflowResources';

export type WorkflowsProps = {
  application: Application;
};

export function Workflows(props: WorkflowsProps) {
  const { application } = props;

  const [currentWorkflowId, setCurrentWorkflowId] = useRecoilState(
    currentWorkflowIdState({ applicationId: application.id })
  );

  const { workflows } = useWorkflowsQuery(application.id);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    () => workflows?.find((x) => x.id === currentWorkflowId) ?? null
  );

  const handleResourceSelect = () => {
    setCurrentWorkflowId(selectedWorkflow!.id);
  };

  return (
    <Box css={{ py: '$6' }}>
      {selectedWorkflow ? (
        <>
          <Flex gap="3" align="center" gutterBottom css={{ px: '$2' }}>
            <Tooltip title="All workflows">
              <IconButton onClick={() => setSelectedWorkflow(null)}>
                <FiArrowLeft />
              </IconButton>
            </Tooltip>

            <Text variant="subtitle2">{selectedWorkflow.name}</Text>
          </Flex>

          <Suspense fallback={<LoadingSpinner />}>
            <WorkflowResources
              application={application!}
              workflowId={selectedWorkflow.id}
              onSelect={handleResourceSelect}
            />
          </Suspense>
        </>
      ) : workflows.length ? (
        <List hideDivider>
          {workflows?.map((item) => (
            <ListItem key={item.id} role="button" onClick={() => setSelectedWorkflow(item)}>
              <Icon size="$8" src={item.iconUri} />
              <Text size="lg">{item.name}</Text>
            </ListItem>
          ))}
        </List>
      ) : (
        <Text as="div" align="center" variant="secondary" gutter>
          No workflows available
        </Text>
      )}
    </Box>
  );
}
