import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogDismiss,
  DialogHeader,
  useDialogDismiss,
} from 'components/dialog';
import { Text } from 'components/text';
import { useDataImportRollbackMutation, useDataImportSessionQuery } from 'data/data-import-groups';
import { formatDate } from 'utils';

export type ScenarioDataImportRollbackFormValue = {
  dataImportSessionId: number;
};

export type ScenarioDataImportRollbackDialogContentProps = {
  dataImportSessionId: number;
  applicationId: number;
};

export type ScenarioDataImportRollbackDialogProps = ScenarioDataImportRollbackDialogContentProps & {
  isOpen?: boolean;
  onClose?(): void;
};

export function ScenarioDataImportRollbackDialog(props: ScenarioDataImportRollbackDialogProps) {
  const { isOpen, onClose, ...rest } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ScenarioDataImportDialogContent {...rest} />
    </Dialog>
  );
}

export function ScenarioDataImportDialogContent(props: ScenarioDataImportRollbackDialogProps) {
  const { applicationId, dataImportSessionId } = props;

  const close = useDialogDismiss();

  const [isRollBack, setIsRollBack] = useState<boolean>(false);

  const { dataImportSession } = useDataImportSessionQuery(applicationId, dataImportSessionId);
  const dataImportRollbackMutation = useDataImportRollbackMutation(
    applicationId,
    dataImportSessionId
  );

  const {
    formState: { isSubmitting, isValid },
    handleSubmit,
  } = useForm<ScenarioDataImportRollbackFormValue>({
    mode: 'onChange',
    defaultValues: {
      dataImportSessionId: dataImportSessionId,
    },
  });

  const handleValidSubmit = async (value: ScenarioDataImportRollbackFormValue) => {
    await dataImportRollbackMutation.mutateAsync();
    close();
  };

  return (
    <form onSubmit={handleSubmit(handleValidSubmit)}>
      <DialogHeader>
        {isRollBack ? <Text variant="title">Confirm Rollback</Text> : null}
      </DialogHeader>

      <DialogContent>
        {!isRollBack ? (
          <Text size="lg" css={{ color: '$neutral-700' }}>
            Data successfully uploaded for : <b>{dataImportSession?.dataImportGroupName}</b> at{' '}
            {formatDate(dataImportSession?.uploaded)}
          </Text>
        ) : (
          <Text size="lg" css={{ color: '$neutral-700' }}>
            Note: All data for <b>{dataImportSession?.dataImportGroupName}</b> will be rolled back
            to the state as at {formatDate(dataImportSession?.uploaded)}
          </Text>
        )}
      </DialogContent>

      <DialogActions>
        <DialogDismiss>
          <Button>Cancel</Button>
        </DialogDismiss>

        {!isRollBack ? (
          <Button
            colorScheme="primary"
            onClick={() => {
              setIsRollBack(true);
            }}
          >
            Roll back
          </Button>
        ) : (
          <Button type="submit" colorScheme="primary" disabled={!isValid} pending={isSubmitting}>
            {isSubmitting ? 'Rolling back imported data...' : 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </form>
  );
}
