import { CSSProperties, ReactElement, cloneElement } from 'react';

const VISUALLY_HIDDEN_STYLES: CSSProperties = {
  clip: 'rect(0 0 0 0)',
  overflow: 'hidden',
  border: 0,
  outline: 0,
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  margin: '-1px',
  height: '1px',
  width: '1px',
};

export type VisuallyHiddenProps = {
  children: ReactElement;
  isDisabled?: boolean;
};

export function VisuallyHidden(props: VisuallyHiddenProps) {
  const { children, isDisabled = false } = props;

  return cloneElement(children, {
    style: isDisabled
      ? children.props.style
      : {
          ...VISUALLY_HIDDEN_STYLES,
          ...children.props?.style,
        },
  });
}
