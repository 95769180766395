import { RefObject } from 'react';

import { useEventCallback } from 'hooks';
import { getOwnerDocument } from 'utils';

type UseListFocusOptions = {
  ref: RefObject<HTMLElement>;
  disableListWrap?: boolean;
};

type TraversalFn = (
  list: HTMLElement,
  item: Element | null,
  disableListWrap: boolean
) => HTMLElement | null;

export function useListFocus(opts: UseListFocusOptions) {
  const { ref, disableListWrap = false } = opts;

  const moveFocus = useEventCallback((traversalFn: TraversalFn, currentFocus?: Element | null) => {
    const list = ref.current;
    if (!list) {
      return;
    }

    currentFocus = currentFocus === undefined ? getOwnerDocument(list).activeElement : currentFocus;

    let wrappedOnce = false;
    let nextFocus = traversalFn(list, currentFocus, disableListWrap);

    while (nextFocus) {
      if (nextFocus === list.firstChild) {
        if (wrappedOnce) {
          return;
        }

        wrappedOnce = true;
      }

      const nextFocusDisabled =
        (nextFocus as any).disabled || nextFocus!.getAttribute('aria-disabled') === 'true';

      if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
        nextFocus = traversalFn(list, nextFocus, disableListWrap);
      } else {
        nextFocus.focus();
        return;
      }
    }
  });

  const focusItemAtIndex = (index: number) => {};

  const focusNextItem = () => {
    moveFocus(getNextItem);
  };

  const focusPreviousItem = () => {
    moveFocus(getPreviousItem);
  };

  const focusFirstItem = () => {
    moveFocus(getNextItem, null);
  };

  const focusLastItem = () => {
    moveFocus(getPreviousItem, null);
  };

  return {
    moveFocus,
    focusItemAtIndex,
    focusNextItem,
    focusPreviousItem,
    focusFirstItem,
    focusLastItem,
  };
}

function getNextItem(
  list: HTMLElement,
  item: Element | null,
  disableListWrap: boolean
): HTMLElement | null {
  if (list === item || !list.contains(item)) {
    return list.firstChild as HTMLElement;
  }

  if (item?.nextElementSibling) {
    return item.nextElementSibling as HTMLElement;
  }

  return disableListWrap ? null : (list.firstChild as HTMLElement);
}

function getPreviousItem(
  list: HTMLElement,
  item: Element | null,
  disableListWrap: boolean
): HTMLElement | null {
  if (list === item || !list.contains(item)) {
    return (disableListWrap ? list.firstChild : list.lastChild) as HTMLElement;
  }

  if (item?.previousElementSibling) {
    return item.previousElementSibling as HTMLElement;
  }

  return disableListWrap ? null : (list.lastChild as HTMLElement);
}
