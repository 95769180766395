import { KeyboardEvent, MouseEvent } from 'react';

import { createContext } from 'utils';

export type DialogContext = {
  /**
   * Whether the dialog is open.
   */
  open?: boolean;

  /**
   * Callback invoked when the dialog is closed.
   */
  onClose?(event?: MouseEvent | KeyboardEvent): void;
};

export const [DialogContextProvider, useDialogContext] = createContext<DialogContext>({
  name: 'DialogContext',
  strict: true,
});

export type UseDialogContextProviderOpts = {
  /**
   * Whether the dialog is open.
   */
  open?: boolean;

  /**
   * Callback invoked when the dialog is closed.
   */
  onClose?(event: any): void;
};

export function useDialogContextProvider(opts: UseDialogContextProviderOpts): DialogContext {
  const { open, onClose } = opts;

  return {
    open,
    onClose,
  };
}
