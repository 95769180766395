import { useEffect } from 'react';

import { useTheme } from './themeState';

export function Theme() {
  const theme = useTheme();

  useEffect(() => {
    document.body.className = '';

    if (theme?.className) {
      document.body.classList.add(theme.className);
    }
  }, [theme?.className]);

  return null;
}
