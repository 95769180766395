import { useCallback } from 'react';

import { useControlledState } from './useControlledState';

export type UseOpenStateOpts = {
  /**
   * Whether the state is opened. Use in a controlled state.
   */
  isOpen?: boolean;

  /**
   * The default open state.
   */
  defaultOpen?: boolean;

  /**
   * Callback invoked when the value is changed.
   */
  onChange?(isOpen: boolean): void;

  /**
   * Callback invoked when closed.
   */
  onClose?(): void;

  /**
   * Callback invoked when opened.
   */
  onOpen?(): void;
};

/**
 * Returns a stateful open value, and some utility functions to update it. Can be used in a
 * controlled or uncontrolled component.
 *
 * @example
 *
 * import { useOpenState } from 'hooks';
 *
 * const UncontrolledComponent = () => {
 *    const { isOpen, toggle } = useOpenState();
 *
 *    return (
 *      <button onClick={toggle}>Toggle</button>
 *      {isOpen ? <div>Menu</div> : null}
 *    );
 * }
 *
 * // Note how the parent component now controls the `isOpen` state.
 * const ControlledComponent = ({ isOpen: isOpenProp, onToggle }) => {
 *    const { isOpen } = useOpenState({ isOpen: isOpenProp });
 *
 *    return (
 *      <button onClick={onToggle}>Toggle</button>
 *      {isOpen ? <div>Menu</div> : null}
 *    );
 * }
 */
export function useOpenState(opts: UseOpenStateOpts = {}) {
  const { defaultOpen = false, isOpen: isOpenProp, onChange, onClose, onOpen } = opts;
  const [isOpen, setOpen] = useControlledState({
    value: isOpenProp,
    defaultValue: defaultOpen,
    onChange,
  });

  const open = useCallback(() => {
    setOpen(true);
    onOpen?.();
  }, [onOpen, setOpen]);

  const close = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose, setOpen]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [isOpen, open, close]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}

export type UseOpenStateReturn = ReturnType<typeof useOpenState>;
