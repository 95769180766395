export type TimeSeriesAdjustmentResult = {
  MTPId: number;
  STPId: number;
  Attribute: string;
  [k: string]: any;
};

export type AdjustmentResultColumnAbbreviations =
  | 'AA'
  | 'AF'
  | 'DV'
  | 'IA'
  | 'IF'
  | 'ICR'
  | 'UF'
  | 'UA'
  | 'FV'
  | 'TSCR'
  | 'FV'
  | 'PP';

export const AdjustmentResultColumnAbbreviationExpansions: Record<
  AdjustmentResultColumnAbbreviations,
  string
> = {
  AA: 'Adjustment Add',
  AF: 'Adjustment Factor',
  DV: 'Definition Value',
  IA: 'Import Add',
  IF: 'Import Factor',
  ICR: 'Import Cumulative Result',
  UF: 'User Factor',
  UA: 'User Add',
  FV: 'Final Value',
  TSCR: 'TS Cumulative Result',
  PP: 'Plan Period',
};
