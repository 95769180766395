import { ReactElement, cloneElement, forwardRef } from 'react';

import { useDialogDismiss } from './useDialog';

export type DialogDismissProps = {
  children: ReactElement;
};

/**
 * Wrapper component that will close the current dialog when the wrapped component is clicked.
 */
export const DialogDismiss = forwardRef<HTMLElement, DialogDismissProps>((props, forwardedRef) => {
  const { children } = props;

  const close = useDialogDismiss();

  return cloneElement(children, {
    ref: forwardedRef,
    type: 'button',
    onClick: close,
  });
});
