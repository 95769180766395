import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

export const Divider = styled('hr', gutters, {
  margin: 0,
  padding: 0,

  borderColor: '$neutral-200',
  borderStyle: 'solid',
  flexShrink: 0,

  variants: {
    orientation: {
      horizontal: {
        borderWidth: '0 0 thin',
      },
      vertical: {
        height: '100%',
        borderBottomWidth: 0,
        borderRightWidth: 'thin',
      },
    },

    flexItem: {
      true: {
        alignSelf: 'stretch',
        height: 'auto',
      },
    },
  },

  defaultVariants: {
    orientation: 'horizontal',
  },
});
