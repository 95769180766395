import { RefObject, useEffect } from 'react';

import { useCallbackRef } from 'hooks';
import { getOwnerDocument } from 'utils';

export type UseOutsideClickOpts = {
  /**
   * The node ref.
   */
  ref: RefObject<HTMLElement | null | undefined>;

  /**
   * Handler function called when an outside click is detected.
   */
  handler: (e: Event) => void;

  /**
   * Whether the listener is enabled.
   */
  enabled?: boolean;
};

/**
 * Detects when a click event is fired outside of the specified element ref.
 *
 * @example
 * import React from 'react';
 * import { useOutsideClick } from 'hooks';
 *
 * const Component = () => {
 *  const menuRef = React.useRef();
 *  const [isOpen, setOpen] = React.useState(false);
 *
 *  useOutsideClick({
 *    ref: menuRef,
 *    handler: () => setOpen(false),
 *  });
 *
 *  return (
 *    <>
 *      <button onClick={() => setOpen(true)}>Open menu</button>
 *      {isOpen ? <div ref={menuRef}>Menu</div> : null}
 *    </>
 *  )
 * };
 */
export function useOutsideClick(props: UseOutsideClickOpts) {
  const { ref, handler, enabled = true } = props;
  const handlerRef = useCallbackRef(handler);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const callback = (event: MouseEvent | TouchEvent) => {
      if (event instanceof MouseEvent && event.button > 0) {
        return;
      }

      const target = event.target as HTMLElement;
      if (
        !target ||
        !getOwnerDocument(target).body.contains(target) ||
        ref.current?.contains(target)
      ) {
        return;
      }

      handlerRef(event);
    };

    if (enabled) {
      document.addEventListener('click', callback, true);
      document.addEventListener('touchstart', callback, true);

      return () => {
        document.removeEventListener('click', callback, true);
        document.removeEventListener('touchstart', callback, true);
      };
    }
  }, [ref, enabled, handlerRef]);
}

export type UseOutsideClickReturn = ReturnType<typeof useOutsideClick>;
