export type ManagedModal = {
  mountNode?: Element;
  node?: Element;
};

export type ModalContainer = {
  container: HTMLElement;
  modals: ManagedModal[];
  restore: null | (() => void);
};

export class ModalManager {
  #modals: ManagedModal[] = [];
  #containers: ModalContainer[] = [];

  /**
   *
   * @param modal The modal to add.
   * @param container The modal container node.
   * @returns The modal ID.
   */
  add(modal: ManagedModal, container: HTMLElement): number {
    const modalIndex = this.#modals.indexOf(modal);
    if (modalIndex !== -1) {
      return modalIndex;
    }

    this.#modals.push(modal);

    if (modal.node) {
      setAriaHidden(modal.node, false);
    }

    let modalContainer = this.#containers.find((x) => x.container === container);
    if (!modalContainer) {
      modalContainer = { container, modals: [], restore: null };
      this.#containers.push(modalContainer);
    }

    modalContainer.modals.push(modal);

    return this.#modals.length - 1;
  }

  remove(modal: ManagedModal): number {
    const modalIndex = this.#modals.indexOf(modal);
    if (modalIndex === -1) {
      return modalIndex;
    }

    this.#modals.splice(modalIndex, 1);

    const container = this.#containers.find((x) => x.modals.indexOf(modal) !== -1);
    container?.modals.splice(container.modals.indexOf(modal), 1);

    if (container?.modals.length === 0) {
      container.restore?.();

      if (modal.node) {
        // aria-hidden
      }
    } else {
      const next = container?.modals[container.modals.length - 1];
      if (next?.node) {
        // aria-hidden
      }
    }

    return modalIndex;
  }

  mount(modal: ManagedModal): void {
    const container = this.#containers.find((x) => x.modals.indexOf(modal) !== -1);
    if (container && !container.restore) {
      container.restore = handleContainer(container);
    }
  }

  isTopModal(modal: ManagedModal): boolean {
    return this.#modals[this.#modals.length - 1] === modal;
  }
}

export const defaultManager = new ModalManager();

function handleContainer(container: ModalContainer): () => void {
  const restore = () => {};

  return restore;
}

export function setAriaHidden(element: Element, hidden: boolean) {
  if (hidden) {
    element.setAttribute('aria-hidden', 'true');
  } else {
    element.removeAttribute('aria-hidden');
  }
}
