import { Button } from 'components/button';
import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { Flex } from 'components/flex';
import { Switch } from 'components/switch';
import { Text } from 'components/text';
import {
  useNotifications,
  useSendTestNotification,
  useUpdateNotifications,
} from 'data/notifications';

import { NotificationList } from './NotificationList';

export type NotificationsProps = {
  isOpen?: boolean;
  onClose?(): void;
};

export function Notifications(props: NotificationsProps) {
  const { isOpen, onClose } = props;

  const notifications = useNotifications();
  const updateNotifications = useUpdateNotifications();
  const sendTestNotification = useSendTestNotification();

  const onNavigate = () => {
    onClose?.();
  };

  const toggleShowAll = (showAll: boolean) => {
    updateNotifications(showAll, 0);
  };

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <DrawerHeader>
        <Flex justify="space-between">
          <Text as="div" variant="title2">
            Notifications
          </Text>

          <Button size="sm" onClick={sendTestNotification}>
            Send test
          </Button>
        </Flex>
      </DrawerHeader>

      <DrawerContent>
        <Flex justify="end">
          <Switch checked={notifications.showAll} onChange={toggleShowAll}>
            Show all
          </Switch>
        </Flex>

        <NotificationList onNavigate={onNavigate} />
      </DrawerContent>
    </Drawer>
  );
}
