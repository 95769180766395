import {
  ReactElement,
  ReactNode,
  Ref,
  cloneElement,
  forwardRef,
  isValidElement,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useEnhancedEffect } from 'hooks';
import { assignRef, mergeRefs } from 'utils';

export type PortalProps = {
  /**
   * The portal content.
   */
  children?: ReactNode;

  /**
   * Whether the portal is disabled.
   */
  disabled?: boolean;
};

/**
 * Component used to render content outside the current DOM hierarchy.
 *
 * @example
 * <div>
 *    Main content
 *    <Portal>Content that will render outside this DOM hierarchy</Portal>
 * </div>
 */
export const Portal = forwardRef<HTMLElement, PortalProps>(function Portal(props, forwardedRef) {
  const { children, disabled } = props;

  const [mountNode, setMountNode] = useState<HTMLElement | null>(null);

  useEnhancedEffect(() => {
    if (!disabled) {
      setMountNode(document.body);
    }
  }, [disabled]);

  useEnhancedEffect(() => {
    if (mountNode && !disabled) {
      assignRef(forwardedRef, mountNode);

      return () => {
        assignRef(forwardedRef, null);
      };
    }
  }, [forwardedRef, mountNode]);

  if (disabled) {
    if (isValidElement(children)) {
      return cloneElement(children, {
        ref: mergeRefs((children as ReactElement & { ref: Ref<any> }).ref, forwardedRef),
      });
    }

    return children as any;
  }

  return mountNode ? createPortal(children, mountNode) : mountNode;
});
