import { useState } from 'react';

import { Box } from 'components/box';
import { Button } from 'components/button';
import { Link } from 'components/link';
import { List, ListItem, ListItemContent, ListItemMeta } from 'components/list';
import { Text } from 'components/text';
import { useApplicationsQuery } from 'data/applications';
import { useOrganizationsQuery } from 'data/organizations';

const ITEM_LIMIT = 5;

export type GlobalLinksProps = {
  onNavigate?(): void;
};

export function GlobalLinks(props: GlobalLinksProps) {
  const { onNavigate } = props;

  const [showAllApplications, setShowAllApplications] = useState(false);
  const [showAllOrganizations, setShowAllOrganizations] = useState(false);

  const { applications } = useApplicationsQuery();
  const { organizations } = useOrganizationsQuery();

  const toggleShowAllApplications = () => setShowAllApplications((prevValue) => !prevValue);
  const toggleShowAllOrganizations = () => setShowAllOrganizations((prevValue) => !prevValue);

  return (
    <>
      <Box gutterBottom="2xl">
        <Text as="div" variant="subtitle2" gutterBottom>
          Applications
        </Text>

        <List hideDivider css={{ marginBottom: '$2' }}>
          {(showAllApplications ? applications : [...applications].splice(0, ITEM_LIMIT)).map(
            (item) => (
              <ListItem key={item.id} disableGutters="x">
                <ListItemContent>
                  <Link to={`/${item.fullPath}`} onClick={onNavigate}>
                    {item.name}
                  </Link>

                  <ListItemMeta>{item.organization.name}</ListItemMeta>
                </ListItemContent>
              </ListItem>
            )
          )}
        </List>

        {applications.length >= ITEM_LIMIT && (
          <Button variant="link" onClick={toggleShowAllApplications}>
            {showAllApplications ? 'Show less' : 'Show more'}
          </Button>
        )}
      </Box>

      <Box>
        <Text as="div" variant="subtitle2" gutterBottom>
          Organizations
        </Text>

        <List hideDivider css={{ marginBottom: '$2' }}>
          {(showAllOrganizations ? organizations : [...organizations].splice(0, ITEM_LIMIT)).map(
            (item) => (
              <ListItem key={item.id} disableGutters="x">
                <ListItemContent>
                  <Link to={`/orgs/${item.path}`} onClick={onNavigate}>
                    {item.name}
                  </Link>
                </ListItemContent>
              </ListItem>
            )
          )}
        </List>

        {organizations.length >= ITEM_LIMIT && (
          <Button variant="link" onClick={toggleShowAllOrganizations}>
            {showAllOrganizations ? 'Show less' : 'Show more'}
          </Button>
        )}
      </Box>
    </>
  );
}
