import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

/**
 * Component to display a list item.
 */
export const ListItem = styled('li', {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  gap: '$4',
  padding: '$3',

  variants: {
    disableGutters: {
      x: { px: 0 },
      y: { py: 0 },
    },
  },
});

/**
 * Component used for displaying list-like data.
 */
export const List = styled('ul', gutters, {
  padding: 0,
  margin: 0,
  position: 'relative',

  [`${ListItem}:not(:last-of-type)`]: {
    borderBottom: '1px solid $neutral-200',
  },

  variants: {
    hideDivider: {
      true: {
        [`${ListItem}`]: {
          borderBottom: 'none !important',
        },
      },
    },
  },
});

/**
 * Primary content of a `ListItem`.
 */
export const ListItemContent = styled('div', {
  flex: '1',
});

/**
 * Metadata content of a `ListItem`, typically a child of `ListItemContent`.
 */
export const ListItemMeta = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$3',

  letterSpacing: '$sm',
  lineHeight: '$sm',
  fontWeight: '$thin',
  fontSize: '$sm',

  color: '$neutral-500',

  marginTop: '2px',
});

/**
 * An adornment for a `ListItem`, typically a sibling of `ListItemContent`.
 */
export const ListItemAdornment = styled('div', {
  flex: '0 0 auto',
});
