import { ComponentProps } from 'react';

import { css, styled } from 'stitches';

export type InputProps = ComponentProps<typeof Input>;

export const baseInputStyles = css({
  // Reset
  appearance: 'none',
  outline: 'none',
  margin: 0,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',

  width: '100%',

  fontVariantNumeric: 'tabular-nums',
  fontWeight: '$normal',
  lineHeight: '$snug',
  fontSize: '$default',
  whiteSpace: 'nowrap',

  backgroundColor: '$neutral-0',
  color: '$neutral-600',

  padding: '$2 $3',

  border: '1px solid',
  borderColor: '$neutral-300',
  borderRadius: '$default',

  boxShadow: '$sm',

  '&::placeholder': {
    color: '$neutral-400',
  },

  '&:hover': {
    borderColor: '$neutral-400',
  },

  '&:focus': {
    outline: 'none',
    borderColor: '$primary-500',
    boxShadow: '0 0 0 1px $colors$primary-500',
  },

  '&:disabled, &[aria-disabled]': {
    backgroundColor: '$neutral-50',
    borderColor: '$neutral-200',
    pointerEvents: 'none',
    color: '$neutral-400',
  },

  variants: {
    variant: {
      borderless: {
        borderColor: 'transparent !important',
        boxShadow: 'none !important',
      },
    },

    state: {
      error: {
        borderColor: '$danger-500 !important',
        boxShadow: '0 0 0 1px $colors$danger-500 !important',
      },

      warning: {
        borderColor: '$warning-500 !important',
        boxShadow: '0 0 0 1px $colors$warning-500 !important',
      },
    },
  },
});

export const Input = styled('input', baseInputStyles, {});
