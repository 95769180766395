export type Descendant<T = {}> = T & {
  disabled?: boolean;

  id?: string;
};

export type DescendantNode<T, K> = Descendant<K> & {
  node: T;

  index: number;
};

export class DescendantManager<
  T extends HTMLElement = HTMLElement,
  K extends Record<string, any> = {}
> {
  #descendants = new Map<T, DescendantNode<T, K>>();

  /**
   * Gets a node's index within the enabled descendant node.
   * @param node The node.
   * @returns The index if the node is found within the enabled descendant nodes; otherwise -1.
   */
  enabledIndexOf(node: T | null) {
    return node ? this.getEnabledDescendants().findIndex((n) => n.node.isSameNode(node)) : -1;
  }

  /**
   * Registers a given node or descendant object.
   * @param nodeOrDescendant The node or descendant object.
   * @returns
   */
  register(nodeOrDescendant: T | Descendant<K> | null) {
    if (nodeOrDescendant === null) {
      return;
    }

    if (isNode(nodeOrDescendant)) {
      return this.registerNode(nodeOrDescendant);
    }

    return (node: T | null) => this.registerNode(node, nodeOrDescendant);
  }

  /**
   * Unregister the given node.
   * @param node The node.
   */
  unregister(node: T): void {
    this.#descendants.delete(node);
    const nodes = sortNodes(Array.from(this.#descendants.keys()));
    this.assignIndex(nodes);
  }

  destroy(): void {
    this.#descendants.clear();
  }

  /**
   * Returns a sorted array of descendant nodes.
   */
  getDescendants(): DescendantNode<T, K>[] {
    return Array.from(this.#descendants.values()).sort((a, b) => a.index - b.index);
  }

  /**
   * Returns a descendant node by the given index.
   * @param index The descendant index.
   * @returns The node if found; otherwise undefined.
   */
  getDescendant(index: number): DescendantNode<T, K> | undefined {
    return this.getDescendants()[index];
  }

  /**
   * Returns a sorted array of enabled descendant nodes.
   */
  getEnabledDescendants(): DescendantNode<T, K>[] {
    return this.getDescendants().filter((n) => !n.disabled);
  }

  /**
   * Returns an enabled descendant node by the given index.
   * @param index The descendant index.
   * @returns The node if found and enabled; otherwise undefined.
   */
  getEnabledDescendant(index: number): DescendantNode<T, K> | undefined {
    return this.getEnabledDescendants()[index];
  }

  /**
   * Returns the next enabled descendant node based on the given index.
   * @param index The current index.
   * @returns The descendant node if found; otherwise undefined.
   */
  getNextEnabled(index: number, loop = true): DescendantNode<T, K> | undefined {
    const node = this.getDescendant(index);
    if (!node) {
      return;
    }

    const nodeIndex = this.enabledIndexOf(node.node);
    const nextNodeIndex = getNextIndex(nodeIndex, this.getEnabledDescendants().length, loop);

    return this.getEnabledDescendant(nextNodeIndex);
  }

  /**
   * Returns the previous enabled descendant node based on the given index.
   * @param index The current index.
   * @param loop Whether or not to loop through the descendants.
   * @returns The descendant node if found; otherwise undefined.
   */
  getPreviousEnabled(index: number, loop = true): DescendantNode<T, K> | undefined {
    const node = this.getDescendant(index);
    if (!node) {
      return;
    }

    const nodeIndex = this.enabledIndexOf(node.node);
    const prevNodeIndex = getPreviousIndex(
      nodeIndex,
      this.getEnabledDescendants().length - 1,
      loop
    );

    return this.getEnabledDescendant(prevNodeIndex);
  }

  /**
   * Returns the first enabled descendant node.
   * @returns The descendant node if found; otherwise undefined.
   */
  getFirstEnabled(): DescendantNode<T, K> | undefined {
    return this.getEnabledDescendant(0);
  }

  /**
   * Returns the last enabled descendant node.
   * @returns The descendant node if found; otherwise undefined.
   */
  getLastEnabled(): DescendantNode<T, K> | undefined {
    const descendants = this.getEnabledDescendants();
    return descendants[descendants.length - 1];
  }

  private registerNode(node: T | null, descendant?: Descendant<K>): void {
    if (!node || this.#descendants.has(node)) {
      return;
    }

    const nodes = sortNodes(Array.from(this.#descendants.keys()).concat(node));

    if (descendant?.disabled) {
      descendant.disabled = !!descendant.disabled;
    }

    const descendantNode = { node, index: -1, ...descendant };
    this.#descendants.set(node, descendantNode as DescendantNode<T, K>);

    this.assignIndex(nodes);
  }

  private assignIndex(nodes: Node[]): void {
    this.#descendants.forEach((descendant) => {
      const index = nodes.indexOf(descendant.node);
      descendant.index = index;
      descendant.node.dataset.index = descendant.index.toString();
    });
  }
}

/**
 * Sort an array of DOM nodes according to the HTML tree order
 * @see http://www.w3.org/TR/html5/infrastructure.html#tree-order
 */
function sortNodes(nodes: Node[]) {
  return nodes.sort((a, b) => {
    const compare = a.compareDocumentPosition(b);

    if (
      compare & Node.DOCUMENT_POSITION_FOLLOWING ||
      compare & Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // a < b
      return -1;
    }

    if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
      // a > b
      return 1;
    }

    if (
      compare & Node.DOCUMENT_POSITION_DISCONNECTED ||
      compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC
    ) {
      throw Error('Cannot sort the given nodes.');
    } else {
      return 0;
    }
  });
}

function isNode(el: any): el is HTMLElement {
  return typeof el == 'object' && 'nodeType' in el && el.nodeType === Node.ELEMENT_NODE;
}

function getNextIndex(currentIndex: number, max: number, loop = true) {
  let next = currentIndex + 1;
  if (loop && next >= max) {
    next = 0;
  }
  return next;
}

function getPreviousIndex(currentIndex: number, max: number, loop = true) {
  let next = currentIndex - 1;
  if (loop && next < 0) {
    next = max;
  }
  return next;
}
