import { Children, HTMLAttributes, ReactElement, Ref, cloneElement, useRef, useState } from 'react';

import { useDelayedOpenState } from 'hooks';
import { chainEventHandlers, mergeRefs } from 'utils';

import { Placement, PopoverProps } from './Popover';

export type PopoverTriggerProps = {
  /**
   * The anchor and popover element pair.
   */
  children: [anchor: ReactElement, popover: ReactElement];

  /**
   * Whether the popover is open or not.
   * @default false
   */
  open?: boolean;

  /**
   * Whether the popover is open by default or not.
   * @default false
   */
  defaultOpen?: boolean;

  /**
   * The popover placement, relative to the anchor.
   */
  placement?: Placement;

  /**
   * Whether to trigger the popover via click or mouseover.
   * @default click
   */
  trigger?: 'click' | 'hover';

  /**
   * The delay, in milliseconds, before the popover is opened when the trigger is set to 'hover'.
   * @default 200
   */
  enterDelay?: number;

  /**
   * The delay, in milliseconds, before the popover is closed when the trigger is set to 'hover'.
   * Note this will always be above 0 to cater for when a user might want to "enter" the popover.
   * @default 150
   */
  leaveDelay?: number;

  /**
   * Whether to auto focus the popover content.
   */
  autoFocus?: boolean;

  /**
   * Whether to return focus once the popover has closed.
   */
  returnFocus?: boolean;

  /**
   * Whether to disable the popover focus trap.
   */
  disableFocusTrap?: boolean;

  /**
   * Whether to disable the popover `Portal`.
   */
  disablePortal?: boolean;

  /**
   * Callback invoked when the open value changes.
   */
  onChange?(open: boolean): void;

  /**
   * Callback invoked when the popover is closed.
   */
  onClose?(): void;
};

/**
 * A wrapper component used to automatically control a popover's open state.
 *
 * @example
 *
 * <PopoverTrigger>
 *    <Button>Toggle</Button>
 *    <Popover>My popover content</Popover>
 * </PopoverTrigger>
 */
export function PopoverTrigger(props: PopoverTriggerProps) {
  const {
    children,
    placement,
    trigger = 'click',
    enterDelay = 200,
    leaveDelay: leaveDelayProp = 150,
    autoFocus,
    returnFocus,
    disablePortal,
    disableFocusTrap,
    open: openProp,
    defaultOpen = false,
    onChange,
    onClose,
  } = props;

  const [anchorNode, setAnchorNode] = useState<HTMLElement | null>(null);
  const popoverEnteredRef = useRef(false);

  const { isOpen, open, close } = useDelayedOpenState({
    isOpen: openProp,
    defaultOpen,
    onChange,
    onClose,
  });

  // Ensure the `leaveDelay` is always at least 100.
  const leaveDelay = Math.max(100, leaveDelayProp);

  const [anchor, popover] = Children.toArray(children) as (ReactElement & {
    ref: Ref<HTMLElement>;
  })[];

  const handleClose = () => {
    close();
    onClose?.();
  };

  const handleClick = () => {
    // setOpen(!isOpen);
    isOpen ? close() : open();
  };

  const anchorRefCallback = (node: HTMLElement | null) => {
    setAnchorNode(node);
  };

  const anchorProps: HTMLAttributes<HTMLElement> & { ref: Ref<any> } = {
    ref: mergeRefs(anchor.ref, anchorRefCallback),
    'aria-haspopup': 'true',
    onClick: chainEventHandlers(anchor.props.onClick, handleClick),
    ...(isOpen && { 'aria-expanded': 'true' }),
  };

  if (trigger === 'hover') {
    const handleMouseEnter = () => {
      open(enterDelay);
    };

    const handleMouseLeave = () => {
      close(leaveDelay, () => {
        // Ensure the popover content itself hasn't been `entered` by the user before closing it.
        return !popoverEnteredRef.current;
      });
    };

    anchorProps.onMouseEnter = handleMouseEnter;
    anchorProps.onMouseLeave = handleMouseLeave;
  }

  const popoverProps: HTMLAttributes<HTMLElement> & Partial<PopoverProps> = {
    anchor: anchorNode,
    open: isOpen,
    placement,
    autoFocus,
    disablePortal,
    disableFocusTrap,
    returnFocus,
    onClose: handleClose,
  };

  if (trigger === 'hover') {
    const handlePopoverMouseEnter = () => {
      popoverEnteredRef.current = true;
    };

    const handlePopoverMouseLeave = () => {
      popoverEnteredRef.current = false;
      close(leaveDelay);
    };

    popoverProps.onMouseEnter = handlePopoverMouseEnter;
    popoverProps.onMouseLeave = handlePopoverMouseLeave;
  }

  return (
    <>
      {cloneElement(anchor, anchorProps)}
      {cloneElement(popover, popoverProps)}
    </>
  );
}
