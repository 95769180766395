import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { Flex } from 'components/flex';
import { Text } from 'components/text';
import { Tooltip } from 'components/tooltip';

export type PaginatorProps = {
  /**
   * Whether to show page numbers.
   * @default true
   */
  showPageNumbers?: boolean;

  /**
   * The current page.
   * @default 1
   */
  page?: number;

  /**
   * The current page count.
   * @default 1
   */
  pageCount?: number;

  /**
   * Callback invoked when a page is changed.
   */
  onPageChange?: (page: number) => void;
};

export function Paginator(props: PaginatorProps) {
  const { page = 1, pageCount = 1, showPageNumbers = true, onPageChange } = props;

  const isPrevDisabled = page <= 1;
  const isNextDisabled = page >= pageCount;

  return (
    <Flex align="center" gap="3">
      <Tooltip title="Previous page">
        <IconButton disabled={isPrevDisabled} onClick={() => onPageChange?.(page - 1)}>
          <FiChevronLeft />
        </IconButton>
      </Tooltip>

      {showPageNumbers ? (
        <Text variant="secondary" size="sm">
          {page} of {pageCount}
        </Text>
      ) : null}

      <Tooltip title="Next page">
        <IconButton disabled={isNextDisabled} onClick={() => onPageChange?.(page + 1)}>
          <FiChevronRight />
        </IconButton>
      </Tooltip>
    </Flex>
  );
}
