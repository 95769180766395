import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IconButton, IconButtonProps } from './IconButton';

export type IconButtonLinkProps = IconButtonProps & LinkProps;

export const IconButtonLink = forwardRef<HTMLAnchorElement, IconButtonLinkProps>(
  function IconButtonLink(props, forwardedRef) {
    /**
     * TODO: Remove any cast
     */
    return <IconButton ref={forwardedRef as any} as={Link} {...(props as any)} />;
  }
);
