import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

const SIZE_VARIANTS = {
  xs: {
    fontSize: '$xs',
    letterSpacing: '$xs',
    lineHeight: '$xs',
  },
  sm: {
    fontSize: '$sm',
    letterSpacing: '$sm',
    lineHeight: '$sm',
  },
  default: {
    fontSize: '$default',
    letterSpacing: '$default',
    lineHeight: '$default',
  },
  lg: {
    fontSize: '$lg',
    letterSpacing: '$lg',
    lineHeight: '$lg',
  },
  xl: {
    fontSize: '$xl',
    letterSpacing: '$xl',
    lineHeight: '$xl',
  },
  '2xl': {
    fontSize: '$2xl',
    letterSpacing: '$2xl',
    lineHeight: '$2xl',
  },
  '3xl': {
    fontSize: '$3xl',
    letterSpacing: '$3xl',
    lineHeight: '$3xl',
  },
  '4xl': {
    fontSize: '$4xl',
    letterSpacing: '$4xl',
    lineHeight: '$4xl',
  },
  '5xl': {
    fontSize: '$5xl',
    letterSpacing: '$5xl',
    lineHeight: '$5xl',
  },
};

export const Text = styled('span', gutters, {
  // Reset

  padding: '0',
  margin: '0',

  fontVariantNumeric: 'tabular-nums',
  fontWeight: '$normal',
  lineHeight: '$default',
  fontSize: '$default',
  letterSpacing: '$default',

  variants: {
    size: SIZE_VARIANTS,
    align: {
      center: { textAlign: 'center' },
    },
    variant: {
      title: {
        ...SIZE_VARIANTS['2xl'],
        fontWeight: '$normal',
      },
      title2: {
        ...SIZE_VARIANTS['xl'],
        fontWeight: '$medium',
      },
      subtitle: {
        ...SIZE_VARIANTS['lg'],
        fontWeight: '$semibold',
      },
      subtitle2: {
        ...SIZE_VARIANTS['default'],
        fontWeight: '$semibold',
      },
      secondary: {
        color: '$neutral-500',
      },
      metadata: {
        ...SIZE_VARIANTS.sm,
        color: '$neutral-500',
        fontWeight: '$thin',
      },
    },
    colorScheme: {
      neutral: {
        color: '$neutral-500',
      },
      primary: {
        color: '$primary-500',
      },
      warning: {
        color: '$warning-500',
      },
      danger: {
        color: '$danger-500',
      },
      success: {
        color: '$success-500',
      },
    },
    truncate: {
      true: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    textTransform: {
      none: { textTransform: 'none' },
      uppercase: { textTransform: 'uppercase' },
      lowercase: { textTransform: 'lowercase' },
    },
  },
});
