import {
  ApplicationId,
  CreateReportPayload,
  Report,
  ReportEmbedData,
  ReportId,
  ReportProvider,
  UpdateReportPayload,
} from 'interfaces';

import { api, toFormData } from './api';

/**
 * Fetches the reports belonging to the specified application.
 * @param applicationId The application ID.
 */
export function getReports(applicationId: ApplicationId) {
  return api.get<Report[]>(`/applications/${applicationId}/reports`);
}

/**
 * Fetches the report providers belonging to the specified application
 * @param applicationId The application ID
 */
export function getReportProviders(applicationId: ApplicationId) {
  return api.get<ReportProvider[]>(`/applications/${applicationId}/reports/providers`);
}

/**
 * Creates a new report for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the report.
 */
export function createReport(applicationId: ApplicationId, payload: CreateReportPayload) {
  return api.post<Report>(`/applications/${applicationId}/reports`, toFormData(payload));
}

/**
 * Deletes an existing report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId the report ID.
 */
export function deleteReport(applicationId: ApplicationId, reportId: ReportId) {
  return api.delete(`/applications/${applicationId}/reports/${reportId}`);
}

/**
 * Fetches a single report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export function getReport(applicationId: ApplicationId, reportId: ReportId) {
  return api.get<Report>(`/applications/${applicationId}/reports/${reportId}`);
}

/**
 * Fetches the embed data for a report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export function getReportEmbed(applicationId: ApplicationId, reportId: ReportId) {
  return api.get<ReportEmbedData>(`/applications/${applicationId}/reports/${reportId}/embed`);
}

/**
 * Updates an existing report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 * @param payload The request payload used to update the report.
 */
export function updateReport(
  applicationId: ApplicationId,
  reportId: ReportId,
  payload: UpdateReportPayload
) {
  return api.put<Report>(`/applications/${applicationId}/reports/${reportId}`, toFormData(payload));
}

/**
 * Updates an existing report's sort order belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 * @param currentSort The report current sort order.
 * @param newSort The report updated sort order.
 */
export function setReportSortOrder(
  applicationId: ApplicationId,
  reportId: ReportId,
  currentSort: number,
  newSort: number
) {
  return api.patch<void>(`/applications/${applicationId}/reports/${reportId}/sort`, {
    currentSort,
    newSort,
  });
}

/**
 * Adds or removes the report as a favorite for the current user.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export function setReportFavorited(
  applicationId: ApplicationId,
  reportId: ReportId,
  isFavorite: boolean
) {
  return api.patch(`/applications/${applicationId}/reports/${reportId}/favorite`, {
    isFavorite,
  });
}

/**
 * Sets the tags for the specified report.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 * @param tagIds The tag IDs.
 */
export function setReportTags(applicationId: ApplicationId, reportId: ReportId, tagIds: number[]) {
  return api.patch<Report>(`/applications/${applicationId}/reports/${reportId}/tags`, { tagIds });
}
