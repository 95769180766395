import { QueryCache, QueryClient } from 'react-query';

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
      refetchOnWindowFocus: true,
      suspense: true,
      retry: 0,
    },
  },

  queryCache,
});
