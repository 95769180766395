import { styled } from 'stitches';

const LayoutPreviewNavbar = styled('div', {
  backgroundColor: '$neutral-300',
  borderRadius: '$sm',
});

const LayoutPreviewRoot = styled('div', {
  backgroundColor: 'hsl(218, 31%, 95%)',

  borderRadius: '$md',

  flexDirection: 'row',
  display: 'flex',
  gap: '$2',

  padding: '$2',
  height: '80px',

  cursor: 'pointer',

  '&[aria-selected=true]': {
    boxShadow: '0 0 0 1px $colors$primary-500',
  },

  variants: {
    orientation: {
      horizontal: {
        flexDirection: 'column',

        [`${LayoutPreviewNavbar}`]: {
          height: '$4',
        },
      },
      vertical: {
        flexDirection: 'row',
        [`${LayoutPreviewNavbar}`]: {
          height: '$4',
        },
        [`${LayoutPreviewNavbar}`]: {
          width: '$4',
        },
      },
    },
  },
});

const LayoutPreviewContent = styled('div', {
  backgroundColor: '$neutral-0',
  borderRadius: '$sm',
  flex: '1',
});

export type LayoutPreviewProps = {
  navbarColor?: any;
  selected?: boolean;
  orientation?: 'vertical' | 'horizontal';
  onSelect?(): void;
};

export function LayoutPreview(props: LayoutPreviewProps) {
  const { selected, orientation = 'horizontal', onSelect } = props;

  return (
    <LayoutPreviewRoot
      orientation={orientation}
      aria-selected={selected ? 'true' : undefined}
      onClick={onSelect}
    >
      <LayoutPreviewNavbar />

      <LayoutPreviewContent />
    </LayoutPreviewRoot>
  );
}
