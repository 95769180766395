import { useOpenState } from 'hooks';
import { createContext } from 'utils';

export type PopoverContext = {
  /**
   * Whether the popover is open.
   */
  open?: boolean;

  /**
   * Callback invoked when the popover is closed.
   */
  onClose?(): void;
};

export const [PopoverContextProvider, usePopoverContext] = createContext<PopoverContext>({
  name: 'PopoverContext',
  strict: false,
});

export type UsePopoverContextProviderOpts = {
  /**
   * Whether the popover is open.
   */
  open?: boolean;

  /**
   * Whether the popover is open by default.
   * @default false
   */
  defaultOpen?: boolean;

  /**
   * Callback invoked when the popover is closed.
   */
  onClose?(): void;
};

export function usePopoverContextProvider(opts: UsePopoverContextProviderOpts): PopoverContext {
  const { open: openProp, defaultOpen = false, onClose } = opts;

  const { isOpen } = useOpenState({
    isOpen: openProp,
    defaultOpen,
    onClose,
  });

  return {
    open: isOpen,
    onClose,
  };
}
