import { useQuery } from 'react-query';

import * as settings from 'api/settings';

/**
 * Hook used to query the application version information.
 * @returns The app version information.
 */
export function useAppVersionQuery() {
  const { data } = useQuery(['settings', 'app-version'], () => settings.getAppVersion(), {
    staleTime: Infinity,
  });

  return {
    appVersion: data!,
  };
}
