import {
  CSSProperties,
  Children,
  ComponentProps,
  ReactElement,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useEventCallback, useResizeObserver } from 'hooks';
import { styled } from 'stitches';
import { mergeProps } from 'utils';

import { TabLabelProps } from './TabLabel';
import { TabListScrollButton } from './TabListScrollButton';

const TabListRoot = styled('div', {
  display: 'flex',
  flexShrink: 0,
  overflow: 'hidden',
  marginBottom: '$3',
});

const TabListContainer = styled('div', {
  whiteSpace: 'nowrap',
  position: 'relative',
  overflow: 'hidden',
  flex: '1 1 auto',
});

const TabLabels = styled('div', {
  display: 'flex',
  flex: '1 0 auto',
  transition: 'transform 150ms ease-in',
});

const TabIndicator = styled('div', {
  position: 'absolute',
  height: '2px',
  bottom: 0,
  backgroundColor: '$primary-500',
  transition: 'width, left 150ms ease',
});

export type TabListProps = ComponentProps<typeof TabListRoot> & {
  children?: ReactElement<TabLabelProps> | ReactElement<TabLabelProps>[];
  selectedIndex?: number;
  onSelectedIndexChange?(index: number): void;
};

export function TabList(props: TabListProps) {
  const { children: childrenProp, selectedIndex, onSelectedIndexChange, ...rest } = props;

  const [paginationEnabled, setPaginationEnabled] = useState(false);
  const [paginateNextEnabled, setPaginateNextEnabled] = useState(false);
  const [paginatePrevEnabled, setPaginatePrevEnabled] = useState(false);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [transform, setTransform] = useState<string | undefined>(undefined);
  const [indicatorStyles, setIndicatorStyles] = useState<CSSProperties>({});

  const tabListContainerRef = useRef<HTMLDivElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  /**
   * Determines which of the pagination controls should be enabled based on the current scroll
   * distance and the container's width.
   */
  const updatePaginationControls = useCallback(() => {
    if (!tabListContainerRef.current || !tabListRef.current || !paginationEnabled) {
      return;
    }

    const maxScrollDistance =
      tabListRef.current.scrollWidth - tabListContainerRef.current.offsetWidth || 0;
    setPaginateNextEnabled(scrollDistance !== maxScrollDistance);
    setPaginatePrevEnabled(scrollDistance !== 0);
  }, [scrollDistance, paginationEnabled]);

  /**
   * Checks if the pagination controls should be enabled.
   */
  const checkPaginationControlsEnabled = useCallback(() => {
    if (!tabListRef.current || !rootRef.current) {
      return;
    }

    const shouldPaginate = tabListRef.current.scrollWidth > rootRef.current.offsetWidth;

    setPaginationEnabled(shouldPaginate);
    if (!shouldPaginate) {
      setScrollDistance(0);
    }

    updatePaginationControls();
  }, [updatePaginationControls]);

  const updateTabIndicatorState = useEventCallback(() => {
    const { tabListContainerMeta, selectedTabMeta } = getTabNodesMeta();
    const newIndicatorStyles: CSSProperties = {
      left: 0,
      width: 0,
    };

    if (tabListContainerMeta && selectedTabMeta) {
      newIndicatorStyles.width = selectedTabMeta.width;
      newIndicatorStyles.left =
        selectedTabMeta.left - tabListContainerMeta.left + tabListContainerMeta.scrollLeft;
    }

    if (Number.isNaN(newIndicatorStyles.width) || Number.isNaN(newIndicatorStyles.left)) {
      setIndicatorStyles(newIndicatorStyles);
    } else {
      if (
        newIndicatorStyles.width !== indicatorStyles.width ||
        newIndicatorStyles.left !== indicatorStyles.left
      ) {
        setIndicatorStyles(newIndicatorStyles);
      }
    }
  });

  /**
   * Paginates the tab list.
   */
  const paginate = useCallback(
    (direction: 'next' | 'previous') => {
      const containerWidth = tabListContainerRef.current!.offsetWidth;
      const maxScrollDistance = tabListRef.current!.scrollWidth - containerWidth;

      setScrollDistance((value) => {
        value += ((direction === 'previous' ? -1 : 1) * containerWidth) / 3;
        return Math.max(0, Math.min(maxScrollDistance, value));
      });

      updateTabIndicatorState();
    },
    [updateTabIndicatorState]
  );

  /**
   * Returns meta information on both the tab list container and currently selected tab element node.
   */
  const getTabNodesMeta = () => {
    let selectedTabMeta;
    let tabListMeta;
    let tabListContainerMeta;

    const containerNode = tabListContainerRef.current;
    if (containerNode) {
      const rect = containerNode.getBoundingClientRect();
      tabListContainerMeta = {
        scrollLeft: containerNode.scrollLeft,
        left: rect.left,
      };
    }

    const children = tabListRef.current!.children;
    if (selectedIndex !== undefined && children.length) {
      const tab = children[selectedIndex];
      selectedTabMeta = tab?.getBoundingClientRect() ?? null;
    }

    return {
      tabListMeta,
      tabListContainerMeta,
      selectedTabMeta,
    };
  };

  const children = Children.map(childrenProp, (child, index) => {
    if (!isValidElement(child)) {
      return null;
    }

    return cloneElement(
      child,
      mergeProps(child.props, {
        selected: selectedIndex === index,
        onClick: () => onSelectedIndexChange?.(index),
      })
    );
  });

  useEffect(() => {
    let updatedScrollDistance = scrollDistance;
    if (scrollDistance !== 0) {
      const containerWidth = tabListContainerRef.current!.offsetWidth;
      const labelsWidth = Array.from(tabListRef.current!.childNodes).reduce(
        (totalWidth, node) => (totalWidth += (node as HTMLElement).offsetWidth),
        0
      );

      updatedScrollDistance = Math.min(Math.max(labelsWidth - containerWidth, 0), scrollDistance);
    }

    setTransform(`translateX(${-updatedScrollDistance}px)`);
  }, [scrollDistance]);

  useLayoutEffect(() => {
    updateTabIndicatorState();
  });

  useResizeObserver(tabListRef, {
    onResize: () => {
      checkPaginationControlsEnabled();
    },
  });

  return (
    <TabListRoot
      ref={rootRef}
      // TODO: Remove any cast
      {...(rest as any)}
    >
      {paginationEnabled ? (
        <TabListScrollButton disabled={!paginatePrevEnabled} onClick={() => paginate('previous')} />
      ) : null}

      <TabListContainer ref={tabListContainerRef}>
        <TabLabels ref={tabListRef} role="tablist" style={{ transform }}>
          {children}
        </TabLabels>

        <TabIndicator style={indicatorStyles} />
      </TabListContainer>

      {paginationEnabled ? (
        <TabListScrollButton
          disabled={!paginateNextEnabled}
          direction="next"
          onClick={() => paginate('next')}
        />
      ) : null}
    </TabListRoot>
  );
}
