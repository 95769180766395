import { DropdownButton } from 'components/dropdown-button';
import { Menu, MenuOptionGroup, MenuOptionItem, MenuTrigger } from 'components/menu';

import { useEntityListContext } from './EntityListContext';

export function EntityListSort() {
  const { sorting, query, setSorting } = useEntityListContext();

  const updateSorting = (prop: string) => {
    setSorting(prop, query.sortProp === prop && query.sortDir === 'asc' ? 'desc' : 'asc');
  };

  if (!sorting) {
    return null;
  }

  return (
    <MenuTrigger>
      <DropdownButton>Sort</DropdownButton>

      <Menu>
        <MenuOptionGroup value={query.sortProp} onChange={updateSorting}>
          {sorting.map((item) => (
            <MenuOptionItem key={String(item.key)} value={item.key}>
              {item.label || item.key}
            </MenuOptionItem>
          ))}
        </MenuOptionGroup>
      </Menu>
    </MenuTrigger>
  );
}
