import { useEffect, useState } from 'react';

import { parseDate } from 'utils';

export type UseDurationOptions = {
  duration?: number | null;
  startTime?: Date | string | null;
  endTime?: Date | string | null;
};

export function useDuration(opts: UseDurationOptions) {
  const { duration: durationProp, startTime } = opts;

  const [duration, setDuration] = useState<number | null>(() => {
    if (durationProp !== undefined && durationProp !== null) return durationProp;
    return startTime ? Math.floor((Date.now() - parseDate(startTime!)!.getTime()) / 1000) : null;
  });

  useEffect(() => {
    if ((durationProp !== undefined && durationProp !== null) || !startTime) {
      return;
    }

    const intervalId = window.setInterval(() => {
      setDuration(Math.floor((Date.now() - parseDate(startTime!)!.getTime()) / 1000));
    }, 1000);

    return () => window.clearInterval(intervalId);
  }, [durationProp, startTime]);

  if (duration === null) {
    return null;
  } else if (duration < 60) {
    return `${duration}s`;
  } else if (duration < 3600) {
    return `${Math.floor(duration / 60)}m ${duration % 60}s`;
  } else {
    return `${Math.floor(duration / 3600)}h ${Math.floor((duration % 3600) / 60)}m`;
  }
}
