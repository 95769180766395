import { css } from '../stitches';

export const gutters = css({
  variants: {
    gutter: {
      xs: { marginTop: '$1', marginBottom: '$1' },
      sm: { marginTop: '$2', marginBottom: '$2' },
      true: { marginTop: '$3', marginBottom: '$3' },
      lg: { marginTop: '$5', marginBottom: '$5' },
      xl: { marginTop: '$7', marginBottom: '$7' },
    },

    gutterTop: {
      xs: { marginTop: '$1' },
      sm: { marginTop: '$2' },
      true: { marginTop: '$3' },
    },

    gutterBottom: {
      xs: { marginBottom: '$1' },
      sm: { marginBottom: '$2' },
      true: { marginBottom: '$3' },
      lg: { marginBottom: '$5' },
      xl: { marginBottom: '$7' },
      '2xl': { marginBottom: '$9' },
    },
  },
});
