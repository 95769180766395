import { RefObject } from 'react';
import { atom } from 'recoil';

import { createContext, createLocalStorageEffect } from 'utils';

export type GlobalLayoutContext = {
  contextualNavRef: RefObject<HTMLDivElement>;
};

export const [GlobalLayoutContextProvider, useGlobalLayoutContext] =
  createContext<GlobalLayoutContext>({
    name: 'GlobalLayoutContext',
  });

/**
 * Gets or sets the layout state that determines if the contextual navigation is collapsed.
 */
export const contextualNavCollapsedState = atom<boolean>({
  key: 'globalLayout.contextualNavCollapsed',
  default: false,
  effects: [createLocalStorageEffect('globalLayout.contextualNavCollapsed')],
});

/**
 * Gets or sets the layout state that determines if the contextual navigation is hidden.
 */
export const contextualNavHiddenState = atom<boolean>({
  key: 'globalLayout.contextualNavHidden',
  default: true,
});
