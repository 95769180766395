import { globalCss } from './stitches';

import './preflight.css';

export const useGlobalStyles = globalCss({
  'html, body': {
    height: '100%',
    width: '100%',
    fontSize: '14px',
    overflow: 'hidden',
  },

  body: {
    fontFamily:
      "Inter var, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    backgroundColor: '$global-bg',
    color: '$global-text',
  },

  '#root': {
    height: '100vh',
    width: '100vw',
    fontSize: '14px',
    overflow: 'hidden',
  },

  // Used for GoJS license validation
  '.gshHeader': {
    borderColor: '#edb9a9',
    fontSize: 16,
    marginBottom: 3,
  },
});
