import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';

/**
 * Hook that manages a pending state for an async callback.
 * @param callback The callback to invoke.
 */
export function useAsyncCallback<T extends (...args: any[]) => Promise<any>>(
  callback: T,
  dependencyList: DependencyList
) {
  const [isPending, setPending] = useState(false);
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const fn = useCallback(async (...args) => {
    setPending(true);
    await callback(...args);

    // Don't update the state if the component is no longer mounted.
    if (isMountedRef.current) {
      setPending(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);

  return [fn, isPending] as [T, boolean];
}
