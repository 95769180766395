import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { ReactElement, Ref, forwardRef } from 'react';

import { SlideDirection, TransitionEasings, Variants, slideTransition, withDelay } from './utils';

export type SlideOptions = {
  direction?: SlideDirection;
};

const defaultTransition = {
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeInOut,
  },
  enter: {
    type: 'spring',
    damping: 25,
    stiffness: 180,
  },
};

const variants: Variants<SlideOptions> = {
  enter: ({ transition, transitionEnd, direction, delay } = {}) => ({
    // scale: 1,
    opacity: 1,
    ...slideTransition({ direction }).enter,
    transition: transition?.enter ?? withDelay.enter(defaultTransition.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ transition, transitionEnd, direction, delay } = {}) => ({
    opacity: 0,
    // scale: 0.85,
    ...slideTransition({ direction }).exit,
    transition: transition?.exit ?? withDelay.exit(defaultTransition.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

export const slideFadeConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants,
};

export type SlideFadeProps = HTMLMotionProps<'div'> &
  SlideOptions & {
    children?:
      | ReactElement
      | ((motionProps: HTMLMotionProps<'div'>, ref: Ref<HTMLDivElement>) => ReactElement);
    in?: boolean;
  };

export type SlideFadePresenceProps = SlideFadeProps & {
  unmountOnExit?: boolean;
};

export const SlideFade = forwardRef<HTMLDivElement, SlideFadeProps>(function SlideFade(props, ref) {
  const { in: isOpen, direction = 'right', children, transition, style, ...rest } = props;

  const custom = {
    direction,
    transition,
  };

  const animate = isOpen ? 'enter' : 'exit';

  const motionProps: any = {
    custom,
    ...slideFadeConfig,
    animate,
    style: {
      position: 'fixed',
      ...slideTransition({ direction }).position,
      ...style,
    },
    ...rest,
  };

  return typeof children === 'function' ? (
    children(motionProps, ref)
  ) : (
    <motion.div {...motionProps}>{children}</motion.div>
  );
});

export const SlideFadePresence = forwardRef<HTMLDivElement, SlideFadePresenceProps>(
  function SlideFade(props, ref) {
    const { in: isOpen, unmountOnExit, transition, ...rest } = props;

    const custom = {
      transition,
    };

    const show = unmountOnExit ? isOpen && unmountOnExit : true;

    return (
      <AnimatePresence custom={custom}>
        {show && <SlideFade in={isOpen} custom={custom} {...rest} />}
      </AnimatePresence>
    );
  }
);
