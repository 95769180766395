import { ChangeEvent, forwardRef } from 'react';
import { FiSearch } from 'react-icons/fi';

import { Input, InputProps } from 'components/input';
import { styled } from 'stitches';

const SearchIcon = styled(FiSearch, {
  width: '$4',
  height: '$4',
  position: 'absolute',
  color: '$neutral-400',
  left: 'calc($sizes$3)',
  top: '50%',
  transform: 'translateY(-50%)',
});

const SearchInputRoot = styled('div', {
  position: 'relative',

  '&:focus-within': {
    [`& > ${SearchIcon}`]: {
      color: '$primary-500',
    },
  },
});

const StyledSearchInput = styled(Input, {
  boxShadow: 'none',
  backgroundColor: '$neutral-200',
  paddingLeft: '$10',

  '&:focus': {
    backgroundColor: '$neutral-0',
  },
});

export type SearchInputProps = InputProps & {
  /**
   * Callback invoked when the search value changes.
   * @param value The search value.
   */
  onSearch?(value: string): void;
};

/**
 * Input component used primarily for searching.
 *
 * @example
 * <SearchInput onChange={handleSearch} />
 */
export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  props,
  forwardedRef
) {
  const { placeholder = 'Search', onChange, onSearch, ...rest } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    onSearch?.(e.target.value);
  };

  return (
    <SearchInputRoot>
      <SearchIcon />
      {/** TODO: Remove any cast */}
      <StyledSearchInput
        ref={forwardedRef}
        role="searchbox"
        placeholder={placeholder}
        onChange={handleChange}
        {...(rest as any)}
      />
    </SearchInputRoot>
  );
});
