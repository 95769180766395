import {
  AtomEffect,
  DefaultValue,
  ReadWriteSelectorFamilyOptions,
  RecoilState,
  RecoilValue,
  SerializableParam,
  atomFamily,
  selectorFamily,
} from 'recoil';

export type CreateAtomFamilyOptions<T, P extends SerializableParam> = {
  key: string;
  defaultValue: T | Promise<T> | RecoilValue<T> | ((param: P) => T | Promise<T> | RecoilValue<T>);
  effects?: ReadonlyArray<AtomEffect<T>> | ((param: P) => ReadonlyArray<AtomEffect<T>>);
};

export type CreateSelectorFamilyOptions<T, P extends SerializableParam> = Omit<
  Partial<ReadWriteSelectorFamilyOptions<T, P>>,
  'key'
>;

export type CreateAtomFamilyReturn<T, P extends SerializableParam> = {
  atom: (param: P) => RecoilState<T>;
  createKeyedSelectorFamily: <K extends keyof T>(
    key: K,
    opts?: CreateSelectorFamilyOptions<T[K], P>
  ) => (param: P) => RecoilState<T[K]>;
};

export function createAtomFamily<T, P extends SerializableParam>(
  options: CreateAtomFamilyOptions<T, P>
) {
  const atom = atomFamily<T, P>({
    key: options.key,
    default: options.defaultValue,
    effects: options.effects,
  });

  function createKeyedSelectorFamily<K extends keyof T>(
    selectorKey: K,
    opts?: CreateSelectorFamilyOptions<T[K], P>
  ): (param: P) => RecoilState<T[K]> {
    return selectorFamily({
      key: `${options.key}-${selectorKey}`,
      get:
        (param) =>
        ({ get }) =>
          get(atom(param))[selectorKey],
      set:
        (param) =>
        ({ set }, value) => {
          set(atom(param), (prevValue) => {
            if (prevValue[selectorKey] === value) {
              return prevValue;
            }

            return { ...prevValue, [selectorKey]: value };
          });
        },
      ...opts,
    });
  }

  return { atom, createKeyedSelectorFamily } as CreateAtomFamilyReturn<T, P>;
}

function createStorageEffect(storage: Storage): <T>(storageKey: string) => AtomEffect<T> {
  return (storageKey: string) =>
    ({ setSelf, onSet }) => {
      const savedValue = storage.getItem(storageKey);
      if (savedValue !== null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          storage.removeItem(storageKey);
        } else {
          storage.setItem(storageKey, JSON.stringify(newValue));
        }
      });
    };
}

export function createLocalStorageEffect<T>(storageKey: string): AtomEffect<T> {
  return createStorageEffect(localStorage)(storageKey);
}

export function createSessionStorageEffect<T>(storageKey: string): AtomEffect<T> {
  return createStorageEffect(sessionStorage)(storageKey);
}
