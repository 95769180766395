import { MouseEvent, forwardRef, useRef, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { useMergedRefs } from 'hooks';
import { styled } from 'stitches';

const ResizeHandleRoot = styled('div', {
  position: 'absolute',
  bottom: '0',
  left: '100%',
  top: '0',
  width: '24px',

  zIndex: 5,
});

const ResizeHandleHandle = styled('div', {
  backgroundColor: 'transparent',

  position: 'absolute',
  height: '100%',
  width: '2px',
  left: '3px',

  opacity: 1,

  transition: 'opacity, background 50ms ease 75ms',

  '[data-dragging=true] &': {
    background: '$primary-500 !important',
  },
});

const ResizeHandleContainer = styled('div', {
  position: 'relative',
  cursor: 'ew-resize',
  left: '-4px',
  height: '100%',
  width: '100%',

  '&:hover': {
    [`${ResizeHandleHandle}`]: {
      background: '$neutral-200',
    },
  },
});

const CollapseButton = styled(IconButton, {
  position: 'absolute',
  transform: 'translateX(calc(-50% + 4px))',

  padding: '3px !important',

  top: '$7',

  color: '$neutral-400',

  opacity: 0,
  transition: 'all 50ms ease-in 75ms',

  '[data-collapsed=true] &': {
    opacity: '1',
    transform: 'translateX(calc(-50% - 1px))',
  },

  [`${ResizeHandleContainer}:hover &`]: {
    opacity: 1,
    backgroundColor: '$neutral-0',
    boxShadow: '$default',
    color: '$neutral-500',
    transform: 'translateX(calc(-50% + 4px))',
  },

  '[data-dragging=true] &': {
    opacity: '0 !important',
  },

  '&:active': {
    color: '$neutral-700 !important',
  },
});

export type ResizeHandleProps = {
  collapsible?: boolean;

  collapsed?: boolean;

  onDrag?(event: DraggableEvent, data: DraggableData): void;

  onCollapsedChange?(collapsed: boolean): void;
};

export const ResizeHandle = forwardRef<HTMLDivElement, ResizeHandleProps>(function ResizeHandle(
  props,
  forwardedRef
) {
  const { collapsible = false, collapsed = false, onCollapsedChange, onDrag } = props;

  const [isDragging, setDragging] = useState(false);

  const resizeHandleRef = useRef<HTMLDivElement>(null);
  const rootRef = useMergedRefs(forwardedRef, resizeHandleRef);

  const handleDoubleClick = (event: MouseEvent) => {
    if (collapsible) {
      event.preventDefault();
      onCollapsedChange?.(!collapsed);
    }
  };

  const handleCollapseButtonMouseDown = (event: MouseEvent) => {
    /**
     * Prevent the button from triggering the drag.
     */
    event.stopPropagation();
    event.preventDefault();
  };

  const handleCollapseButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    onCollapsedChange?.(!collapsed);
  };

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragStop = () => {
    setDragging(false);
  };

  return (
    <DraggableCore
      nodeRef={resizeHandleRef}
      onDrag={onDrag}
      onStart={handleDragStart}
      onStop={handleDragStop}
    >
      <ResizeHandleRoot
        ref={rootRef}
        data-collapsed={collapsed}
        data-dragging={isDragging}
        onDoubleClick={handleDoubleClick}
      >
        <ResizeHandleContainer>
          <ResizeHandleHandle />

          {collapsible && (
            <CollapseButton
              tabIndex={-1}
              onClick={handleCollapseButtonClick}
              onMouseDown={handleCollapseButtonMouseDown}
            >
              {collapsed ? <FiChevronRight /> : <FiChevronLeft />}
            </CollapseButton>
          )}
        </ResizeHandleContainer>
      </ResizeHandleRoot>
    </DraggableCore>
  );
});
