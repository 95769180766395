import { forwardRef } from 'react';

import { IconPlaceholder, IconPlaceholderProps } from 'components/icon';
import type * as Polymorphic from 'stitches/polymorphic';

const DEFAULT_TAG = 'div';

export type AvatarProps = IconPlaceholderProps & {};

export const Avatar = forwardRef(function Avatar(props, forwardedRef) {
  return (
    // TODO: Remove any cast
    <IconPlaceholder ref={forwardedRef} {...(props as any)} />
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, AvatarProps>;
