import { ComponentProps, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { StyledButton } from './Button';

export type ButtonLinkProps = ComponentProps<typeof StyledButton> & LinkProps;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(
  props,
  forwardedRef
) {
  /**
   * TODO: Remove any cast
   */
  return <StyledButton ref={forwardedRef as any} as={Link} {...(props as any)} />;
});
