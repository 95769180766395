import { MouseEvent, ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { styled } from 'stitches';

const TabLabelRoot = styled('div', {
  color: '$neutral-600',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$3',

  outline: 'none',

  cursor: 'pointer',

  padding: '0 $3',
  height: '$10',

  '&[aria-selected=true]': {
    color: '$primary-500',
  },

  variants: {
    stretched: {
      true: {
        flexGrow: 1,
      },
    },
  },
});

const TabLabelDismiss = styled('div', {
  whiteSpace: 'nowrap',
  userSelect: 'none',
  color: '$neutral-500',

  '&:hover': {
    color: '$neutral-600',
  },

  '&:active': {
    color: '$neutral-700',
  },
});

export type TabLabelProps = {
  children?: ReactNode;

  /**
   * Whether the tag is selected.
   */
  selected?: boolean;

  /**
   * Whether the tab is dismissible.
   * @deprecated
   */
  isDismissible?: boolean;

  /**
   * Whether or not the label is stretched.
   */
  stretched?: boolean;

  /**
   * Callback invoked when the label is clicked.
   */
  onClick?(): void;

  /**
   * Callback invoked when the dismiss button is clicked.
   */
  onDismiss?(): void;
};

export function TabLabel(props: TabLabelProps) {
  const { children, selected, stretched = false, onClick, onDismiss } = props;

  const handleDismissButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onDismiss?.();
  };

  return (
    <TabLabelRoot
      role="tab"
      tabIndex={selected ? 1 : -1}
      stretched={stretched}
      aria-selected={selected ? 'true' : undefined}
      onClick={onClick}
    >
      {children}

      {onDismiss && (
        <TabLabelDismiss role="button" aria-label="Dismiss tab" onClick={handleDismissButtonClick}>
          <FiX />
        </TabLabelDismiss>
      )}
    </TabLabelRoot>
  );
}
