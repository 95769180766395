import { MutableRefObject, Ref, RefCallback } from 'react';

export type AssignableRef<T> = Ref<T> | MutableRefObject<T>;

/**
 * Assigns the value to the given ref.
 * @param ref The ref to assign.
 * @param value The value.
 */
export function assignRef<T>(ref: AssignableRef<T>, value: T): void {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    return ref(value);
  }

  try {
    // @ts-ignore
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}

/**
 * Merges two or more refs into a single function ref.
 * @param refs The refs to merge.
 */
export function mergeRefs<T>(
  ...refs: [AssignableRef<T>, AssignableRef<T>, ...AssignableRef<T>[]]
): RefCallback<T> {
  return (value: T) => refs.forEach((ref) => assignRef(ref, value));
}
