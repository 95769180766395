import { ComponentProps, forwardRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { StyledButtonBase } from 'components/button';
import { styled } from 'stitches';

const StyledDropdownButton = styled(StyledButtonBase, {
  gap: '$3',

  '@hover': {
    '&:hover': {
      backgroundColor: '$neutral-200',
    },
  },
  '&:active': {
    backgroundColor: '$neutral-300',
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$neutral-200',
      },
    },
  },
});

export type DropdownButtonProps = ComponentProps<typeof StyledDropdownButton>;

export const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (props, forwardedRef) => {
    const { children, ...rest } = props;

    return (
      // TODO: Remove any cast
      <StyledDropdownButton ref={forwardedRef} {...(rest as any)}>
        {children}
        <FiChevronDown />
      </StyledDropdownButton>
    );
  }
);
