import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, ReactNode, cloneElement, forwardRef } from 'react';

import { Portal } from 'components/portal';
import { ScaleFade, TransitionComponent } from 'components/transitions';
import { Placement, popperCSSVars, useDelayedOpenState, useId, usePopper } from 'hooks';
import { styled } from 'stitches';
import { mergeProps } from 'utils';

const StyledTooltip = styled('div', {
  // backgroundColor: 'rgba(0, 0, 0, 0.54)',
  backgroundColor: 'rgba(0, 26, 66, .75)',
  color: '#ffffff',

  borderRadius: '$default',
  padding: '$2',

  textAlign: 'center',
  fontSize: '$sm',

  whiteSpace: 'nowrap',

  position: 'relative',
  transformOrigin: `${popperCSSVars.transformOrigin.varRef}`,
});

const AnimatedTooltip = motion(StyledTooltip);

export type TooltipProps = {
  /**
   * The tooltip content.
   */
  children: ReactElement;

  /**
   * The tooltip ID.
   */
  id?: string;

  /**
   * The tooltip placement.
   * @default 'bottom'
   */
  placement?: Placement;

  /**
   * Whether the tooltip is open. Used to manage the tooltip's state in a controlled manner.
   */
  open?: boolean;

  /**
   * Whether the tooltip is open by default.
   * @default false
   */
  defaultOpen?: boolean;

  /**
   * Whether to close the tooltip when the underlying element is clicked.
   * @default true
   */
  closeOnClick?: boolean;

  /**
   * The tooltip content.
   */
  title?: ReactNode;

  /**
   * Whether the tooltip is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * Transition component type.
   * @default ScaleFade
   */
  transition?: TransitionComponent;

  /**
   * The delay, in milliseconds, before the tooltip is open on mouse enter.
   * @default 0
   */
  enterDelay?: number;

  /**
   * The delay, in milliseconds, before the tooltip is hidden on mouse leave.
   * @default 0
   */
  leaveDelay?: number;

  /**
   * Callback invoked when the tooltip has been closed.
   */
  onClose?(): void;
};

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(props, forwardedRef) {
  const {
    children,
    placement = 'bottom',
    title,
    enterDelay = 0,
    leaveDelay = 0,
    id: idProp,
    open: openProp,
    defaultOpen = false,
    closeOnClick = true,
    disabled = false,
    transition: Transition = ScaleFade,
    onClose,
  } = props;

  const id = `tooltip-${useId(idProp)}`;
  const { isOpen, open, close } = useDelayedOpenState({
    isOpen: openProp,
    defaultOpen,
    onClose,
  });

  const { getPopperNodeProps, getReferenceNodeProps } = usePopper({
    placement,
  });

  const handleMouseEnter = () => {
    open(enterDelay);
  };

  const handleMouseLeave = () => {
    close(leaveDelay);
  };

  const handleMouseDown = () => {
    if (closeOnClick) {
      close(0);
    }
  };

  const titleIsString = typeof title === 'string';
  const childProps = mergeProps(children.props, {
    'aria-label': titleIsString ? title : null,
    'aria-labelledby': isOpen && !titleIsString ? id : null,
    onMouseDown: handleMouseDown,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  });

  if (disabled) {
    return children;
  }

  return (
    <>
      {cloneElement(children, getReferenceNodeProps(childProps))}

      <AnimatePresence>
        {isOpen && (
          <Portal>
            <div {...getPopperNodeProps()}>
              <Transition in>
                {(motionProps) => (
                  <AnimatedTooltip
                    id={id}
                    role="tooltip"
                    // TODO: Remove any cast
                    {...(motionProps as any)}
                  >
                    {title}
                  </AnimatedTooltip>
                )}
              </Transition>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
});
