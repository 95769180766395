import { Suspense, lazy, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet, Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';
import { RecoilRoot, useSetRecoilState } from 'recoil';

import { Auth } from 'auth';
import { LoadingSpinner } from 'components/loading-spinner';
import { Applications } from 'features/landing/Applications';
import { GlobalLayout } from 'global-layout';
import { Theme, useGlobalStyles } from 'stitches';

import { applicationPathState, organizationPathState } from './AppState';
import { queryClient } from './queryClient';

const AccountRoot = lazy(() => import('features/account/AccountRoot'));
const AdminRoot = lazy(() => import('features/admin/AdminRoot'));
const ApplicationRoot = lazy(() => import('features/application/ApplicationRoot'));
const DocsRoot = lazy(() => import('features/docs/DocsRoot'));
const OrganizationRoot = lazy(() => import('features/organization/OrganizationRoot'));

/**
 * Main entry point component. Responsible for initializing application wide routing, context, state and
 * providers.
 */
export function App() {
  useGlobalStyles();

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet titleTemplate="%s - Decisio" defaultTitle="Decisio" />

          <Suspense fallback={<LoadingSpinner />}>
            <Router>
              <Auth>
                <Theme />

                <GlobalLayout>
                  <Routes>
                    <Route element={<Root />}>
                      <Route index element={<Applications />} />
                      <Route path="account/*" element={<AccountRoot />} />
                      <Route path="admin/*" element={<AdminRoot />} />
                      <Route path="docs/*" element={<DocsRoot />} />
                      <Route path="orgs/:orgPath/*" element={<OrganizationRoot />} />
                      <Route path=":orgPath/:applicationPath/*" element={<ApplicationRoot />} />
                    </Route>
                  </Routes>
                </GlobalLayout>
              </Auth>
            </Router>
          </Suspense>
        </HelmetProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

function Root() {
  const { orgPath = null, applicationPath = null } = useParams();
  const setApplicationPath = useSetRecoilState(applicationPathState);
  const setOrganizationPath = useSetRecoilState(organizationPathState);

  useEffect(() => {
    setOrganizationPath(orgPath);
    setApplicationPath(applicationPath);
  }, [orgPath, applicationPath, setOrganizationPath, setApplicationPath]);

  return <Outlet />;
}
