import { Suspense } from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { Flex } from 'components/flex';
import { LoadingSpinner } from 'components/loading-spinner';
import { Tab, Tabs } from 'components/tabs';
import { Text } from 'components/text';
import { Tooltip } from 'components/tooltip';
import { Application } from 'interfaces';

import { Workflows } from './Workflows';

type AppLinksProps = {
  application: Application;
  onShowAll?(): void;
};

export function AppLinks(props: AppLinksProps) {
  const { application, onShowAll } = props;

  return (
    <>
      <Flex align="center" gap="3" gutterBottom>
        <Tooltip title="Show all">
          <IconButton onClick={onShowAll}>
            <FiArrowLeft />
          </IconButton>
        </Tooltip>

        <Text variant="subtitle">{application.name}</Text>
      </Flex>

      <Tabs>
        <Tab label="Workflows">
          <Suspense fallback={<LoadingSpinner />}>
            <Workflows application={application} />
          </Suspense>
        </Tab>
      </Tabs>
    </>
  );
}
