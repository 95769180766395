import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from './AuthState';

export type AuthProps = {
  children?: ReactNode;
};

export function Auth(props: AuthProps) {
  const { children = null } = props;

  const navigate = useNavigate();
  const { isAuthenticated, postLoginRedirectUrl } = useAuthState();

  useEffect(() => {
    if (isAuthenticated && postLoginRedirectUrl) {
      navigate(postLoginRedirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated ? <>{children}</> : null;
}
