import { ComponentProps, forwardRef } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

const DEFAULT_TAG = 'div';

const BreadcrumbRoot = styled(DEFAULT_TAG, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'inline-flex',
  gap: '$3',

  height: '$10',

  cursor: 'default',

  color: '$neutral-400',

  '&[data-active=true]': {
    color: '$neutral-600',
  },

  '&[href]:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export type BreadcrumbProps = ComponentProps<typeof BreadcrumbRoot> & {
  active?: boolean;
};

export const Breadcrumb = forwardRef(function Breadcrumb(props, forwardedRef) {
  const { children, active = false, ...rest } = props;

  return (
    <BreadcrumbRoot
      ref={forwardedRef}
      data-active={active}
      // TODO: Remove any cast
      {...(rest as any)}
    >
      {children}
      {!active && <FiChevronRight />}
    </BreadcrumbRoot>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, BreadcrumbProps>;
