import { ReactNode } from 'react';

import { styled } from 'stitches';

const StyledTabPanel = styled('div', {
  flexGrow: '1',
});

export type TabPanelProps = {
  children?: ReactNode;
};

export function TabPanel(props: TabPanelProps) {
  const { children } = props;

  return <StyledTabPanel role="tabpanel">{children}</StyledTabPanel>;
}
