import { AnimatePresence } from 'framer-motion';
import {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

import { Backdrop } from 'components/backdrop';
import { Portal } from 'components/portal';
import { useEventCallback, useMergedRefs } from 'hooks';
import { styled } from 'stitches';
import { getOwnerDocument } from 'utils';

import { ManagedModal, defaultManager as manager, setAriaHidden } from './ModalManager';

const ModalRoot = styled('div', {
  variants: {
    fixed: {
      true: {
        position: 'fixed',
        zIndex: '$3',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
      },
    },
  },
});

const ModalBackdrop = styled(Backdrop, {
  zIndex: -1,
});

export type ModalProps = {
  /**
   * Whether the auto focus the modal content.
   */
  autoFocus?: boolean;

  /**
   * Whether to return focus once the modal has closed.
   */
  returnFocus?: boolean;

  /**
   * Whether the the modal should be rendered in a portal or not.
   * @default false
   */
  disablePortal?: boolean;

  /**
   * Whether to disable the focus trap.
   * @default false
   */
  disableFocusTrap?: boolean;

  /**
   * Whether to hide the modal backdrop.
   * @default false
   */
  hideBackdrop?: boolean;

  /**
   * Whether or not the backdrop is transparent.
   * @default false
   */
  transparentBackdrop?: boolean;

  /**
   * The modal content.
   * @required
   */
  children: ReactElement;

  /**
   * Whether the modal is open.
   * @default false
   */
  open?: boolean;

  /**
   * Whether to close the modal when the escape key is pressed.
   * @default true
   */
  closeOnEscKey?: boolean;

  /**
   * Whether to close the modal when the backdrop is clicked.
   * @default true
   */
  closeOnBackdropClick?: boolean;

  /**
   * Whether the the modal relies on fixed positioning.
   * @default true
   */
  fixed?: boolean;

  /**
   * Callback invoked when a key is pressed in the modal hierarchy.
   * @param event The `KeyboardEvent`.
   */
  onKeyDown?(event: KeyboardEvent): void;

  /**
   * Callback invoked when the modal is closed.
   */
  onClose?(event: KeyboardEvent | MouseEvent | null): void;
};

export const Modal = forwardRef<HTMLElement, ModalProps>(function Modal(props, forwardedRef) {
  const {
    children,
    autoFocus,
    returnFocus,
    disablePortal,
    disableFocusTrap = false,
    open,
    closeOnEscKey = true,
    closeOnBackdropClick = true,
    fixed = true,
    hideBackdrop = false,
    transparentBackdrop = false,
    onClose,
    onKeyDown,
  } = props;

  const mountNodeRef = useRef<HTMLElement | null>(null);
  const modal = useRef<ManagedModal>({});
  const modalRef = useRef<HTMLElement>(null);
  const rootRef = useMergedRefs(modalRef, forwardedRef);

  const getModal = () => {
    modal.current.node = modalRef.current!;
    modal.current.mountNode = mountNodeRef.current!;
    return modal.current;
  };

  const isTopModal = () => manager.isTopModal(getModal());

  const handleMounted = () => {
    manager.mount(getModal());
  };

  const handleOpen = useEventCallback(() => {
    const container = getOwnerDocument(mountNodeRef.current!).body;
    manager.add(getModal(), container);

    if (modalRef.current) {
      handleMounted();
    }
  });

  const handleClose = useCallback(() => {
    manager.remove(getModal());
  }, []);

  useEffect(() => {
    return () => handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (open) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [open, handleOpen, handleClose]);

  const handlePortalRef = useEventCallback((node: HTMLElement | null) => {
    mountNodeRef.current = node;
    if (!node) {
      return;
    }

    if (open && isTopModal()) {
      handleMounted();
    } else if (modalRef.current) {
      setAriaHidden(modalRef.current, true);
    }
  });

  const handleBackdropClick = (event: MouseEvent) => {
    if (event.target !== event.currentTarget || !closeOnBackdropClick) {
      return;
    }

    onClose?.(event);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    onKeyDown?.(event);

    if (event.key !== 'Escape' || !isTopModal() || !closeOnEscKey) {
      return;
    }

    /**
     * Prevent the event from propagating incase of another escape key handler.
     */
    event.stopPropagation();
    onClose?.(event);
  };

  const childProps: any = {};
  if (children.props?.tabIndex === undefined) {
    childProps.tabIndex = '-1';
  }

  return (
    <AnimatePresence>
      {open && (
        <Portal ref={handlePortalRef} disabled={disablePortal}>
          <FocusLock
            autoFocus={autoFocus}
            returnFocus={returnFocus}
            disabled={disableFocusTrap || !isTopModal()}
          >
            <RemoveScroll>
              <ModalRoot ref={rootRef} role="presentation" onKeyDown={handleKeyDown} fixed={fixed}>
                {!hideBackdrop && (
                  <ModalBackdrop transparent={transparentBackdrop} onClick={handleBackdropClick} />
                )}
                {cloneElement(children, childProps)}
              </ModalRoot>
            </RemoveScroll>
          </FocusLock>
        </Portal>
      )}
    </AnimatePresence>
  );
});
