import { styled } from 'stitches';

import { useFormFieldContext } from './FormFieldContext';

/**
 * Component for displaying field error messages.
 */
export const FieldError = styled('div', {
  color: '$danger-500',
  marginTop: '$2',
  marginInlineStart: '1px',
  fontSize: '$sm',
});

/**
 * Component for displaying errors of a particular `FormField` context.
 *
 * @example
 *
 * <FormField error="Field invalid">
 *    ...
 *    <FormFieldErrors />
 * </FormField>
 */
export function FormFieldErrors() {
  const { error } = useFormFieldContext();

  if (!error) {
    return null;
  }

  return <FieldError>{error}</FieldError>;
}
