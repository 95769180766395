import { ReactNode, Suspense, useRef, useState } from 'react';

import { useDialog } from 'components/dialog';
import { LoadingSpinner } from 'components/loading-spinner';
import { SideLayout, SideLayoutContent } from 'components/side-layout';
import { usePreferenceState } from 'data/preferences';
import { styled } from 'stitches';

import { ContextualNavContainer } from './ContextualNavContainer';
import { GlobalLayoutContextProvider } from './GlobalLayoutState';
import { GlobalNav } from './GlobalNav';
import { Notifications } from './notifications';
import { PreferencesDialog } from './PreferencesDialog';
import { QuickNavigation } from './QuickNavigation';

const GlobalLayoutContainer = styled('div', {
  display: 'flex',
  overflow: 'hidden',
  height: 'calc(100vh - 0px)',

  variants: {
    orientation: {
      vertical: { flexDirection: 'row' },
      horizontal: { flexDirection: 'column' },
    },
  },
});

export type GlobalLayoutProps = {
  children?: ReactNode;
};

export function GlobalLayout(props: GlobalLayoutProps) {
  const contextualNavRef = useRef<HTMLDivElement>(null);

  const [selectedDrawer, setSelectedDrawer] = useState<string | null>(null);
  const [navOrientation] = usePreferenceState<'vertical' | 'horizontal'>({
    key: 'navbarOrientation',
    defaultValue: 'vertical',
  });

  const preferencesDialog = useDialog();

  return (
    <GlobalLayoutContextProvider
      value={{
        contextualNavRef,
      }}
    >
      <GlobalLayoutContainer orientation={navOrientation}>
        <GlobalNav
          orientation={navOrientation}
          onDrawerSelect={setSelectedDrawer}
          onPreferences={preferencesDialog.open}
        />

        <SideLayout>
          <ContextualNavContainer />

          <SideLayoutContent>
            <Suspense fallback={<LoadingSpinner />}>{props.children}</Suspense>
          </SideLayoutContent>
        </SideLayout>
      </GlobalLayoutContainer>

      <QuickNavigation
        isOpen={selectedDrawer === 'quickNavigation'}
        onClose={() => setSelectedDrawer(null)}
      />

      <Notifications
        isOpen={selectedDrawer === 'notifications'}
        onClose={() => setSelectedDrawer(null)}
      />

      <PreferencesDialog {...preferencesDialog.getDialogProps()} />
    </GlobalLayoutContextProvider>
  );
}
