import { ApplicationId } from 'interfaces';

export type NotificationId = string;

export interface Notification {
  /**
   * Gets the notification ID.
   */
  readonly id: NotificationId;

  /**
   * Gets the notification application ID, if present.
   */
  readonly applicationId: ApplicationId | null;

  /**
   * Gets the full path for the notification application.
   */
  readonly applicationPath: string;

  /**
   * Gets the notification key.
   */
  readonly key: string;

  /**
   * Gets the notification domain.
   */
  readonly domain: NotificationDomain;

  /**
   * Gets the notification resource type.
   */
  readonly resourceType: string | null;

  /**
   * Gets the notification resource ID.
   */
  readonly resourceId: number | null;

  /**
   * Gets the notification title.
   */
  readonly title: string;

  /**
   * Gets the notification message.
   */
  readonly message: string;

  /**
   * Gets the notification status.
   */
  readonly status?: NotificationStatus;

  /**
   * Gets the date the notification was created.
   */
  readonly created: string;
}

/**
 * Represents the notification status.
 */
export enum NotificationStatus {
  /**
   * The notification is unread.
   */
  Unread = 0,

  /**
   * The notification has been read.
   */
  Read = 1,
}

/**
 * Represents the notification
 */
export enum NotificationDomain {
  /**
   * Notifications related to general or application management.
   */
  Management = 'Management',

  /**
   * Notifications related to the application scenarios.
   */
  Scenarios = 'Scenarios',
}
