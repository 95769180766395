import { Flex } from 'components/flex';
import { Spinner } from 'components/spinner';
import { ScaleValue } from 'stitches';

export type SpinnerLoaderProps = {
  size?: ScaleValue<'sizes'> | string | number;
};

export function LoadingSpinner(props: SpinnerLoaderProps) {
  const { size = '$10' } = props;

  return (
    <Flex justify="center" align="center" css={{ padding: '$6', size: '100%' }}>
      <Spinner indeterminate size={size} css={{ color: '$primary-500' }} />
    </Flex>
  );
}
