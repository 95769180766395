import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { CSS, ScaleValue, styled } from 'stitches';

const SpinnerRoot = styled('div', {
  verticalAlign: 'middle',
  lineHeight: 1,
  display: 'inline-block',
});

const BASE_SIZE = 44;

export type SpinnerProps = {
  size?: ScaleValue<'sizes'> | string | number;

  thickness?: number;

  indeterminate?: boolean;

  css?: CSS;
};

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(function Spinner(props, ref) {
  const { size = '$8', thickness = 3.6, indeterminate = false, css } = props;

  const animationProps = getAnimationProps(indeterminate);

  return (
    <SpinnerRoot ref={ref} css={{ size, ...(css as any) }}>
      <motion.svg
        role="progressbar"
        focusable={false}
        viewBox={`${BASE_SIZE / 2} ${BASE_SIZE / 2} ${BASE_SIZE} ${BASE_SIZE}`}
        display="block"
        style={{
          originX: '50%',
          originY: '50%',
        }}
        {...animationProps.svg}
      >
        <motion.circle
          strokeDashoffset={0}
          strokeDasharray="80px, 200px"
          strokeWidth={thickness}
          stroke="currentColor"
          fill="none"
          cx="100%"
          cy="100%"
          r={(BASE_SIZE - thickness) / 2}
          {...animationProps.circle}
        />
      </motion.svg>
    </SpinnerRoot>
  );
});

function getAnimationProps(indeterminate: boolean) {
  let svg = {};
  let circle = {};

  if (indeterminate) {
    svg = {
      animate: { rotate: [0, 360] },
      transition: { duration: 1.4, ease: 'linear', repeat: Infinity },
    };

    circle = {
      animate: {
        strokeDashoffset: [0, -15, -125],
        strokeDasharray: ['1px, 200px', '100px, 200px', '100px, 200px'],
      },
      transition: { duration: 1.4, ease: 'easeInOut', repeat: Infinity, repeatDelay: 0.1 },
    };
  }

  return { svg, circle };
}
