import { forwardRef } from 'react';

import { Placement, Popover, PopoverProps } from 'components/popover';
import { styled } from 'stitches';

export type { Placement };

const MenuRoot = styled(Popover, {
  width: '18rem',
  display: 'flex',
  flexDirection: 'column',
});

export type MenuProps = PopoverProps & {};

export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(props, forwardedRef) {
  return <MenuRoot ref={forwardedRef} {...props} />;
});
