import { ComputedPlacement, Placement, VirtualElement } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { Modifier, usePopper as useBasePopper } from 'react-popper';

import { mergeRefs } from 'utils';

export type { VirtualElement };

const toVar = (value: string, fallback?: string) => ({
  var: value,
  varRef: fallback ? `var(${value}, ${fallback})` : `var(${value})`,
});

export const popperCSSVars = {
  transformOrigin: toVar('--popper-transform-origin'),
  maxHeight: toVar('--popper-max-height'),
} as const;

const transformOrigins: Record<ComputedPlacement, string> = {
  top: 'bottom center',
  'top-start': 'bottom left',
  'top-end': 'bottom right',

  bottom: 'top center',
  'bottom-start': 'top left',
  'bottom-end': 'top right',

  left: 'right center',
  'left-start': 'right top',
  'left-end': 'right bottom',

  right: 'left center',
  'right-start': 'left top',
  'right-end': 'left bottom',
};

const toTransformOrigin = (placement: string): string | null =>
  transformOrigins[placement as ComputedPlacement] || null;

export type UsePopperOptions = {
  boundaryRef?: RefObject<HTMLElement>;
  fixed?: boolean;
  placement?: Placement;
  flip?: boolean;
  preventOverflow?: boolean;
  offset?: [number, number];
  gutter?: number;
  constrainHeight?: boolean;
  matchWidth?: boolean;
  modifiers?: Modifier<string>[];
};

export type PopperReferenceElement = Element | VirtualElement;

export function usePopper(props: UsePopperOptions = {}) {
  const {
    boundaryRef,
    fixed = true,
    flip = true,
    gutter = 8,
    offset,
    preventOverflow = true,
    placement = 'bottom',
    constrainHeight = true,
    matchWidth,
    modifiers = [],
  } = props;

  const [referenceNode, setReferenceNode] = useState<PopperReferenceElement | null>(null);
  const [popperNode, setPopperNode] = useState<HTMLElement | null>(null);

  const customModifiers = useMemo<Modifier<string>[]>(() => {
    return [
      maxSize,
      {
        name: 'offset',
        phase: 'main',
        options: {
          offset: offset ?? [0, gutter],
        },
      },
      {
        name: 'preventOverflow',
        enabled: !!preventOverflow,
        options: {
          boundary: boundaryRef?.current || 'clippingParents',
          padding: gutter,
        },
        phase: 'main',
      },
      {
        name: 'flip',
        enabled: !!flip,
        phase: 'main',
        options: {
          padding: gutter,
        },
      },
      {
        name: 'transformOrigin',
        enabled: true,
        phase: 'write',
        fn: ({ state }) => {
          state.elements.popper.style.setProperty(
            popperCSSVars.transformOrigin.var,
            toTransformOrigin(state.placement)
          );
        },
        effect:
          ({ state }) =>
          () => {
            state.elements.popper.style.setProperty(
              popperCSSVars.transformOrigin.var,
              toTransformOrigin(state.placement)
            );
          },
      },
      {
        name: 'matchWidth',
        enabled: !!matchWidth,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }: any) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect:
          ({ state }: any) =>
          () => {
            const reference = state.elements.reference as HTMLElement;
            state.elements.popper.style.width = `${reference.offsetWidth}px`;
          },
      },
      {
        name: 'maxHeight',
        enabled: constrainHeight,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({ state }) {
          const { height } = state.modifiersData.maxSize;
          state.elements.popper.style.setProperty(
            popperCSSVars.maxHeight.var,
            `${height - gutter}px`
          );
          state.styles.popper.maxHeight = popperCSSVars.maxHeight.varRef;
        },
      },
    ];
  }, [offset, gutter, preventOverflow, boundaryRef, flip, matchWidth, constrainHeight]);

  const popper = useBasePopper(referenceNode, popperNode, {
    placement,
    strategy: fixed ? 'fixed' : 'absolute',
    modifiers: modifiers.concat(customModifiers),
  });

  // useEffect(() => {
  //   popper.forceUpdate?.();
  // });

  const finalPlacement = popper.state?.placement ?? placement;

  const getReferenceNodeProps = useCallback((props = {}, ref = null) => {
    return { ...props, ref: mergeRefs(setReferenceNode, ref) };
  }, []);

  const getPopperNodeProps = useCallback(
    (props = {}, ref = null) => {
      return {
        ...props,
        ...popper.attributes.popper,
        ref: mergeRefs(setPopperNode, ref),
        style: { ...props.style, ...popper.styles.popper, zIndex: 1000 },
      };
    },
    [popper.attributes, popper.styles]
  );

  return {
    popper,
    placement: finalPlacement,
    transformOrigin: toTransformOrigin(finalPlacement),
    getReferenceNodeProps,
    getPopperNodeProps,
    setReferenceNode,
  };
}

export type UsePopperReturn = ReturnType<typeof usePopper>;

export type { Placement };
