import { TagId } from 'interfaces';

/**
 * A strongly-typed report ID.
 */
export type ReportId = number;

/**
 * Represents the report entity.
 */
export interface Report {
  /**
   * Gets the report ID.
   */
  readonly id: ReportId;

  /**
   * Ges the report name.
   */
  name: string;

  /**
   * Gets the report display name.
   */
  displayName: string;

  /**
   * Gets the report label.
   */
  label: string | null;

  /**
   * Gets the report icon ID.
   */
  iconId: number | null;

  /**
   * Gets the report icon URI.
   */
  iconUri: string | null;

  /**
   * Gets the report category ID.
   */
  categoryId: number | null;

  /**
   * Gets the report description.
   */
  description: string | null;

  /**
   * The report provider name.
   */
  provider: string;

  /**
   * Whether the report is a favorite of the current user.
   */
  isFavorite: boolean;

  /**
   * Gets the date the report was created.
   */
  created: string;

  /**
   * Gets the date the report was last updated.
   */
  updated: string;

  /**
   * Gets the report tags.
   */
  tags: string[];

  /**
   * Gets the provider specific properties.
   */
  properties: Record<string, string>;

  /**
   * Gets the report sort order.
   */
  sortOrder: number | null;
}

export type PBIObjectType = 'Report' | 'Dashboard';

export interface PBIEmbedData {
  /**
   * Gets the access token.
   */
  readonly accessToken: string;

  /**
   * Gets the PowerBI object Id.
   */
  readonly objectId: string;

  /**
   * Gets the PowerBI object type.
   */
  readonly objectType: PBIObjectType;

  /**
   * Gets the embed url.
   */
  readonly embedUrl: string;
}

export enum ReportAccessLevel {
  Read = 0,
  ReadWrite = 1,
}

/**
 * Union of all possible report embed data types.
 */
export type ReportEmbedData = {
  data: PBIEmbedData;
  accessLevel: ReportAccessLevel;
};

/**
 * Request payload used to create a new report.
 */
export interface CreateReportPayload {
  /**
   * The name of the new report.
   */
  name: string;

  /**
   * Gets the category ID.
   */
  categoryId: number | null;

  /**
   * Gets the label.
   */
  label: string | null;

  /**
   * Gets the description.
   */
  description: string | null;

  /**
   * Gets the icon ID.
   */
  iconId: number | null;

  /**
   * The tag IDs.
   */
  tagIds: TagId[];

  /**
   * Gets the file.
   */
  file: File | null;
}

/**
 * Request payload used to update an existing report.
 */
export type UpdateReportPayload = CreateReportPayload;

/**
 * Represents a report provider which determines how a report is persisted and rendered.
 */
export interface ReportProvider {
  /**
   * The provider key or unique identifier.
   */
  key: string;

  /**
   * The provider name.
   */
  name: string;

  /**
   * Whether a report file is required for this provider.
   */
  fileRequired: boolean;

  /**
   * The provider properties.
   */
  properties: Array<{
    /**
     * The property key.
     */
    key: string;

    /**
     * The property display label.
     */
    label: string;

    /**
     * The property data type.
     */
    type: 'boolean' | 'text';
  }>;
}
