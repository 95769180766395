import { ReactNode, forwardRef } from 'react';

import { styled } from 'stitches';

import { useSideLayoutContext } from './SideLayoutContext';

const SideLayoutContentRoot = styled('div', {
  position: 'relative',
  overflow: 'auto',
  display: 'block',
  height: '100%',

  translate: 'transform3d(0, 0, 0)',
});

export type SideLayoutContentProps = {
  children?: ReactNode;
};

export const SideLayoutContent = forwardRef<HTMLDivElement, SideLayoutContentProps>(
  function SideLayoutContent(props, ref) {
    const { margins } = useSideLayoutContext();

    return (
      <SideLayoutContentRoot
        ref={ref}
        style={{
          marginLeft: margins.left,
          marginRight: margins.right,
        }}
      >
        {props.children}
      </SideLayoutContentRoot>
    );
  }
);
