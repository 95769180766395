import { FlexItem } from 'components/flex';
import { Input } from 'components/input';

export type SelectMenuSearchProps = {
  value?: string;
  onChange?(value: string): void;
};

export function SelectMenuSearch(props: SelectMenuSearchProps) {
  const { value, onChange } = props;

  return (
    <FlexItem shrink="0" gutter="xs">
      <Input
        variant="borderless"
        placeholder="Search"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </FlexItem>
  );
}
