import { styled } from 'stitches';
import { gutters } from 'stitches/helpers';

/**
 * Simple box component.
 *
 * @example
 *
 * <Box css={...}}>Box</Box>
 */
export const Box = styled('div', gutters, {
  variants: {
    container: {
      true: {
        position: 'relative',
        padding: '0 $6',
        margin: '0 auto',
        width: '100%',

        '@sm': { maxWidth: '$containers$sm' },
        '@md': { maxWidth: '$containers$md' },
        '@lg': { maxWidth: '$containers$lg' },
        '@xl': { maxWidth: '$containers$xl' },
        '@2xl': { maxWidth: '$containers$2xl' },
      },

      xl: {
        position: 'relative',
        padding: '0 $6',
        margin: '0 auto',
        width: '100%',

        '@sm': { maxWidth: '$containers$sm' },
        '@md': { maxWidth: '$containers$md' },
        '@lg': { maxWidth: '$containers$lg' },
        '@xl': { maxWidth: '$containers$xl' },
      },
    },
  },
});
