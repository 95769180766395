import { forwardRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Flex } from 'components/flex';
import { SidePanel } from 'components/side-layout';

import {
  contextualNavCollapsedState,
  contextualNavHiddenState,
  useGlobalLayoutContext,
} from './GlobalLayoutState';

export const ContextualNavContainer = forwardRef<HTMLDivElement, {}>((_, forwardedRef) => {
  const { contextualNavRef } = useGlobalLayoutContext();
  const isContextualNavHidden = useRecoilValue(contextualNavHiddenState);
  const [isContextualNavCollapsed, setContextualNavCollapsed] = useRecoilState(
    contextualNavCollapsedState
  );

  return (
    <SidePanel
      ref={forwardedRef}
      collapsible
      defaultCollapsed={isContextualNavCollapsed}
      defaultWidth={250}
      minWidth={200}
      maxWidth={350}
      hidden={isContextualNavHidden}
      width={isContextualNavCollapsed ? 70 : undefined}
      onCollapsedChange={setContextualNavCollapsed}
    >
      <Flex ref={contextualNavRef} css={{ height: '100%', overflow: 'hidden' }} />
    </SidePanel>
  );
});
