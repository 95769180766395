import { MouseEvent, ReactNode, forwardRef, useState } from 'react';

import { FlexItem } from 'components/flex';
import { MenuOptionItem } from 'components/menu';
import { Text } from 'components/text';

export type SelectMenuItemProps = {
  value?: any;
  isChecked?: boolean;
  focusSelect?: boolean;
  children?: ReactNode;
  closeOnClick?: boolean | ((e: MouseEvent) => boolean);
  onSelect?(value: any): void;
  onFocusSelect?(value: any): void;
};

export const SelectMenuItem = forwardRef<HTMLDivElement, SelectMenuItemProps>(
  function SelectMenuItem(props, forwardedRef) {
    const {
      focusSelect = false,
      children,
      closeOnClick = false,
      isChecked = false,
      value,
      onSelect,
      onFocusSelect,
      ...otherProps
    } = props;
    const [isHovered, setHovered] = useState(false);

    const handleItemClick = () => {
      onSelect?.(value);
    };

    const handleFocusClick = (e: MouseEvent) => {
      e.stopPropagation();
      onFocusSelect?.(value);
    };

    return (
      <MenuOptionItem
        ref={forwardedRef}
        checked={isChecked}
        value={value}
        closeOnClick={closeOnClick}
        onClick={handleItemClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...(otherProps as any)}
      >
        <Text as={FlexItem} grow={1}>
          {children}
        </Text>

        {focusSelect && isHovered && (
          <Text
            size="sm"
            role="button"
            onClick={handleFocusClick}
            css={{
              color: '$primary-500',
              flexShrink: 0,
            }}
          >
            Only
          </Text>
        )}
      </MenuOptionItem>
    );
  }
);
