import { ApplicationId, Preference } from 'interfaces';

import { api } from './api';

/**
 * Fetches all user preferences.
 */
export function getPreferences() {
  return api.get<Preference[]>('/preferences');
}

/**
 * Request payload used to update the user preferences.
 */
export type UpdatePreferencesPayload = {
  preferences: Array<{
    key: string;
    value: string | null;
    applicationId?: ApplicationId | null;
  }>;
};

/**
 * Updates the user preferences.
 * @param payload The {@link UpdatePreferencesPayload}.
 * @returns An empty promise.
 */
export function updatePreferences(payload: UpdatePreferencesPayload) {
  return api.patch('/preferences', payload);
}
