import {
  ApplicationId,
  PipelineDefinition,
  PipelineDefinitionId,
  PipelineDefinitionParameterId,
  PipelineDefinitionPreview,
  PipelineDefinitionTaskId,
  PipelineJob,
  PipelineJobId,
  PipelineJobLog,
  PipelineJobPreview,
  PipelineJobStepId,
  PipelineSchedule,
  PipelineScheduleId,
  PipelineTask,
  ScenarioId,
} from 'interfaces';

import { api } from './api';

/**
 * Gets the pipeline definitions for the specified application.
 * @param applicationId The application ID.
 * @returns A `Promise` containing the application pipelines.
 */
export function getPipelineDefinitions(applicationId: ApplicationId) {
  return api.get<PipelineDefinitionPreview[]>(`/applications/${applicationId}/pipelines`);
}

/**
 * Gets the pipeline definition for the specified application.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline ID.
 * @returns A `Promise` containing the application pipeline.
 */
export function getPipelineDefinition(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId
) {
  return api.get<PipelineDefinition>(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}`
  );
}

export interface CreatePipelineDefinitionCommand {
  name: string;
  description: string;
  tasks: Array<{
    id?: PipelineDefinitionTaskId;
    key: string | null;
    name: string;
    taskKey: string;
    condition: string;
    conditionTargetKey: string | null;
    inputs: Record<string, string>;
  }>;
  parameters: Array<{
    id?: PipelineDefinitionParameterId;
    key: string;
    defaultValue: string | null;
  }>;
}

/**
 * Creates a new pipeline definition for the specified application.
 * @param applicationId The application ID.
 * @param payload The create pipeline command payload.
 */
export function createPipelineDefinition(
  applicationId: ApplicationId,
  payload: CreatePipelineDefinitionCommand
) {
  return api.post(`applications/${applicationId}/pipelines`, payload);
}

export type UpdatePipelineDefinitionCommand = CreatePipelineDefinitionCommand;

/**
 * Updates an existing pipeline definition.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param payload The update pipeline command payload.
 * @returns A `Promise` containing the updated pipeline definition.
 */
export function updatePipelineDefinition(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  payload: UpdatePipelineDefinitionCommand
) {
  return api.put<PipelineDefinition>(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}`,
    payload
  );
}

/**
 * Deletes an existing pipeline definition.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 */
export function deletePipelineDefinition(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId
) {
  return api.delete(`applications/${applicationId}/pipelines/${pipelineDefinitionId}`);
}

/**
 * Gets the pipeline tasks for  the specified application.
 * @param applicationId The application ID.
 * @returns A `Promise` containing the available pipeline tasks.
 */
export function getPipelineTasks(applicationId: ApplicationId) {
  return api.get<PipelineTask[]>(`/applications/${applicationId}/pipelines/tasks`);
}

/**
 * Gets the pipeline jobs for the specified pipeline.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @returns A `Promise` containing the pipeline jobs.
 */
export function getPipelineJobs(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId
) {
  return api.get<PipelineJobPreview[]>(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/jobs`
  );
}

/**
 * Gets the logs for the specified pipeline job step.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param pipelineJobId The pipeline job ID.
 * @param pipelineJobStepId The pipeline job step ID.
 * @returns A `Promise` containing the job step logs.
 */
export function getPipelineJobStepLogs(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  pipelineJobId: PipelineJobId,
  pipelineJobStepId: PipelineJobStepId
) {
  return api.get<PipelineJobLog[]>(
    `applications/${applicationId}/pipelines/${pipelineDefinitionId}/jobs/${pipelineJobId}/steps/${pipelineJobStepId}/logs`
  );
}

/**
 * Gets a pipeline job for the specified pipeline.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param pipelineJobId The pipeline job ID.
 * @returns A `Promise` containing the pipeline job.
 */
export function getPipelineJob(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  pipelineJobId: PipelineJobId
) {
  return api.get<PipelineJob>(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/jobs/${pipelineJobId}`
  );
}

export type SubmitPipelineJobCommand = {
  scenarioId?: ScenarioId | null;
  scheduledDate?: string | null;
};

/**
 * Submits a new pipeline job.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param payload The payload.
 */
export function submitPipelineJob(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  payload: SubmitPipelineJobCommand
) {
  return api.post(`/applications/${applicationId}/pipelines/${pipelineDefinitionId}/jobs`, payload);
}

/**
 * Cancels a running or scheduled pipeline job.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param pipelineJobId The pipeline job ID.
 */
export function cancelPipelineJob(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  pipelineJobId: PipelineJobId
) {
  return api.post(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/jobs/${pipelineJobId}/cancel`,
    {}
  );
}

/**
 * Gets the pipeline schedules.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @returns The pipeline schedules
 */
export function getPipelineSchedules(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId
) {
  return api.get<PipelineSchedule[]>(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/schedules`
  );
}

export interface CreatePipelineScheduleCommand {
  scenarioId: ScenarioId | null;
  cron: string;
  parameters: Array<{
    key: string;
    value: string | null;
  }>;
}

/**
 * Creates a new pipeline schedule.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param payload The command payload.
 */
export function createPipelineSchedule(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  payload: CreatePipelineScheduleCommand
) {
  return api.post(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/schedules`,
    payload
  );
}

/**
 * Deletes an existing pipeline schedule.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param pipelineScheduleId The pipeline schedule ID.
 */
export function deletePipelineSchedule(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  pipelineScheduleId: PipelineScheduleId
) {
  return api.delete(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/schedules/${pipelineScheduleId}`
  );
}

export type SetPipelineScheduleEnabledCommand = {
  isEnabled?: boolean;
};

/**
 * Deletes an existing pipeline schedule.
 * @param applicationId The application ID.
 * @param pipelineDefinitionId The pipeline definition ID.
 * @param pipelineScheduleId The pipeline schedule ID.
 * @param command The command payload.
 */
export function setPipelineScheduleEnabled(
  applicationId: ApplicationId,
  pipelineDefinitionId: PipelineDefinitionId,
  pipelineScheduleId: PipelineScheduleId,
  command: SetPipelineScheduleEnabledCommand
) {
  return api.patch(
    `/applications/${applicationId}/pipelines/${pipelineDefinitionId}/schedules/${pipelineScheduleId}/enabled`,
    command
  );
}

export function getPipelineHubConnection() {
  return api.createHubConnection(`pipelines`);
}
