import { Suspense, useState } from 'react';

import { useDialog } from 'components/dialog';
import { Flex } from 'components/flex';
import { List } from 'components/list';
import { LoadingSpinner } from 'components/loading-spinner';
import { Paginator } from 'components/paginator';
import { ScenarioDataImportRollbackDialog } from 'components/scenario-data-import-rollback-dialog';
import { Text } from 'components/text';
import { PAGE_SIZE, useNotifications, useUpdateNotifications } from 'data/notifications';
import { Notification } from 'interfaces';

import { NotificationItem } from './NotificationItem';

export type NotificationListProps = {
  onNavigate(): void;
};

export function NotificationList(props: NotificationListProps) {
  const { onNavigate } = props;

  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const notifications = useNotifications();
  const updateNotifications = useUpdateNotifications();
  const totalCount = notifications.totalCount;
  const pageCount = Math.ceil(totalCount / PAGE_SIZE);

  const handleDialogClose = () => {
    setSelectedNotification(null);
  };
  const openDialog = (dialog: { open: () => any }) => {
    return (notification: Notification) => {
      setSelectedNotification(notification);
      dialog.open();
    };
  };

  const scenarioDataImportRollbackDialog = useDialog({ onClose: handleDialogClose });

  const onPageChange = (page: number) => {
    updateNotifications(notifications.showAll, page - 1);
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <List hideDivider css={{ marginBottom: '$2' }}>
        {notifications.items.map((item) => (
          <NotificationItem
            key={item.id}
            notification={item}
            onNavigate={onNavigate}
            onDataImportRollback={openDialog(scenarioDataImportRollbackDialog)}
          />
        ))}
      </List>

      {notifications.items.length === 0 ? (
        <Flex css={{ padding: '$5 0' }}>
          <Text variant="secondary">
            You have no {notifications.showAll ? '' : 'unread'} notifications.
          </Text>
        </Flex>
      ) : (
        <Flex justify="center" gutterBottom="lg">
          <Paginator
            page={notifications.page + 1}
            pageCount={pageCount}
            onPageChange={onPageChange}
          />
        </Flex>
      )}

      <ScenarioDataImportRollbackDialog
        dataImportSessionId={selectedNotification?.resourceId ?? 0}
        applicationId={selectedNotification?.applicationId ?? 0}
        {...scenarioDataImportRollbackDialog.getDialogProps()}
      />
    </Suspense>
  );
}
