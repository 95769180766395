import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useCallbackRef } from './useCallbackRef';
import { useControlledValue } from './useControlledValue';

export type UseControlledStateOptions<T> = {
  /**
   * The controlled value.
   */
  value?: T;

  /**
   * The default value.
   */
  defaultValue?: T | (() => T);

  /**
   * Callback when the value changes.
   */
  onChange?: (value: T) => void;
};

export function useControlledState<T>(options: UseControlledStateOptions<T>) {
  const { value: valueProp, defaultValue, onChange: onChangeProp } = options;

  const [valueState, setValueState] = useState(defaultValue as T);
  const [isControlled, value] = useControlledValue(valueProp, valueState);
  const onChange = useCallbackRef(onChangeProp);

  const setValue = useCallback(
    (next: SetStateAction<T>) => {
      const nextValue = typeof next === 'function' ? (next as Function)(value) : next;

      if (!isControlled) {
        setValueState(nextValue);
      }

      onChange?.(nextValue);
    },
    [isControlled, value, setValueState, onChange]
  );

  return [value, setValue] as [T, Dispatch<SetStateAction<T>>];
}
