import { createContext } from 'utils';

export type FormFieldContext = {
  /**
   * Whether the field is required.
   */
  required: boolean;

  /**
   * Whether the field is currently invalid.
   */
  invalid: boolean;

  /**
   * The current error message.
   */
  error: string | null;

  /**
   * The field ID.
   */
  id?: string;
};

export const [FormFieldContextProvider, useFormFieldContext, FormFieldContextConsumer] =
  createContext<FormFieldContext>({
    name: 'FormFieldContext',
    strict: false,
  });

export type UseFormFieldContextProviderOpts = {
  /**
   * The field ID.
   */
  id?: string;

  /**
   * Whether the field is currently invalid.
   */
  invalid?: boolean;

  /**
   * The form field error(s).
   */
  error?: string | string[] | null | boolean;

  /**
   * Whether the field is required.
   * @default false
   */
  required?: boolean;
};

export function useFormFieldContextProvider(
  opts: UseFormFieldContextProviderOpts
): FormFieldContext {
  const { id, error: errorProp, required = false } = opts;

  const invalid = Array.isArray(errorProp) ? errorProp.length > 0 : !!errorProp;

  let error = null;
  if (invalid) {
    if (Array.isArray(errorProp)) {
      error = errorProp[0];
    } else if (typeof errorProp === 'string') {
      error = errorProp;
    }

    if (!error) {
      error = 'Field is invalid.';
    }
  }

  return {
    id,
    error,
    invalid,
    required,
  };
}
