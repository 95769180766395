import { ComponentProps, forwardRef } from 'react';

import { styled } from 'stitches';

import { useFormFieldContext } from './FormFieldContext';

export const FieldHintRoot = styled('div', {
  color: '$neutral-500',
  marginTop: '$2',
  marginInlineStart: '1px',
  fontSize: '$sm',
});

export type FieldHintProps = ComponentProps<typeof FieldHintRoot> & {
  /**
   * Whether to hide the hint when the field is in an invalid state.
   * @default true
   */
  hideOnInvalid?: boolean;
};

/**
 * Component used to render form field hints.
 */
export const FieldHint = forwardRef<HTMLDivElement, FieldHintProps>(function FieldHint(
  props,
  forwardedRef
) {
  const { hideOnInvalid = true, ...rest } = props;

  const { invalid } = useFormFieldContext();

  if (hideOnInvalid && invalid) {
    return null;
  }

  return <FieldHintRoot ref={forwardedRef} {...(rest as any)} />;
});
