import { chainEventHandlers } from './chainEventHandlers';
import { mergeRefs } from './ref';

type Props = Record<string, any>;

// taken from: https://stackoverflow.com/questions/51603250/typescript-3-parameter-list-intersection-type/51604379#51604379
type TupleTypes<T> = { [P in keyof T]: T[P] } extends { [key: number]: infer V } ? V : never;
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export function mergeProps<T extends Props[]>(...args: T): UnionToIntersection<TupleTypes<T>> {
  const result: Props = {};

  for (const props of args) {
    for (let k in result) {
      if (/^on[A-Z]/.test(k) && typeof result[k] === 'function' && typeof props[k] === 'function') {
        result[k] = chainEventHandlers(result[k], props[k]);
      } else if (k === 'ref') {
        result[k] = mergeRefs(result[k], props[k]);
      } else if (k === 'style') {
        result[k] = { ...result[k], ...props[k] };
      } else {
        result[k] = props[k] !== undefined ? props[k] : result[k];
      }
    }

    for (let k in props) {
      if (result[k] === undefined) {
        result[k] = props[k];
      }
    }
  }

  return result as UnionToIntersection<TupleTypes<T>>;
}
