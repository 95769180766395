import { ReactElement, cloneElement, forwardRef } from 'react';

import { useDrawerContext } from './DrawerContext';

export type DrawerDismissProps = {
  children: ReactElement;
};

export const DrawerDismiss = forwardRef<HTMLElement, DrawerDismissProps>(function DrawerDismiss(
  props,
  forwardedRef
) {
  const { children } = props;

  const { onClose } = useDrawerContext();

  return cloneElement(children, {
    ref: forwardedRef,
    onClick: onClose,
  });
});
