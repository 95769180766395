import { ComponentProps } from '@stitches/react';
import { Children, ReactElement, cloneElement, forwardRef, isValidElement } from 'react';

import { styled } from 'stitches';

const BreadcrumbRoot = styled('nav', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$3',

  marginBottom: '$6',
});

export type BreadcrumbsProps = ComponentProps<typeof BreadcrumbRoot> & {};

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(function BreadcrumbsWithRef(
  props,
  forwardedRef
) {
  const { children } = props;

  const childArray = Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as ReactElement[];

  const lastIndex = childArray.length - 1;
  const breadcrumbItems = childArray.map((child, index) => {
    const isActive = lastIndex === index;

    return cloneElement(child, {
      active: isActive,
    });
  });

  return <BreadcrumbRoot ref={forwardedRef}>{breadcrumbItems}</BreadcrumbRoot>;
});
