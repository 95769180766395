import { NotificationId } from 'interfaces';

import { api } from './api';

export type NotificationsQuery = {
  /**
   * Whether to return all notifications.
   */
  all?: boolean;

  /**
   * The notifications offset.
   */
  offset?: number;

  /**
   * The number of notifications to return.
   */
  limit?: number;
};

/**
 * Fetches the notifications for the current user.
 * @returns The user notifications.
 */
export async function getNotifications(query: NotificationsQuery = {}) {
  const {
    data: { data, totalCount, extraData },
  }: any = await api.request(`/notifications`, 'GET', { params: query });

  return {
    count: totalCount,
    data: data,
    unread: extraData?.unreadCount,
  };
}

/**
 * Marks a notification as read.
 * @param notificationId The notification ID.
 */
export function setNotificationRead(notificationId: NotificationId) {
  return api.patch(`/notifications/${notificationId}`);
}

/**
 * Deletes an existing notification.
 * @param notificationId The notification ID.
 */
export function deleteNotification(notificationId: NotificationId) {
  return api.delete(`/api/notifications/${notificationId}`);
}
