import { List, ListItem, ListItemContent } from 'components/list';
import { ResourceLink } from 'components/resource-link';
import { Text } from 'components/text';
import { useAssignedWorkflowResources } from 'data/workflows';
import { Application, Resource, WorkflowId } from 'interfaces';
import { styled } from 'stitches';

const StyledResourceLink = styled(ResourceLink, {
  color: '$neutral-900',
  textDecoration: 'none',

  alignItems: 'center',
  display: 'flex',
  gap: '$3',
});

type WorkflowResourcesProps = {
  application: Application;
  workflowId: WorkflowId;
  onSelect?(resource: Resource): void;
};

export function WorkflowResources(props: WorkflowResourcesProps) {
  const { application, workflowId, onSelect } = props;

  const { assignedResources } = useAssignedWorkflowResources(application.id, workflowId);

  if (!assignedResources.length) {
    return (
      <Text as="div" variant="secondary" align="center" gutter="xl">
        No resources available
      </Text>
    );
  }

  return (
    <List hideDivider>
      {assignedResources.map((item) => (
        <ListItem key={`${item.type}.${item.resourceId}`}>
          <ListItemContent>
            <StyledResourceLink
              applicationPath={application.fullPath}
              queryParams={{ workflowId: workflowId.toString() }}
              item={item}
              onClick={() => onSelect?.(item)}
            />
          </ListItemContent>
        </ListItem>
      ))}
    </List>
  );
}
