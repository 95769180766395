import { Provider, RefCallback, useLayoutEffect, useRef, useState } from 'react';

import { Descendant, DescendantManager, createContext, mergeRefs } from 'utils';

export function useDescendantManager<T extends HTMLElement = HTMLElement, K = {}>() {
  const [descendantManager] = useState(() => new DescendantManager<T, K>());

  useLayoutEffect(() => {
    return () => descendantManager.destroy();
  });

  return descendantManager;
}

/**
 * The return type of the `useDescendantManager` hook.
 */
export type UseDescendantManagerReturn = ReturnType<typeof useDescendantManager>;

export const [DescendantManagerContextProvider, useDescendantManagerContext] =
  createContext<UseDescendantManagerReturn>();

export function useDescendant<T extends HTMLElement = HTMLElement, K = {}>(
  descendant?: Descendant<K>
) {
  const descendantManager = useDescendantManagerContext();
  const [index, setIndex] = useState(-1);
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const currentNode = ref.current;
    return () => {
      if (currentNode) {
        descendantManager.unregister(currentNode);
      }
    };
  }, [descendantManager]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const datasetIndex = Number(ref.current.dataset.index);
    if (index !== datasetIndex && !Number.isNaN(datasetIndex)) {
      setIndex(datasetIndex);
    }
  });

  const refCallback = (
    descendant ? descendantManager.register(descendant) : descendantManager.register
  ) as RefCallback<T>;

  return {
    descendantManager,
    index,
    enabledIndex: descendantManager.enabledIndexOf(ref.current),
    register: mergeRefs(refCallback, ref),
  };
}

export function createDescendantManagerContext<T extends HTMLElement = HTMLElement, K = {}>() {
  const provider = DescendantManagerContextProvider as unknown as Provider<DescendantManager<T, K>>;

  const _useDescendantManagerContext = () =>
    useDescendantManagerContext() as unknown as DescendantManager<T, K>;
  const _useDescendantManager = () => useDescendantManager<T, K>();
  const _useDescendant = (descendant: Descendant<K>) => useDescendant<T, K>(descendant);

  return [provider, _useDescendantManagerContext, _useDescendantManager, _useDescendant] as const;
}
