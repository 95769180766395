import { useCallback } from 'react';

import { UseOpenStateOpts, useOpenState } from 'hooks';

import { useDialogContext } from './DialogContext';

export type UseDialogOpts = UseOpenStateOpts & {};

/**
 *
 */
export function useDialog(opts: UseDialogOpts = {}) {
  const { isOpen, open, close, toggle } = useOpenState(opts);

  const getDialogProps = useCallback(() => {
    return {
      isOpen,
      onClose: close,
    };
  }, [close, isOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
    getDialogProps,
  };
}

/**
 * Returns the function used to close the current dialog.
 */
export function useDialogDismiss() {
  const { onClose } = useDialogContext();

  return useCallback(() => {
    onClose?.();
  }, [onClose]);
}
