import { ReactChild, ReactNode } from 'react';

export type TabProps = {
  /**
   * The tab content.
   */
  children?: ReactNode;

  /**
   * The tab label.
   */
  label?: ReactChild;

  /**
   * Whether or not the label is stretched.
   */
  stretchLabel?: boolean;

  /**
   * Event handler triggered when a dismissible tab is dismissed.
   */
  onDismiss?(): void;
};

export function Tab(_: TabProps) {
  return null;
}
