import { RefCallback, useMemo } from 'react';

import { AssignableRef, assignRef } from 'utils';

/**
 * Merges two or more refs into a single function ref.
 * @param refs Refs to merge.
 *
 * @example
 * import React from 'react';
 * import { useMergedRefs } from 'hooks';
 *
 * const Component = React.forwardRef((props, ref) => {
 *   const internalRef = React.useRef(null);
 *   return <div {...props} ref={useMergedRefs(internalRef, ref)} />;
 * });
 */
export function useMergedRefs<T extends unknown>(
  ...refs: [AssignableRef<T>, AssignableRef<T>, ...AssignableRef<T>[]]
): RefCallback<T> {
  return useMemo(
    () => (value: T) => refs.forEach((ref) => assignRef(ref, value)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
}
