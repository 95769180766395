import { ComponentProps } from 'react';

import { styled } from 'stitches';

import { StyledButtonBase } from './Button';

export type IconButtonProps = ComponentProps<typeof IconButton>;

/**
 * Extended `Button` component used exclusively for icons.
 *
 * @example
 * import { FiX } from 'react-icons/fi';
 *
 * <IconButton>
 *    <FiX />
 * </IconButton>
 */
export const IconButton = styled(StyledButtonBase, {
  backgroundColor: 'transparent',
  borderRadius: '$round',
  padding: '$2',
  height: 'auto',

  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.075)',
  },

  '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.125)',
  },

  variants: {
    colorScheme: {
      neutral: {
        color: '$neutral-500',
      },
      primary: {
        color: '$primary-500',
      },
    },
  },
});
