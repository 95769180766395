import { RefObject, useMemo } from 'react';

import { Placement, SelectMenu } from 'components/select-menu';

import { useEntityListContext } from './EntityListContext';

export type EntityListSelectFilterProps<T> = {
  filterKey: string;
  labelProp?: string | ((item: T) => any);
  valueProp?: string | ((item: T) => any);
  label?: string;
  source?: T[];
  searchable?: boolean;
  multiple?: boolean;
  placement?: Placement;
  boundaryRef?: RefObject<HTMLElement>;
};

export function EntityListSelectFilter<T>(props: EntityListSelectFilterProps<T>) {
  const {
    filterKey,
    label = 'Filter',
    labelProp = (item) => item,
    valueProp = (item) => item,
    source = [],
    placement = 'bottom-end',
    searchable = false,
    multiple = false,
    boundaryRef,
  } = props;

  const { getFilter, setFilter } = useEntityListContext();

  const value = useMemo(() => {
    const filterValue = getFilter(filterKey);
    return filterValue[0];
  }, [filterKey, getFilter]);

  const handleClear = () => {
    setFilter(filterKey, null);
  };

  const handleChange = (value: any) => {
    setFilter(filterKey, value);
  };

  return (
    <SelectMenu
      label={label}
      source={source}
      value={value}
      searchable={searchable}
      placement={placement}
      multiple={multiple}
      boundaryRef={boundaryRef}
      labelProp={labelProp}
      valueProp={valueProp}
      onClear={handleClear}
      onChange={handleChange}
    />
  );
}
