import { Children, ReactElement, ReactNode, forwardRef, isValidElement } from 'react';

import { useControlledState, useDelayedOpenState } from 'hooks';
import { CSS, styled } from 'stitches';
import { clampValue } from 'utils';

import { TabProps } from './Tab';
import { TabLabel } from './TabLabel';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';

const TabsRoot = styled('div', {
  position: 'relative',
});

const TabListRevealer = styled('div', {
  position: 'absolute',
  transform: 'translateY(-1rem)',
  height: '2rem',
  width: '100%',
});

export type TabsProps = {
  /**
   * The content.
   */
  children?: ReactNode;

  /**
   * The index of the selected tab.
   */
  selectedIndex?: number;

  /**
   * The default selected index.
   * @default 0
   */
  defaultSelectedIndex?: number;

  /**
   * Whether to hide the tab list.
   * @default false
   */
  hideTabList?: boolean;

  /**
   * Whether to reveal the tab list when hovered over.
   */
  revealTabListOnHover?: boolean;

  /**
   * The delay, in milliseconds, between hovering over the hidden tab list and revealing it.
   * @default 400
   */
  revealEnterDelay?: number;

  /**
   * The delay, in milliseconds, between leaving the tab list and hiding it.
   * @default 300
   */
  revealLeaveDelay?: number;

  /**
   * Custom CSS.
   */
  css?: CSS;

  /**
   * Callback invoked when the selected index changes.
   * @param index The tab index.
   */
  onSelectedIndexChange?(index: number): void;
};

/**
 * Component used for displaying tabbed content.
 *
 * @example
 *
 * <Tabs>
 *    <Tab label="Tab 1">Tab one</Tab>
 *    <Tab label="Tab 2">Tab two</Tab>
 * </Tabs>
 */
export const Tabs = forwardRef<HTMLDivElement, TabsProps>(function Tabs(props, forwardedRef) {
  const {
    children: childrenProp = [],
    selectedIndex: selectedIndexProp,
    defaultSelectedIndex = 0,
    hideTabList = false,
    revealTabListOnHover = false,
    revealEnterDelay = 400,
    revealLeaveDelay = 300,
    css,
    onSelectedIndexChange,
  } = props;

  const {
    isOpen: tabListOpen,
    open: openTabList,
    close: closeTabList,
    reset: resetTimers,
  } = useDelayedOpenState();

  const [selectedIndex, setSelectedIndex] = useControlledState({
    value: selectedIndexProp,
    defaultValue: defaultSelectedIndex,
    onChange: onSelectedIndexChange,
  });

  const handleRevealerMouseEnter = () => {
    if (!revealTabListOnHover) {
      return;
    }

    openTabList(revealEnterDelay);
  };

  const handleRevealerMouseLeave = () => {
    resetTimers();
  };

  const handleTabListMouseLeave = () => {
    if (!revealTabListOnHover) {
      return;
    }

    closeTabList(revealLeaveDelay);
  };

  const handleTabListMouseEnter = () => {
    resetTimers();
  };

  const children = Children.toArray(childrenProp).filter((child) =>
    isValidElement(child)
  ) as ReactElement<TabProps>[];

  const clampedSelectedIndex = clampValue(selectedIndex, children.length);

  // Map the children to their corresponding tab labels.
  const tabLabels: ReactElement[] = [];
  let tabPanel: ReactElement | null = null;

  children.forEach((child, index) => {
    const childProps = child.props;
    let key = child.key || index;
    tabLabels.push(
      <TabLabel key={key} stretched={childProps.stretchLabel} onDismiss={childProps.onDismiss}>
        {childProps.label}
      </TabLabel>
    );

    if (index === clampedSelectedIndex) {
      tabPanel = <TabPanel key={key}>{childProps.children}</TabPanel>;
    }
  });

  const showTabList = !hideTabList || tabListOpen;

  return (
    // TODO: Remove any cast
    <TabsRoot ref={forwardedRef} css={css as any}>
      {showTabList && (
        <TabList
          selectedIndex={clampedSelectedIndex}
          onSelectedIndexChange={setSelectedIndex}
          onMouseEnter={handleTabListMouseEnter}
          onMouseLeave={handleTabListMouseLeave}
        >
          {tabLabels}
        </TabList>
      )}

      {!showTabList && revealTabListOnHover && (
        <TabListRevealer
          aria-hidden="true"
          data-testid="tablist-revealer"
          onMouseEnter={handleRevealerMouseEnter}
          onMouseLeave={handleRevealerMouseLeave}
        />
      )}

      {tabPanel}
    </TabsRoot>
  );
});
