import { ComponentProps, KeyboardEvent, MouseEvent, ReactNode, forwardRef } from 'react';

import { styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

import { useMenuContext } from './MenuContext';

const DEFAULT_TAG = 'div';

export const MenuItemRoot = styled(DEFAULT_TAG, {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: '$3',

  lineHeight: '$snug',
  outline: 'none',
  cursor: 'pointer',
  color: '$neutral-700',

  padding: '$3 $4',

  '&[href]': {
    textDecoration: 'none',
  },

  '&:focus, &:hover, &[data-focused]': {
    backgroundColor: '$neutral-50',
  },

  '&:active': {
    backgroundColor: '$neutral-100',
  },

  '&[aria-disabled=true]': {
    pointerEvents: 'none',
    color: '$neutral-300',
  },
});

export type MenuItemProps = ComponentProps<typeof MenuItemRoot> & {
  /**
   * The item content.
   */
  children?: ReactNode;

  /**
   * Whether the item should cause the menu to close when clicked.
   * @default true
   */
  closeOnClick?: boolean | ((e: MouseEvent) => boolean);

  /**
   * Whether the item is disabled.
   * @default false
   */
  disabled?: boolean;
};

export const MenuItem = forwardRef(function MenuItem(props, forwardedRef) {
  const {
    children,
    closeOnClick = true,
    disabled = false,
    tabIndex: tabIndexProp,
    onClick,
    onKeyDown,
    onKeyUp,
    ...rest
  } = props;

  const { onClose } = useMenuContext();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onClick?.(event);

    if (closeOnClick === true || (typeof closeOnClick === 'function' && closeOnClick?.(event))) {
      onClose?.();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget && event.key === ' ') {
      event.preventDefault();
    }

    onKeyDown?.(event);

    if (!disabled && event.target === event.currentTarget && event.key === 'Enter') {
      event.preventDefault();
      handleClick(event as unknown as MouseEvent<HTMLDivElement>);
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    onKeyUp?.(event);

    if (event.target === event.currentTarget && event.key === ' ' && !event.defaultPrevented) {
      handleClick(event as unknown as MouseEvent<HTMLDivElement>);
    }
  };

  return (
    <MenuItemRoot
      ref={forwardedRef}
      role="menuitem"
      tabIndex={disabled ? undefined : tabIndexProp ?? -1}
      aria-disabled={disabled ? 'true' : undefined}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      // TODO: Remove any cast
      {...(rest as any)}
    >
      {children}
    </MenuItemRoot>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, MenuItemProps>;
