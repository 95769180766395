import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as workflows from 'api/workflows';
import {
  ApplicationId,
  CreateWorkflowPayload,
  Resource,
  SetWorkflowGroupsPayload,
  SetWorkflowResourcesPayload,
  UpdateWorkflowPayload,
  Workflow,
  WorkflowId,
} from 'interfaces';

import { useResourcesQuery } from './resources';
import { QueryOptions } from './types';

/**
 * Hook used to query the application workflows.
 * @param applicationId The application ID.
 * @param options Additional `UseQueryOptions`.
 */
export function useWorkflowsQuery(
  applicationId: ApplicationId,
  options?: QueryOptions<Workflow[]>
) {
  const { data } = useQuery<Workflow[]>(
    ['workflows', { applicationId }],
    () => workflows.getWorkflows(applicationId),
    options
  );

  return {
    workflows: data!,
  };
}

/**
 * Hook used to query an application workflow.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 */
export function useWorkflowQuery(applicationId: ApplicationId, workflowId: WorkflowId) {
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ['workflows', workflowId, { applicationId }],
    () => workflows.getWorkflow(applicationId, workflowId),
    {
      initialData: queryClient
        ?.getQueryData<Workflow[]>(['workflows', { applicationId }])
        ?.find((x) => x.id === workflowId),
    }
  );

  return {
    workflow: data!,
  };
}

/**
 * Hook that returns a mutation used to create a new workflow.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useCreateWorkflowMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateWorkflowPayload) => workflows.createWorkflow(applicationId, payload),
    {
      onSuccess: (workflow) => {
        queryClient.setQueryData<Workflow[]>(['workflows', { applicationId }], (workflows) => {
          return [...(workflows || []), workflow];
        });

        queryClient.invalidateQueries(['workflows', { applicationId }]);
      },
    }
  );
}

export type UseUpdateWorkflowMutationPayload = UpdateWorkflowPayload & {
  workflowId: WorkflowId;
};

/**
 * Hook that returns a mutation used to update an existing workflow.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateWorkflowMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ workflowId, ...payload }: UseUpdateWorkflowMutationPayload) =>
      workflows.updateWorkflow(applicationId, workflowId, payload),
    {
      onSuccess: (workflow) => {
        queryClient.setQueryData<Workflow>(['workflows', workflow.id, { applicationId }], workflow);
        queryClient.setQueryData<Workflow[]>(['workflows', { applicationId }], (workflows) => {
          return workflows?.map((x) => (x.id === workflow.id ? workflow : x)) || [workflow];
        });
      },
    }
  );
}

export type UseDeleteWorkflowMutationPayload = {
  workflowId: WorkflowId;
};

/**
 * Hook that returns a mutation used to delete a workflow.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useDeleteWorkflowMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ workflowId }: UseDeleteWorkflowMutationPayload) =>
      workflows.deleteWorkflow(applicationId, workflowId),
    {
      onSuccess: (_, { workflowId }) => {
        queryClient.setQueryData<Workflow[]>(['workflows', { applicationId }], (workflows) => {
          return workflows!.filter((x) => x.id !== workflowId);
        });
      },
    }
  );
}

/**
 * Hook used to query an application workflow's resources.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 */
export function useWorkflowResourcesQuery(applicationId: ApplicationId, workflowId: WorkflowId) {
  const { data } = useQuery(['workflows', workflowId, 'resources', { applicationId }], () =>
    workflows.getWorkflowResources(applicationId, workflowId)
  );

  return {
    workflowResources: data!,
  };
}

export type SetWorkflowResourcesMutationPayload = SetWorkflowResourcesPayload & {
  workflowId: WorkflowId;
};

/**
 * Hook that returns a mutation used to set a workflow's resources.
 * @param applicationId The application ID.
 */
export function useSetWorkflowResourcesMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ workflowId, ...payload }: SetWorkflowResourcesMutationPayload) =>
      workflows.setWorkflowResources(applicationId, workflowId, payload),
    {
      onSuccess: (resources, { workflowId }) => {
        queryClient.setQueryData(
          ['workflows', workflowId, 'resources', { applicationId }],
          resources
        );
      },
    }
  );
}

/**
 * Hook used to query an application workflow's groups.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 */
export function useWorkflowGroupsQuery(applicationId: ApplicationId, workflowId: WorkflowId) {
  const { data } = useQuery(['workflows', workflowId, 'groups', { applicationId }], () =>
    workflows.getWorkflowGroups(applicationId, workflowId)
  );

  return {
    workflowGroups: data!,
  };
}

export type SetWorkflowGroupsMutationPayload = SetWorkflowGroupsPayload & {
  workflowId: WorkflowId;
};

/**
 * Hook that returns a mutation used to set a workflow's groups.
 * @param applicationId The application ID.
 */
export function useSetWorkflowGroupsMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ workflowId, ...payload }: SetWorkflowGroupsMutationPayload) =>
      workflows.setWorkflowGroups(applicationId, workflowId, payload),
    {
      onSuccess: (groups, { workflowId }) => {
        queryClient.setQueryData(['workflows', workflowId, 'groups', { applicationId }], groups);
      },
    }
  );
}

/**
 * Hook used to query and determine the assigned resources.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @returns The assigned workflow resources.
 */
export function useAssignedWorkflowResources(applicationId: ApplicationId, workflowId: WorkflowId) {
  const { workflowResources } = useWorkflowResourcesQuery(applicationId, workflowId);
  const { resources } = useResourcesQuery(applicationId);

  const assignedResources = useMemo(() => {
    return workflowResources.reduce<Resource[]>((acc, item) => {
      let resource = resources.find(
        (x) => x.type === item.resourceType && x.resourceId === item.resourceId
      );

      if (resource) {
        acc.push({ ...resource, label: item.label || resource.label });
      }

      return acc;
    }, []);
  }, [workflowResources, resources]);

  return {
    workflowResources,
    assignedResources,
  };
}
