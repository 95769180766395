import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Icon } from 'components/icon';
import { Text } from 'components/text';
import { Resource, ResourceType } from 'interfaces';

const RESOURCE_TYPE_PATHS: Record<ResourceType, string> = {
  DataView: 'scenarios/editor',
  Report: 'reports/viewer',
  MimicBoard: 'mimic-boards/viewer',
};

export type ResourceLinkProps = Omit<LinkProps, 'to' | 'resource'> & {
  applicationPath: string;
  item: Resource;
  queryParams?: Record<string, string> | URLSearchParams;
};

export const ResourceLink = forwardRef<HTMLAnchorElement, ResourceLinkProps>(
  (props, forwardedRef) => {
    const {
      item: resource,
      children,
      applicationPath,
      queryParams: queryParamsProp,
      ...rest
    } = props;

    const queryParams = new URLSearchParams(queryParamsProp);
    let pathname;

    switch (resource.type) {
      case 'DataView': {
        pathname = `/${applicationPath}/${RESOURCE_TYPE_PATHS['DataView']}`;
        queryParams.set('dataViewId', resource.resourceId.toString());
        break;
      }

      case 'Report': {
        pathname = `/${applicationPath}/${RESOURCE_TYPE_PATHS['Report']}`;
        queryParams.set('reportId', resource.resourceId.toString());
        break;
      }

      case 'MimicBoard': {
        pathname = `/${applicationPath}/${RESOURCE_TYPE_PATHS['MimicBoard']}`;
        queryParams.set('mimicBoardId', resource.resourceId.toString());
        break;
      }

      default:
        console.warn(`Unknown resource type '${resource.type}'.`);
        return null;
    }

    return (
      <Link ref={forwardedRef} to={{ pathname, search: queryParams.toString() }} {...rest}>
        <Icon src={resource.iconUri} size="$6" />
        <Text as="div" truncate>
          {resource.label || resource.name}
        </Text>
      </Link>
    );
  }
);
