import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { MenuItem, MenuItemProps } from './MenuItem';

export type MenuLinkProps = MenuItemProps & Pick<LinkProps, 'to' | 'target'>;

export const MenuLink = forwardRef<HTMLAnchorElement, MenuLinkProps>(function MenuLink(
  props,
  forwardedRef
) {
  // TODO: Remove any cast
  return <MenuItem ref={forwardedRef} as={Link} {...(props as any)} />;
});
