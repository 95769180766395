import {
  Children,
  KeyboardEvent,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
} from 'react';

import { useListFocus, useMergedRefs } from 'hooks';

import { styled } from 'stitches';

export const MenuListRoot = styled('div', {
  padding: '$2 0',
  flex: '1 1 auto',
  overflowX: 'auto',
});

export type MenuListProps = {
  children?: ReactNode;

  onKeyDown?(event: KeyboardEvent): void;
};

export const MenuList = forwardRef<HTMLDivElement, MenuListProps>(function (props, forwardedRef) {
  const { children, onKeyDown, ...rest } = props;

  const listRef = useRef<HTMLElement>(null);
  const rootRef = useMergedRefs(listRef, forwardedRef);

  const { focusNextItem, focusPreviousItem } = useListFocus({
    ref: listRef,
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        focusNextItem();
        break;
      }

      case 'ArrowUp': {
        event.preventDefault();
        focusPreviousItem();
        break;
      }
    }

    onKeyDown?.(event);
  };

  let activeItemIndex = -1;
  const items = Children.map(children, (child, index) => {
    if (!isValidElement(child)) {
      return null;
    }

    if (!child.props.disabled && activeItemIndex === -1) {
      activeItemIndex = index;

      if (child.props.tabIndex === undefined) {
        return cloneElement(child, {
          tabIndex: 0,
        });
      }
    }

    return child;
  });

  return (
    <MenuListRoot ref={rootRef} role="menu" tabIndex={-1} onKeyDown={handleKeyDown} {...rest}>
      {items}
    </MenuListRoot>
  );
});
