import { DataViewId } from './data-view';
import { ScenarioId } from './scenario';

export type OverrideId = number;

export interface Override {
  id?: OverrideId;
  type: OverrideType;
  stpId?: number | string | null;
  mtpId?: number | string | null;
  timestamp?: string;
  periodIndex?: number | null;
  timePeriod?: string;
  rowSnapshot?: OverrideSnapshot;
  effectiveFrom?: string;
  effectiveTo?: string;
  keyValues?: OverrideKeyValues;
  values?: OverrideValue[];
}

export interface OverrideValue {
  dataViewFieldId: number;
  previousValue: string | null;
  columnName?: string;
  value: string;
  isApplied?: boolean;
}

export enum OverrideType {
  Delete = 'Delete',
  Insert = 'Insert',
  Update = 'Update',
}

export type OverrideSnapshot = { [key: string]: string | number | null };

export type OverrideKeyValues = { [key: string]: string | number | null };

export interface SaveOverridesCommand {
  dataViewId: DataViewId;
  scenarioId: ScenarioId;
  bypassOverrides: boolean;
  overrides: Override[];
}

export interface DeleteOverridesCommand {
  dataViewId: DataViewId;
  scenarioId: ScenarioId;
  overrideIds: OverrideId[];
}
