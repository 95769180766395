import { ComponentProps, forwardRef } from 'react';

import { styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

const DEFAULT_TAG = 'div';

const IconPlaceholderRoot = styled(DEFAULT_TAG, {
  backgroundColor: '$neutral-200',
  color: '$neutral-500',

  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  userSelect: 'none',
  fontSize: '$sm',
  lineHeight: 1,
  verticalAlign: 'middle',

  borderRadius: '$md',
  outline: 'none',

  width: '40px',
  height: '40px',

  '&[aria-haspopup=true]': {
    cursor: 'pointer',
  },

  variants: {
    rounded: {
      true: {
        borderRadius: '$round',
      },
    },
  },
});

export type IconPlaceholderProps = ComponentProps<typeof IconPlaceholderRoot> & {
  size?: number | string;

  color?: string;

  alt?: string;
};

export const IconPlaceholder = forwardRef(function IconPlaceholder(props, forwardedRef) {
  const { children: childrenProp, size = '24px', alt, css, ...rest } = props;

  const children = childrenProp || getFallbackText(alt);

  return (
    <IconPlaceholderRoot
      ref={forwardedRef}
      // TODO: Remove any cast
      css={{ size, ...css } as any}
      {...rest}
    >
      {children}
    </IconPlaceholderRoot>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, IconPlaceholderProps>;

function getFallbackText(text?: string) {
  return text
    ?.split(/\s+/)
    .slice(0, 2)
    .filter(Boolean)
    .reduce((abbr, part) => abbr + part[0], '')
    .toUpperCase();
}
