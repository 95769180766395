import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { styled } from 'stitches';

const StyledTabListScrollButton = styled('button', {
  all: 'unset',

  width: '$14',
  display: 'flex',

  outline: 'none !important',

  cursor: 'pointer',

  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,

  color: '$neutral-500',

  '&:hover': {
    color: '$neutral-600',
  },

  '&:active': {
    color: '$neutral-700',
  },

  '&[disabled]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});

export type TabListScrollButtonProps = {
  direction?: 'next' | 'previous';
  disabled?: boolean;
  onClick?(): void;
};

export function TabListScrollButton(props: TabListScrollButtonProps) {
  const { direction = 'previous', disabled = false, onClick } = props;

  return (
    <StyledTabListScrollButton disabled={disabled} onClick={onClick}>
      {direction === 'previous' ? <FiChevronLeft /> : <FiChevronRight />}
    </StyledTabListScrollButton>
  );
}
