import { ReactNode, forwardRef } from 'react';

import { CSS, styled } from 'stitches';

import { SideLayoutContextProvider, useSideLayoutContextProvider } from './SideLayoutContext';

const SideLayoutRoot = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'block',
  height: '100%',
  width: '100%',

  translate: 'transform3d(0,0,0)',
});

export type SideLayoutProps = {
  children?: ReactNode;

  collapsible?: boolean;

  onCollapse?(): void;

  css?: CSS;
};

export const SideLayout = forwardRef<HTMLDivElement, SideLayoutProps>(function SideLayout(
  props,
  forwardedRef
) {
  const { children, ...rest } = props;

  const context = useSideLayoutContextProvider();

  return (
    <SideLayoutContextProvider value={context}>
      <SideLayoutRoot
        ref={forwardedRef}
        // TODO: Remove any cast
        {...(rest as any)}
      >
        {children}
      </SideLayoutRoot>
    </SideLayoutContextProvider>
  );
});
