import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as dataImportGroups from 'api/data-import-groups';
import {
  ApplicationId,
  CreateDataImportGroupPayload,
  CreateDataImportUploadPayLoad,
  DataImportGroup,
  DataImportGroupId,
  DataImportSessionId,
  UpdateDataImportGroupPayload,
} from 'interfaces';

/**
 * Hook used to get the data import groups for the specified application.
 * @param applicationId The application ID.
 */
export function useDataImportGroupsQuery(applicationId: ApplicationId) {
  const { data } = useQuery(['data-import-groups', { applicationId }], () =>
    dataImportGroups.getDataImportGroups(applicationId)
  );

  return {
    dataImportGroups: data!,
  };
}

/**
 * Hook used to query a data import group.
 * @param applicationId The application ID.
 * @param dataImportGroupId The data import group ID.
 */
export function useDataImportGroupQuery(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId
) {
  const queryClient = useQueryClient();
  const { data } = useQuery(
    ['data-import-groups', dataImportGroupId, { applicationId }],
    () => dataImportGroups.getDataImportGroup(applicationId, dataImportGroupId),
    {
      initialData: () =>
        queryClient
          .getQueryData<DataImportGroup[]>(['data-import-groups', { applicationId }])
          ?.find((x) => x.id === dataImportGroupId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(['data-import-groups', { applicationId }])?.dataUpdatedAt,
    }
  );

  return {
    dataImportGroup: data!,
  };
}

/**
 * Hook that returns a mutation used to create a new data import group.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useCreateDataImportGroupMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateDataImportGroupPayload) =>
      dataImportGroups.createDataImportGroup(applicationId, payload),
    {
      onSuccess: (dataImportGroup) => {
        queryClient.setQueryData<DataImportGroup[]>(
          ['data-import-groups', { applicationId }],
          (dataImportGroupList) => {
            return [...(dataImportGroupList || []), dataImportGroup];
          }
        );

        queryClient.invalidateQueries(['data-import-groups', { applicationId }]);
      },
    }
  );
}

export type UseUpdateDataImportGroupMutationPayload = UpdateDataImportGroupPayload & {
  dataImportGroupId: DataImportGroupId;
};

/**
 * Hook that returns a mutation used to update an existing data import group.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateDataImportGroupMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataImportGroupId, ...payload }: UseUpdateDataImportGroupMutationPayload) =>
      dataImportGroups.updateDataImportGroup(applicationId, dataImportGroupId, payload),
    {
      onSuccess: (dataImportGroup) => {
        queryClient.setQueryData<DataImportGroup>(
          ['data-import-groups', dataImportGroup.id, { applicationId }],
          dataImportGroup
        );
        queryClient.setQueryData<DataImportGroup[]>(
          ['data-import-groups', { applicationId }],
          (dataImportGroupList) => {
            return (
              dataImportGroupList?.map((x) =>
                x.id === dataImportGroup.id ? dataImportGroup : x
              ) || [dataImportGroup]
            );
          }
        );

        queryClient.invalidateQueries(['data-import-groups', { applicationId }]);
      },
    }
  );
}

export type UseDeleteDataImportGroupMutationPayload = {
  dataImportGroupId: DataImportGroupId;
};

/**
 * Hook that returns a mutation used to delete a data import group.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useDeleteDataImportGroupMutation(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataImportGroupId }: UseDeleteDataImportGroupMutationPayload) =>
      dataImportGroups.deleteDataImportGroup(applicationId, dataImportGroupId),
    {
      onSuccess: (_, { dataImportGroupId }) => {
        queryClient.setQueryData(
          ['data-import-groups', dataImportGroupId, { applicationId }],
          null
        );
        queryClient.setQueryData<DataImportGroup[]>(
          ['data-import-groups', { applicationId }],
          (dataImportGroupList) => {
            return dataImportGroupList?.filter((x) => x.id !== dataImportGroupId) ?? [];
          }
        );

        queryClient.invalidateQueries(['data-import-groups', { applicationId }]);
      },
    }
  );
}

export function useDownloadTemplateFile(
  applicationId: ApplicationId,
  dataImportGroupId: DataImportGroupId
) {
  return useCallback(async () => {
    const { blob, filename } = await dataImportGroups.getTemplateFile(
      applicationId,
      dataImportGroupId
    );

    saveAs(blob, filename);
  }, [applicationId, dataImportGroupId]);
}

/**
 * Hook that returns a mutation used to upload data import group file.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUploadDataImportGroupFileMutation(applicationId: ApplicationId) {
  return useMutation(
    (payload: CreateDataImportUploadPayLoad) =>
      dataImportGroups.UploadDataImportGroupFile(applicationId, payload),
    {
      onSuccess: () => {},
    }
  );
}

/**
 * Hook that returns a mutation used to download data import session file.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import session ID.
 * @returns saves file.
 */
export function useDownloadDataImportedFile(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
) {
  return useCallback(async () => {
    const { blob, filename } = await dataImportGroups.DownloadDataImportedFile(
      applicationId,
      dataImportSessionId
    );

    saveAs(blob, filename);
  }, [applicationId, dataImportSessionId]);
}

/**
 * Hook used to query a data import session.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import session ID.
 */
export function useDataImportSessionQuery(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
) {
  const { data } = useQuery(['data-import-session', dataImportSessionId, { applicationId }], () =>
    dataImportGroups.getDataImportSession(applicationId, dataImportSessionId)
  );

  return {
    dataImportSession: data!,
  };
}

/**
 * Hook that returns a mutation used to roll back data import.
 * @param applicationId The application ID.
 * @param dataImportSessionId The data import session ID.
 * @returns The `UseMutationResult`.
 */
export function useDataImportRollbackMutation(
  applicationId: ApplicationId,
  dataImportSessionId: DataImportSessionId
) {
  return useMutation(
    () => dataImportGroups.rollbackDataImport(applicationId, dataImportSessionId),
    {
      onSuccess: () => {},
    }
  );
}
