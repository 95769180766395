import { ReactElement, forwardRef } from 'react';

import { VariantProps, styled } from 'stitches';

const BadgeRoot = styled('div', {
  position: 'relative',
  display: 'inline-block',
});

const StyledBadge = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$xs',
  top: 0,
  right: 0,
  transform: 'translate(25%, -25%)',
  height: '$5',
  minWidth: '$5',
  padding: '0 $1',
  borderRadius: '$pill',
  lineHeight: '1',
  fontVariantNumeric: 'tabular-nums',

  variants: {
    colorScheme: {
      neutral: {
        backgroundColor: '$neutral-400',
        color: '$neutral-contrast',
      },
      primary: {
        backgroundColor: '$primary-400',
        color: '$primary-contrast',
      },
      warning: {
        backgroundColor: '$warning-400',
        color: '$warning-contrast',
      },
      danger: {
        backgroundColor: '$danger-400',
        color: '$danger-contrast',
      },
      success: {
        backgroundColor: '$success-400',
        color: '$success-contrast',
      },
    },
  },

  defaultVariants: {
    colorScheme: 'primary',
  },
});

export type BadgeProps = {
  /**
   * The badge main content.
   */
  children: ReactElement;

  /**
   * The badge content.
   */
  badgeContent?: any;

  /**
   * The badge background color.
   * @default 'primary'
   */
  colorScheme?: VariantProps<typeof StyledBadge>['colorScheme'];
};

export const Badge = forwardRef<HTMLElement, BadgeProps>(function Badge(props, forwardedRef) {
  const { children, badgeContent = null, colorScheme = 'primary' } = props;

  return (
    <BadgeRoot>
      {children}

      {!!badgeContent && <StyledBadge {...({ colorScheme } as any)}>{badgeContent}</StyledBadge>}
    </BadgeRoot>
  );
});
