import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { ReactElement, Ref, forwardRef } from 'react';

import { SlideDirection, TransitionEasings, Variants, slideTransition, withDelay } from './utils';

export type SlideOptions = {
  direction?: SlideDirection;
};

const defaultTransition = {
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeInOut,
  },
  enter: {
    type: 'spring',
    damping: 25,
    stiffness: 180,
  },
};

const variants: Variants<SlideOptions> = {
  enter: ({ delay, direction, transition, transitionEnd }) => ({
    ...slideTransition({ direction }).enter,
    transition: transition?.enter ?? withDelay.enter(defaultTransition.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ delay, direction, transition, transitionEnd }) => ({
    ...slideTransition({ direction }).exit,
    transition: transition?.exit ?? withDelay.exit(defaultTransition.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

export type SlideProps = HTMLMotionProps<'div'> &
  SlideOptions & {
    in?: boolean;
    unmountOnExit?: boolean;
    children?:
      | ReactElement
      | ((motionProps: HTMLMotionProps<'div'>, ref: Ref<HTMLDivElement>) => ReactElement);
  };

export const Slide = forwardRef<HTMLDivElement, SlideProps>(function Slide(props, ref) {
  const {
    in: isOpen,
    direction = 'right',
    transition,
    unmountOnExit = false,
    children,
    style,
    ...rest
  } = props;

  const animate = isOpen || unmountOnExit ? 'enter' : 'exit';

  const custom = { transition, direction };

  const motionProps: any = {
    initial: 'exit',
    animate,
    exit: 'exit',
    custom,
    variants,
    style: {
      position: 'fixed',
      ...slideTransition({ direction }).position,
      ...style,
    },
    ...rest,
  };

  return typeof children === 'function' ? (
    children(motionProps, ref)
  ) : (
    <motion.div ref={ref} {...(motionProps as any)}>
      {children}
    </motion.div>
  );
});

export const SlidePresence = forwardRef<HTMLDivElement, SlideProps>(function SlidePresence(
  props,
  ref
) {
  const { in: isOpen, direction = 'right', transition, unmountOnExit = false, ...rest } = props;

  const show = unmountOnExit ? isOpen && unmountOnExit : true;

  const custom = { transition, direction };

  return (
    <AnimatePresence custom={custom}>
      {show && <Slide ref={ref} in={isOpen} direction={direction} custom={custom} {...rest} />}
    </AnimatePresence>
  );
});
