import { ComponentProps, forwardRef } from 'react';

import { styled } from 'stitches';

const StyledMenuTitle = styled('div', {
  textTransform: 'uppercase',
  lineHeight: '$sm',
  fontSize: '$sm',
  color: '$neutral-500',
  userSelect: 'none',

  padding: '$3 $4',
});

type MenuTitleProps = ComponentProps<typeof StyledMenuTitle>;

export const MenuTitle = forwardRef<HTMLDivElement, MenuTitleProps>(function MenuTitle(
  props,
  forwardedRef
) {
  // TODO: Remove any cast
  return <StyledMenuTitle ref={forwardedRef} {...(props as any)} tabIndex={-1} />;
});
