import { ChangeEvent, ReactNode, forwardRef, useCallback } from 'react';

import { VisuallyHidden } from 'components/visually-hidden';
import { useControlledState, useId } from 'hooks';
import { styled } from 'stitches';

const SwitchLabel = styled('label', {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'inline-flex',
  gap: '$3',

  marginRight: '$3',

  cursor: 'pointer',
});

const SwitchIndicator = styled('div', {
  backgroundColor: 'white',
  position: 'absolute',

  borderRadius: '$round',

  height: 'calc($$trackSize - 4px)',
  width: 'calc($$trackSize - 4px)',

  transition: 'left ease-in-out 80ms',

  left: '2px',
  top: '2px',
});

const SwitchTrack = styled('div', {
  $$trackSize: '16px',

  backgroundColor: '$neutral-300',

  borderRadius: '$pill',
  position: 'relative',
  display: 'inline-block',

  width: '$9',
  height: '$$trackSize',

  variants: {
    checked: {
      true: {
        backgroundColor: '$primary-500 !important',

        [`${SwitchIndicator}`]: {
          left: 'calc(100% - ($$trackSize - 2px))',
        },
      },
    },
  },
});

export type SwitchProps = {
  children?: ReactNode;
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?(isChecked: boolean): void;
};

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  props,
  forwardedRef
) {
  const { children, id, checked: checkedProp, defaultChecked, disabled, onChange } = props;

  const [isChecked, setChecked] = useControlledState({
    value: checkedProp,
    defaultValue: Boolean(defaultChecked),
    onChange,
  });

  const switchId = useId(id);
  const inputId = `${switchId}-input`;

  const onInputChange = useCallback(
    (e: ChangeEvent) => {
      if (!disabled) {
        let nextValue = !isChecked;

        setChecked(nextValue);
      }
    },
    [isChecked, disabled, setChecked]
  );

  return (
    <SwitchLabel htmlFor={inputId}>
      <SwitchTrack checked={isChecked}>
        <SwitchIndicator />
      </SwitchTrack>

      {children}

      <VisuallyHidden>
        <input
          ref={forwardedRef}
          type="checkbox"
          role="switch"
          id={inputId}
          onChange={onInputChange}
        />
      </VisuallyHidden>
    </SwitchLabel>
  );
});
