import { HubConnectionBuilder } from '@microsoft/signalr';
import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { getNotifications, setNotificationRead } from 'api/notifications';
import { authClient } from 'auth';
import { Notification } from 'interfaces';

export const PAGE_SIZE = 10;

export interface NotificationState {
  /**
   * The notification items
   */
  readonly items: Notification[];

  /**
   * The showAll
   */
  readonly showAll: boolean;

  /**
   * The page
   */
  readonly page: number;

  /**
   * Total count of notifications
   */
  readonly totalCount: number;

  /**
   * Total count of unread notifications
   */
  readonly unreadCount: number;
}

const notificationsConnection = new HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_DECISIO_PATH}/hubs/notifications`, {
    withCredentials: false,
    accessTokenFactory: async () => {
      const token = await authClient.getAccessToken();
      return token || '';
    },
  })
  .build();

const showBrowserNotification = (notification: Notification) => {
  if (!('Notification' in window)) {
    console.warn('This browser does not support desktop notification.');
  } else if (window.Notification.permission === 'granted') {
    new window.Notification('Decisio', { body: notification.title, icon: 'favicon.ico' });
  } else if (window.Notification.permission !== 'denied') {
    window.Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        new window.Notification('Decisio', { body: notification.title, icon: 'favicon.ico' });
      } else {
        console.warn('Browser notification is not allowed.');
      }
    });
  }
};

export const notificationsState = atom<NotificationState>({
  key: 'notifications',
  default: {
    items: [],
    showAll: false,
    page: 0,
    totalCount: 0,
    unreadCount: 0,
  },
  effects: [
    ({ setSelf, getInfo_UNSTABLE }) => {
      Promise.all([getNotifications({ all: false, limit: PAGE_SIZE, offset: 0 })]).then(
        ([notifications]) => {
          setSelf({
            items: notifications.data,
            showAll: false,
            page: 0,
            totalCount: notifications.count,
            unreadCount: notifications.unread,
          });
        }
      );

      notificationsConnection.on('receiveNotification', (notification: Notification) => {
        const current = getInfo_UNSTABLE(notificationsState)?.loadable?.contents;
        getNotifications({
          all: current?.showAll || false,
          limit: PAGE_SIZE,
          offset: (current?.page || 0) * PAGE_SIZE,
        }).then((notifications) => {
          setSelf({
            items: notifications.data,
            showAll: current?.showAll || false,
            page: current?.page || 0,
            totalCount: notifications.count,
            unreadCount: (current?.unreadCount || 0) + 1,
          });
        });

        showBrowserNotification(notification);
      });

      notificationsConnection.start();

      return () => {
        notificationsConnection.stop();
      };
    },
  ],
});

export function useNotifications() {
  return useRecoilValue(notificationsState);
}

export function useSendTestNotification() {
  return useCallback(() => {
    notificationsConnection.send('sendTest');
  }, []);
}

export function useUpdateNotificationStatus() {
  const current = useRecoilValue(notificationsState);
  const setNotificationsState = useSetRecoilState(notificationsState);

  return useCallback(
    async (notificationId: string) => {
      await setNotificationRead(notificationId);

      getNotifications({
        all: current.showAll,
        limit: PAGE_SIZE,
        offset: current.page * PAGE_SIZE,
      }).then((notifications) => {
        setNotificationsState((prevValue) => ({
          ...prevValue,
          items: notifications.data,
          totalCount: notifications.count,
          unreadCount: prevValue.unreadCount - 1,
        }));
      });
    },
    [current.page, current.showAll, setNotificationsState]
  );
}

export function useUpdateNotifications() {
  const setNotificationsState = useSetRecoilState(notificationsState);

  return useCallback(
    (showAll: boolean, page: number) => {
      getNotifications({
        all: showAll,
        limit: PAGE_SIZE,
        offset: page * PAGE_SIZE,
      }).then((notifications) => {
        setNotificationsState((prevValue) => ({
          ...prevValue,
          items: notifications.data,
          showAll,
          page,
          totalCount: notifications.count,
        }));
      });
    },
    [setNotificationsState]
  );
}
