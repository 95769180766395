import { ReactChild, ReactNode, forwardRef, useLayoutEffect, useRef } from 'react';
import { DraggableData } from 'react-draggable';

import { useControlledState, useEventCallback, useMergedRefs } from 'hooks';
import { CSS, styled } from 'stitches';

import { ResizeHandle } from './ResizeHandle';
import { useSideLayoutContext } from './SideLayoutContext';
import { SidePanelPosition } from './types';

const SidePanelRoot = styled('div', {
  position: 'absolute',
  display: 'block',
  bottom: '0',
  top: '0',
  height: '100%',
});

export type SidePanelRenderChildrenOptions = {
  isCollapsed: boolean;
};

export type SidePanelProps = {
  children?: ReactChild | ReactChild[] | ((options: SidePanelRenderChildrenOptions) => ReactNode);

  defaultWidth?: number;

  width?: number;

  minWidth?: number;

  maxWidth?: number;

  hidden?: boolean;

  collapsed?: boolean;

  defaultCollapsed?: boolean;

  resizable?: boolean;

  collapsible?: boolean;

  position?: SidePanelPosition;

  css?: CSS;

  onWidthChange?(width: number): void;

  onCollapsedChange?(isCollapsed: boolean): void;
};

export const SidePanel = forwardRef<HTMLDivElement, SidePanelProps>(function SidePanel(
  props,
  forwardedRef
) {
  const {
    children,
    position = 'start',
    width: widthProp,
    defaultWidth = 300,
    maxWidth,
    minWidth = 100,
    collapsible = false,
    collapsed: collapsedProp,
    defaultCollapsed = false,
    hidden = false,
    resizable = true,
    onCollapsedChange,
    onWidthChange,
    ...rest
  } = props;

  const { register, deregister } = useSideLayoutContext();

  const panelRef = useRef<HTMLDivElement>(null);
  const rootRef = useMergedRefs(forwardedRef, panelRef);

  const [isCollapsed, setCollapsed] = useControlledState({
    value: collapsedProp,
    defaultValue: defaultCollapsed,
    onChange: onCollapsedChange,
  });

  const [width, setWidth] = useControlledState({
    value: widthProp,
    defaultValue: defaultWidth,
  });

  const handleDrag = useEventCallback((e: any, data: DraggableData) => {
    const { deltaX } = data;

    let newWidth = Math.max(width + deltaX / 1, minWidth);
    if (maxWidth) {
      newWidth = Math.min(newWidth, maxWidth);
    }

    setWidth(newWidth);
  });

  const style = {
    right: position === 'end' ? 0 : undefined,
    left: position === 'start' ? 0 : undefined,
    width,
    ...(hidden && { display: 'none' }),
  };

  useLayoutEffect(() => {
    register(panelRef, position);
    return () => deregister(position);
  }, [position, register, deregister]);

  return (
    <SidePanelRoot
      ref={rootRef}
      style={style}
      // TODO: Remove any cast
      {...(rest as any)}
    >
      {typeof children === 'function' ? children({ isCollapsed }) : children}

      {resizable && (
        <ResizeHandle
          collapsible={collapsible}
          collapsed={isCollapsed}
          onCollapsedChange={setCollapsed}
          onDrag={handleDrag}
        />
      )}
    </SidePanelRoot>
  );
});
