import { cloneElement } from 'react';

import { styled } from 'stitches';

import { IconPlaceholder } from './IconPlaceholder';

const IconImage = styled('img', {});

export type InlineSvgIconProps = {
  svg: any;
  size?: string | number;
  colorScheme?: 'primary' | 'default' | null;
};

export type SrcIconProps = {
  src: string | null | undefined;
  size?: string | number;
  alt?: string;
};

export type SvgIconProps = InlineSvgIconProps | SrcIconProps;

export function Icon(props: SvgIconProps) {
  const { size = 24 } = props;

  if (isInlineSvgProps(props)) {
    return cloneElement(props.svg, {
      fill: 'currentColor',
      width: size,
      height: size,
    });
  }

  return props.src ? (
    <IconImage src={props.src} alt={props.alt} css={{ size }} />
  ) : (
    <IconPlaceholder size={size} alt={props.alt} rounded />
  );
}

function isInlineSvgProps(value: SvgIconProps): value is InlineSvgIconProps {
  return 'svg' in value;
}
