import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Breadcrumb, BreadcrumbProps } from './Breadcrumb';

export type BreadcrumbLinkProps = BreadcrumbProps & Pick<LinkProps, 'to'> & {};

export const BreadcrumbLink = forwardRef<HTMLAnchorElement, BreadcrumbLinkProps>(
  function BreadcrumbLink(props, forwardedRef) {
    const { ...rest } = props;

    return <Breadcrumb as={Link} ref={forwardedRef} {...(rest as any)} />;
  }
);
