import { DataViewFieldId, DataViewId } from './data-view';
import { ProfileDefinitionId } from './profile-definition';
import { ScenarioId } from './scenario';

export type TimeSeriesAdjustmentId = number;

export interface TimeSeriesAdjustment {
  readonly id: TimeSeriesAdjustmentId;
  readonly dataViewFieldId: number;
  readonly scenarioId: number;
  readonly stpId: number;
  readonly adjustmentType: TimeSeriesAdjustmentType;
  readonly adjustmentValue: number;
  readonly periodType: TimeSeriesAdjustmentPeriodType;
  readonly effectiveFrom: string;
  readonly effectiveTo: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export enum TimeSeriesAdjustmentType {
  Add = 'Add',
  Factor = 'Factor',
}

export enum TimeSeriesAdjustmentPeriodType {
  Year = 'Year',
  Quarter = 'Quarter',
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
  Hour = 'Hour',
  Profile = 'Profile',
}

export interface ApplyTimeSeriesAdjustmentsPayload {
  dataViewId: DataViewId;
  scenarioId: ScenarioId;
}

export interface CreateTimeSeriesAdjustmentsPayload {
  scenarioId: ScenarioId;
  dataViewId: DataViewId;
  dataViewFieldId: DataViewFieldId;
  profileDefinitionId: ProfileDefinitionId | null;
  adjustmentType: TimeSeriesAdjustmentType;
  adjustmentValue: number | null;
  periodType: TimeSeriesAdjustmentPeriodType;
  effectiveFrom: string;
  effectiveTo: string;
  stpIds?: number[];
}

export interface UpdateTimeSeriesAdjustmentPayload {
  dataViewId: DataViewId;
  dataViewFieldId: DataViewFieldId;
  profileDefinitionId: ProfileDefinitionId | null;
  effectiveFrom: string;
  effectiveTo: string;
  periodType: TimeSeriesAdjustmentPeriodType;
  adjustmentType: TimeSeriesAdjustmentType;
  adjustmentValue: number | null;
}
