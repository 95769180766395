import { ComponentProps } from '@stitches/react';
import { forwardRef } from 'react';

import { Spinner } from 'components/spinner';
import { styled } from 'stitches';
import type * as Polymorphic from 'stitches/polymorphic';

const DEFAULT_TAG = 'button';

export const StyledButtonBase = styled(DEFAULT_TAG, {
  // Reset
  all: 'unset',
  userSelect: 'none',
  outline: 'none',
  margin: 0,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  appearance: 'none',
  boxSizing: 'border-box',

  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'inline-flex',
  flexShrink: 0,

  textDecoration: 'none',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: '$normal',
  lineHeight: '$none',
  fontSize: '$default',
  whiteSpace: 'nowrap',
  cursor: 'pointer',

  border: '1px solid',
  borderColor: 'transparent',
  borderRadius: '$default',

  padding: '0 $3',
  height: '$10',

  transition: 'background-color 50ms ease-in-out',

  '&:focus': {
    outline: 'none',
  },

  '&:disabled, &[data-disabled], &[aria-disabled]': {
    pointerEvents: 'none',
    opacity: 0.8,
  },
});

export const StyledButton = styled(StyledButtonBase, {
  '&:active': {
    backgroundColor: '$neutral-100',
  },

  variants: {
    colorScheme: {
      neutral: {
        backgroundColor: '$neutral-100',
        color: '$neutral-600',

        '@hover': {
          '&:hover': {
            backgroundColor: '$neutral-200',
          },
        },

        '&:active': {
          backgroundColor: '$neutral-300',
        },
      },

      primary: {
        backgroundColor: '$primary-500',
        color: '$primary-contrast',

        '@hover': {
          '&:hover': {
            backgroundColor: '$primary-600',
          },
        },

        '&:active': {
          backgroundColor: '$primary-700',
        },
      },

      warning: {
        backgroundColor: '$warning-500',
        color: '$warning-contrast',

        '@hover': {
          '&:hover': {
            backgroundColor: '$warning-600',
          },
        },

        '&:active': {
          backgroundColor: '$warning-700',
        },
      },

      danger: {
        backgroundColor: '$danger-500',
        color: '$danger-contrast',

        '@hover': {
          '&:hover': {
            backgroundColor: '$danger-600',
          },
        },

        '&:active': {
          backgroundColor: '$danger-700',
        },
      },

      success: {
        backgroundColor: '$success-500',
        color: '$success-contrast',

        '@hover': {
          '&:hover': {
            backgroundColor: '$success-600',
          },
        },

        '&:active': {
          backgroundColor: '$success-700',
        },
      },
    },

    variant: {
      link: {
        // all: 'unset',
        background: 'none !important',
        color: '$primary-500',
        display: 'inline-block',
        cursor: 'pointer',
        padding: '0 !important',
        margin: 0,
        border: 'none',
        height: 'auto !important',
      },
    },

    size: {
      sm: {
        fontSize: '$sm',
        padding: '$1 $2',
        height: '$8',
      },
      md: {},
      lg: {
        fontSize: '$lg',
        padding: '$3 $5',
        height: '$11',
      },
    },

    pending: {
      true: {
        pointerEvents: 'none',
        opacity: 0.8,
      },
    },

    active: {
      true: {
        backgroundColor: '$neutral-200',

        '&:active': {
          backgroundColor: '$neutral-300',
        },
      },
    },
  },

  defaultVariants: {},
});

export type ButtonProps = ComponentProps<typeof StyledButton> & {
  /**
   * Whether the button has a pending action.
   * @default false
   */
  pending?: boolean;
};

/**
 * Base button component.
 *
 * @example
 *
 * <Button>My button</Button>
 */
export const Button = forwardRef((props, forwardedRef) => {
  const { children, pending, disabled, ...rest } = props;

  return (
    <StyledButton
      ref={forwardedRef}
      disabled={(disabled || pending) ?? undefined}
      // TODO: Remove `any` cast
      {...(rest as any)}
    >
      {pending && <Spinner size="1em" indeterminate css={{ marginRight: '$3' }} />}
      {children}
    </StyledButton>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, ButtonProps>;
