import { PopoverTrigger, PopoverTriggerProps } from 'components/popover';

import { useMenuContext } from './MenuContext';

export type MenuTriggerProps = PopoverTriggerProps & {
  /**
   * Callback invoked when the menu is closed.
   */
  onClose?(): void;
};

/**
 * A wrapper component used to automatically control a menu's open state.
 *
 * @example
 *
 * <MenuTrigger>
 *    <Button>Toggle</Button>
 *    <Menu>
 *      <MenuList>
 *        <MenuItem>Item</MenuItem>
 *        <MenuItem>Item</MenuItem>
 *        <MenuItem>Item</MenuItem>
 *      </MenuList>
 *    </Menu>
 * </MenuTrigger>
 */
export function MenuTrigger(props: MenuTriggerProps) {
  const { placement, trigger = 'click', ...rest } = props;

  const parentContext = useMenuContext();
  const triggerProps: Partial<PopoverTriggerProps> = {
    trigger,
    placement: placement ?? 'bottom-start',
  };

  if (parentContext) {
    triggerProps.trigger = 'hover';
    triggerProps.placement = placement ?? 'right-start';
    triggerProps.disablePortal = true;
    triggerProps.autoFocus = false;
    triggerProps.returnFocus = false;
  }

  return <PopoverTrigger enterDelay={0} {...triggerProps} {...rest} />;
}
