import { useQuery } from 'react-query';

import { getDataViews } from 'api/data-views';
import { getMimicBoards } from 'api/mimic-boards';
import { getReports } from 'api/reports';
import { ApplicationId, Resource, ResourceEntity, ResourceType } from 'interfaces';

export function useResourcesQuery(applicationId: ApplicationId) {
  // TODO: use `useQueries` when it supports Suspense: https://github.com/tannerlinsley/react-query/pull/2109
  const { data } = useQuery(['resources', { applicationId }], async () => {
    const [dataViews, mimicBoards, reports] = await Promise.all([
      getDataViews(applicationId),
      getMimicBoards(applicationId),
      getReports(applicationId),
    ]);

    return Object.fromEntries<ResourceEntity[]>([
      ['DataView', dataViews],
      ['Report', reports],
      ['MimicBoard', mimicBoards],
    ]);
  });

  const resourceTypes = Object.keys(data!) as ResourceType[];
  const resources = resourceTypes.reduce<Resource[]>((acc, type) => {
    return [
      ...acc,
      ...data![type].map((x) => ({
        type,
        resourceId: x.id,
        name: x.name,
        label: x.label,
        iconUri: x.iconUri,
        categoryId: x.categoryId,
      })),
    ];
  }, []);

  return {
    resourceTypes,
    resources,
  };
}
