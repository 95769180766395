import { ReactChild, forwardRef } from 'react';

import {
  FieldHint,
  FieldLabel,
  FormField,
  FormFieldContextConsumer,
  FormFieldErrors,
} from 'components/form-field';

import { Input, InputProps } from './Input';

export type InputFieldProps = InputProps & {
  /**
   * The label content.
   */
  label?: ReactChild;

  /**
   * Whether the field is required.
   * @default false
   */
  required?: boolean;

  /**
   * Any error text.
   */
  error?: string | string[] | null | boolean;

  /**
   * The field hint.
   */
  hint?: ReactChild | null;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(function InputField(
  props,
  forwardedRef
) {
  const { label, id, required = false, css, hint, error, ...rest } = props;

  return (
    // TODO: Remove any cast
    <FormField id={id} required={required} error={error} css={css as any}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <FormFieldContextConsumer>
        {({ invalid }) => (
          <Input
            id={id}
            ref={forwardedRef}
            required={required}
            state={invalid ? 'error' : undefined}
            {...rest}
          />
        )}
      </FormFieldContextConsumer>

      {hint && <FieldHint>{hint}</FieldHint>}
      <FormFieldErrors />
    </FormField>
  );
});
