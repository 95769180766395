import {
  ApplicationId,
  CreateMimicBoardPalettePayload,
  CreateMimicBoardPayload,
  MimicBoard,
  MimicBoardGraph,
  MimicBoardId,
  MimicBoardPalette,
  MimicBoardPaletteId,
  MimicBoardPaletteObjectId,
  UpdateMimicBoardGraphPayload,
  UpdateMimicBoardPalettePayload,
  UpdateMimicBoardPayload,
} from 'interfaces';

import { api } from './api';

/**
 * Fetches all mimic boards belonging to the specified application.
 * @param applicationId The application ID.
 * @returns An `Promise` that emits the mimic boards when the request has completed.
 */
export function getMimicBoards(applicationId: ApplicationId) {
  return api.get<MimicBoard[]>(`/applications/${applicationId}/mimic-boards`);
}

/**
 * Creates a new mimic board for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the mimic board.
 * @returns An `Promise` that emits the created mimic board when the request has completed.
 */
export function createMimicBoard(applicationId: ApplicationId, payload: CreateMimicBoardPayload) {
  return api.post<MimicBoard>(`/applications/${applicationId}/mimic-boards`, payload);
}

/**
 * Deletes an existing mimic board belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @returns An `Promise` that emits when the request has completed.
 */
export function deleteMimicBoard(applicationId: ApplicationId, mimicBoardId: MimicBoardId) {
  return api.delete(`/applications/${applicationId}/mimic-boards/${mimicBoardId}`);
}

/**
 * Fetches a mimic board belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @returns An `Promise` that emits the mimic board when the request has completed. If the API throws an error with a 404 status code,
 * a null value is emitted.
 */
export function getMimicBoard(applicationId: ApplicationId, mimicBoardId: MimicBoardId) {
  return api.get<MimicBoard>(`/applications/${applicationId}/mimic-boards/${mimicBoardId}`);
}

/**
 * Fetches a mimic board graph belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @returns An `Promise` that emits the mimic board when the request has completed. If the API throws an error with a 404 status code,
 * a null value is emitted.
 */
export function getMimicBoardGraph(applicationId: ApplicationId, mimicBoardId: MimicBoardId) {
  return api.get<MimicBoardGraph>(
    `/applications/${applicationId}/mimic-boards/${mimicBoardId}/graph`
  );
}

/**
 * Updates an existing mimic board belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @param payload The request payload used to update the mimic board.
 * @returns An `Promise` that emits the updated mimic board when the request has completed.
 */
export function updateMimicBoard(
  applicationId: ApplicationId,
  mimicBoardId: MimicBoardId,
  payload: UpdateMimicBoardPayload
) {
  return api.put<MimicBoard>(
    `/applications/${applicationId}/mimic-boards/${mimicBoardId}`,
    payload
  );
}

/**
 * Updates an existing mimic board nodes belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @param payload The request payload used to update the mimic board nodes.
 * @returns An `Promise` that emits the updated mimic board when the request has completed.
 */
export function updateMimicBoardGraph(
  applicationId: ApplicationId,
  mimicBoardId: MimicBoardId,
  payload: UpdateMimicBoardGraphPayload
) {
  return api.put<MimicBoardGraph>(
    `/applications/${applicationId}/mimic-boards/${mimicBoardId}/graph`,
    payload
  );
}

/**
 * Adds or removes the mimic board as a favorite for the current user.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @param isFavorite Whether the mimic board is a favorite.
 */
export function setMimicBoardFavorited(
  applicationId: ApplicationId,
  mimicBoardId: MimicBoardId,
  isFavorite: boolean
) {
  return api.patch(`/applications/${applicationId}/mimic-boards/${mimicBoardId}/favorite`, {
    isFavorite,
  });
}

/**
 * Updates an existing mimc board's sort order belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @param currentSort The mimic board current sort order.
 * @param newSort The mimic board updated sort order.
 */
export function setMimicBoardSortOrder(
  applicationId: ApplicationId,
  mimicBoardId: MimicBoardId,
  currentSort: number,
  newSort: number
) {
  return api.patch<void>(`/applications/${applicationId}/mimic-boards/${mimicBoardId}/sort`, {
    currentSort,
    newSort,
  });
}

/**
 * Sets the tags for the specified mimic board.
 * @param applicationId The application ID.
 * @param mimicBoardId The mimic board ID.
 * @param tagIds The tag IDs.
 */
export function setMimicBoardTags(
  applicationId: ApplicationId,
  mimicBoardId: MimicBoardId,
  tagIds: number[]
) {
  return api.patch<MimicBoard>(`/applications/${applicationId}/mimic-boards/${mimicBoardId}/tags`, {
    tagIds,
  });
}

/**
 * Fetches all mimic board palettes belonging to the specified application.
 * @param applicationId The application ID.
 */
export function getMimicBoardPalettes(applicationId: number) {
  return api.get<MimicBoardPalette[]>(`/applications/${applicationId}/mimic-board-palettes`);
}

/**
 * Fetches a single mimic board palette belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardPaletteId The mimic board palette ID.
 */
export function getMimicBoardPalette(
  applicationId: ApplicationId,
  mimicBoardPaletteId: MimicBoardPaletteId
) {
  return api.get<MimicBoardPalette>(
    `/applications/${applicationId}/mimic-board-palettes/${mimicBoardPaletteId}`
  );
}

/**
 * Creates a new mimic board palette for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the mimic board palette.
 */
export function createMimicBoardPalette(
  applicationId: ApplicationId,
  payload: CreateMimicBoardPalettePayload
) {
  return api.post<MimicBoardPalette>(
    `/applications/${applicationId}/mimic-board-palettes`,
    payload
  );
}

/**
 * Deletes an existing mimic board palette belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardPaletteId The mimic board palette ID.
 */
export function deleteMimicBoardPalette(
  applicationId: ApplicationId,
  mimicBoardPaletteId: MimicBoardPaletteId
) {
  return api.delete(`/applications/${applicationId}/mimic-board-palettes/${mimicBoardPaletteId}`);
}

/**
 * Updates an existing mimic board palette belonging to the specified application.
 * @param applicationId The application ID.
 * @param mimicBoardPaletteId The mimic board palette ID.
 * @param payload The request payload used to update the mimic board palette.
 */
export function updateMimicBoardPalette(
  applicationId: ApplicationId,
  mimicBoardPaletteId: MimicBoardPaletteId,
  payload: UpdateMimicBoardPalettePayload
) {
  return api.put<MimicBoardPalette>(
    `/applications/${applicationId}/mimic-board-palettes/${mimicBoardPaletteId}`,
    payload
  );
}

/**
 * Creates a new icon for the specified mimic board palette.
 * @param applicationId The application ID.
 * @param mimicBoardPaletteId The mimic board palette ID.
 * @param payload The request payload used to create the mimic board icon.
 */
export function uploadMimicBoardPaletteIcons(
  applicationId: ApplicationId,
  mimicBoardPaletteId: MimicBoardPaletteId,
  files: FileList,
  onProgress?: (event: ProgressEvent) => void
) {
  const body = Array.from(files).reduce((acc, file) => {
    acc.append('files', file);
    return acc;
  }, new FormData());

  return api.request(
    `/applications/${applicationId}/mimic-board-palettes/${mimicBoardPaletteId}/icons`,
    'post',
    {
      data: body,
      onUploadProgress: onProgress,
    }
  );
}

/**
 * Deletes the specified icons from the mimic board palette.
 * @param applicationId The application ID.
 * @param mimicBoardPaletteId The mimic board palette ID.
 * @param mimicBoardPaletteObjectIds The mimic board palette object IDs.
 */
export function deleteMimicBoardPaletteIcons(
  applicationId: ApplicationId,
  mimicBoardPaletteId: MimicBoardPaletteId,
  mimicBoardPaletteObjectIds: MimicBoardPaletteObjectId[]
) {
  return api.post(
    `/applications/${applicationId}/mimic-board-palettes/${mimicBoardPaletteId}/icons:batchDelete`,
    {
      mimicBoardIconIds: mimicBoardPaletteObjectIds,
    }
  );
}
