import { Variants, motion } from 'framer-motion';
import { ReactNode, Suspense, forwardRef, useRef } from 'react';

import { LoadingSpinner } from 'components/loading-spinner';
import { Modal, ModalProps } from 'components/modal';
import { TransitionEasings, withDelay } from 'components/transitions';
import { VariantProps, styled } from 'stitches';

import { DialogContextProvider, useDialogContextProvider } from './DialogContext';

const DialogRoot = styled(Modal, {
  zIndex: '$3',
});

const DialogBox = styled('div', {
  backgroundColor: '#ffffff',

  position: 'relative',
  margin: '$12',

  borderRadius: '$default',
  boxShadow: '$xs, $lg',

  width: '34rem',

  variants: {
    size: {
      sm: { width: '28rem' },
      md: { width: '34rem' },
      lg: { width: '42rem' },
      xl: { width: '50rem' },
      '2xl': { width: '64rem' },
    },
  },

  defaultVariants: {
    size: 'md',
  },
});

const DialogContainer = styled('div', {
  height: '100%',

  outline: 0,

  variants: {
    scroll: {
      dialog: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [`& > ${DialogBox}`]: {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100% - $space$12)',
        },
      },
      body: {
        overflowY: 'auto',
        overflowX: 'hidden',
        textAlign: 'center',

        [`& > ${DialogBox}`]: {
          display: 'inline-block',
          verticalAlign: 'middle',
          textAlign: 'left',
        },
      },
    },
  },
});

export const DialogContent = styled('div', {
  flex: '1 1 auto',
  WebkitOverflowScrolling: 'touch',
  overflowY: 'auto',
  padding: '$5',
});

export const DialogHeader = styled('div', {
  flex: '0 0 auto',
  padding: '$5',
});

export const DialogActions = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '$4 $5',

  flex: '0 0 auto',
  gap: '$4',

  variants: {
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },

  defaultVariants: {
    justify: 'end',
  },
});

const defaultTransitions = {
  enter: {
    duration: 0.2,
    ease: TransitionEasings.easeOut,
  },
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeIn,
  },
};

const variants: Variants = {
  initial: ({ delay, transition, transitionEnd }) => ({
    opacity: 0,
    scale: 0.9,
    transition: transition?.exit ?? withDelay.exit(defaultTransitions.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
  enter: ({ delay, transition, transitionEnd }) => ({
    opacity: 1,
    scale: 1,
    transition: transition?.enter ?? withDelay.enter(defaultTransitions.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ delay, transition, transitionEnd }) => ({
    opacity: 0,
    y: 8,
    transition: transition?.exit ?? withDelay.exit(defaultTransitions.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

const AnimatedDialogBox = motion(DialogBox);

export type DialogProps = Omit<ModalProps, 'children'> & {
  /**
   * @default body
   */
  scroll?: 'body' | 'dialog';

  /**
   * The dialog content.
   */
  children?: ReactNode;

  /**
   * The dialog size.
   * @default md
   */
  size?: VariantProps<typeof DialogBox>['size'];
};

export const Dialog = forwardRef<HTMLElement, DialogProps>((props, forwardedRef) => {
  const { children, size = 'md', scroll = 'body', open, onClose, ...rest } = props;

  const context = useDialogContextProvider({
    open,
    onClose,
  });

  const contentRef = useRef<HTMLElement>(null);

  // TODO: Background click
  // useOutsideClick({
  //   ref: contentRef,
  //   handler: () => {
  //     if (open) {
  //       onClose?.(null);
  //     }
  //   },
  // });

  return (
    <DialogContextProvider value={context}>
      <DialogRoot ref={forwardedRef} open={open} onClose={onClose} {...rest}>
        <DialogContainer scroll={scroll}>
          <AnimatedDialogBox
            ref={contentRef}
            size={size}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            custom={{}}
          >
            <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
          </AnimatedDialogBox>
        </DialogContainer>
      </DialogRoot>
    </DialogContextProvider>
  );
});
