import { KeyboardEvent, MouseEvent } from 'react';

import { createContext } from 'utils';

export type DrawerContext = {
  /**
   * Whether the drawer is open.
   */
  open?: boolean;

  /**
   * Callback invoked when the drawer is closed.
   */
  onClose?(event: MouseEvent | KeyboardEvent): void;
};

export const [DrawerContextProvider, useDrawerContext] = createContext<DrawerContext>({
  name: 'DrawerContext',
  strict: true,
});

export type UseDialogContextProviderOpts = {
  /**
   * Whether the drawer is open.
   */
  open?: boolean;

  /**
   * Callback invoked when the drawer is closed.
   */
  onClose?(event: any): void;
};

export function useDrawerContextProvider(opts: UseDialogContextProviderOpts): DrawerContext {
  const { open, onClose } = opts;

  return {
    open,
    onClose,
  };
}
