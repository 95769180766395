import { motion } from 'framer-motion';
import { ReactNode, RefObject, forwardRef, useEffect, useRef } from 'react';

import { Modal } from 'components/modal';
import { ScaleFade, TransitionComponent } from 'components/transitions';
import { Placement, VirtualElement, popperCSSVars, useOutsideClick, usePopper } from 'hooks';
import { styled } from 'stitches';

import { PopoverContextProvider, usePopoverContextProvider } from './PopoverContext';

export type { Placement };

const PopoverRoot = styled(Modal, {});

const PopoverContainer = styled('div', {
  minWidth: 'max-content',
  inset: '0 auto auto 0',
});

const PopoverBox = styled('div', {
  backgroundColor: '#ffffff',
  boxShadow: '$xs, $lg',
  outline: 0,

  overflowY: 'auto',
  overflowX: 'hidden',

  borderRadius: '$default',

  transformOrigin: `${popperCSSVars.transformOrigin.varRef}`,
  maxHeight: `${popperCSSVars.maxHeight.varRef}`,

  variants: {
    matchWidth: {
      true: {
        width: '100% !important',
      },
    },
  },
});

const AnimatedPopoverBox = motion(PopoverBox);

export const PopoverContent = styled('div', {
  padding: '$4',
  maxWidth: '30rem',
});

export type PopoverProps = {
  /**
   * Whether the popover is open.
   */
  open?: boolean;

  /**
   * The popover content.
   */
  children?: ReactNode;

  /**
   * The element or element `Ref` the popover is anchored to.
   */
  anchor?: VirtualElement | HTMLElement | RefObject<HTMLElement> | null;

  /**
   * The popover placement.
   * @default bottom
   */
  placement?: Placement;

  /**
   * Whether to close the popover when a click event occurs outside of it.
   * @default true
   */
  closeOnOutsideClick?: boolean;

  /**
   * Whether to disable the `Portal`.
   */
  disablePortal?: boolean;

  /**
   * Whether to disable the focus trap.
   */
  disableFocusTrap?: boolean;

  /**
   * The transition component.
   * @default ScaleFade
   */
  transition?: TransitionComponent;

  /**
   * Whether to auto focus the popover content.
   */
  autoFocus?: boolean;

  /**
   * Whether to return focus once the popover has closed.
   */
  returnFocus?: boolean;

  /**
   * Whether to match the width of the anchor element.
   * @default false
   */
  matchWidth?: boolean;

  /**
   * Callback invoked when the popover is closed.
   */
  onClose?(): void;
};

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(function Popover(
  props,
  forwardedRef
) {
  const {
    anchor,
    children,
    open,
    placement = 'bottom',
    closeOnOutsideClick = true,
    disablePortal,
    disableFocusTrap,
    autoFocus,
    returnFocus,
    matchWidth = false,
    onClose,
    transition: Transition = ScaleFade,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const context = usePopoverContextProvider(props);
  const { getPopperNodeProps, setReferenceNode } = usePopper({
    placement,
    matchWidth,
  });

  useEffect(() => {
    setReferenceNode(isElementOrVirtualElement(anchor) ? anchor : anchor?.current ?? null);
  }, [anchor, setReferenceNode]);

  useOutsideClick({
    ref: containerRef,
    enabled: open && closeOnOutsideClick,
    handler: (event) => {
      const target = event.target as HTMLElement;
      const targetInAnchor = isElementOrVirtualElement(anchor)
        ? anchor instanceof HTMLElement
          ? anchor.contains(target)
          : anchor.contextElement?.contains(target)
        : anchor?.current?.contains(target);

      if (!targetInAnchor) {
        onClose?.();
      }
    },
  });

  return (
    <PopoverContextProvider value={context}>
      <PopoverRoot
        transparentBackdrop
        hideBackdrop={true}
        fixed={false}
        autoFocus={autoFocus}
        returnFocus={returnFocus}
        disablePortal={disablePortal}
        disableFocusTrap={disableFocusTrap}
        open={open}
        onClose={onClose}
      >
        <Transition in={open}>
          {(motionProps) => (
            <PopoverContainer {...getPopperNodeProps({}, containerRef)}>
              <AnimatedPopoverBox
                ref={forwardedRef}
                role="dialog"
                matchWidth={matchWidth}
                // TODO: Remove any cast
                {...(motionProps as any)}
                {...rest}
              >
                {children}
              </AnimatedPopoverBox>
            </PopoverContainer>
          )}
        </Transition>
      </PopoverRoot>
    </PopoverContextProvider>
  );
});

function isElementOrVirtualElement(element: any): element is HTMLElement | VirtualElement {
  if (element instanceof HTMLElement) {
    return true;
  } else if (typeof element?.getBoundingClientRect === 'function') {
    return true;
  }

  return false;
}
