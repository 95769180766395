import { CollectionQuery, CollectionSortDir, CollectionSortOption } from 'hooks';
import { createContext } from 'utils';

export interface EntityListContext<T> {
  items: T[];
  isFiltered: boolean;
  pageCount?: number;
  sorting?: CollectionSortOption<T>[];
  query: CollectionQuery;
  queryString: string;
  getFilter(key: string): string[];
  setQueryString(value: string): void;
  setSorting(prop: string, dir: CollectionSortDir): void;
  setFilter(key: string, value: string | null): void;
  setPage(page: number): void;
  resetQuery(): void;
}

export const [EntityListContextProvider, useEntityListContext] = createContext<
  EntityListContext<any>
>({
  name: 'EntityListContext',
});
