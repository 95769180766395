import { selectorFamily, useRecoilValue } from 'recoil';

import { getOrganizationTheme } from 'api/organizations';
import { currentOrganization, queryClient } from 'app';
import { usePreferenceState } from 'data/preferences';
import { OrganizationId } from 'interfaces';

import { createTheme } from './stitches';
import { createPalette, getContrastingTextColor } from './utils';

type ThemeSelectorReturn = ReturnType<typeof createTheme> | null;

export const organizationThemeSelector = selectorFamily<ThemeSelectorReturn, OrganizationId>({
  key: 'organizationTheme',
  get: (organizationId) => async () => {
    const orgTheme = await queryClient.fetchQuery(['organizations', organizationId, 'theme'], () =>
      getOrganizationTheme(organizationId)
    );

    if (!orgTheme?.isEnabled) {
      return null;
    }

    const palette = orgTheme.primaryColor ? createPalette(orgTheme.primaryColor) : {};

    return createTheme({
      colors: {
        ...palette,
        ...(orgTheme.navbarColor && {
          'navbar-bg': orgTheme.navbarColor,
          'navbar-text': getContrastingTextColor(orgTheme.navbarColor),
        }),
      },
      logo: {
        ...(orgTheme.iconUri && {
          uri: orgTheme.iconUri,
        }),
      },
    });
  },
});

export const themeSelector = selectorFamily<ThemeSelectorReturn, { themeMode: string }>({
  key: 'theme',
  get:
    ({ themeMode }) =>
    async ({ get }) => {
      if (themeMode !== 'organization') {
        return null;
      }

      const organization = get(currentOrganization);
      if (!organization) {
        return null;
      }

      return get(organizationThemeSelector(organization.id));
    },
});

export function useTheme() {
  const [themeMode] = usePreferenceState({ key: 'themeMode', defaultValue: 'organization' });
  const theme = useRecoilValue(themeSelector({ themeMode }));

  return theme;
}
