import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import * as pipelines from 'api/pipelines';
import { IconButton } from 'components/button';
import { Link } from 'components/link';
import { ListItem, ListItemAdornment, ListItemContent, ListItemMeta } from 'components/list';
import { useDownloadDataImportedFile } from 'data/data-import-groups';
import { useUpdateNotificationStatus } from 'data/notifications';
import { useAsyncCallback } from 'hooks';
import { Notification, NotificationStatus, PipelineDefinition } from 'interfaces';
import { formatDateDistanceToNow } from 'utils';

export type NotificationItemProps = {
  notification: Notification;
  onNavigate?(): void;
  onDataImportRollback?(notification: Notification): void;
};

export function NotificationItem(props: NotificationItemProps) {
  const { notification, onDataImportRollback, onNavigate } = props;
  let navigate = useNavigate();

  const updateNotificationStatus = useUpdateNotificationStatus();
  const handleClick = () => {
    if (notification.status === NotificationStatus.Unread && updateNotificationStatus) {
      updateNotificationStatus(notification.id);
    }
  };

  const downloadImportedFile = useDownloadDataImportedFile(
    notification.applicationId ?? 0,
    notification.resourceId ?? 0
  );

  const [handleDownloadImportedFile] = useAsyncCallback(async () => {
    await downloadImportedFile();
  }, [downloadImportedFile]);

  const onPipelineNavigate = async (
    e: React.MouseEvent<Element, MouseEvent>,
    notification: Notification
  ) => {
    e.preventDefault();
    try {
      var result: PipelineDefinition = await pipelines.getPipelineDefinition(
        notification!.applicationId!,
        notification!.resourceId!
      );

      if (!result) navigate(`${notification.applicationPath}/pipelines`);
      else navigate(`${notification.applicationPath}/pipelines/${notification.resourceId}`);
    } catch (ex) {
      navigate(`${notification.applicationPath}/pipelines`);
    } finally {
      onNavigate?.();
    }
  };

  return (
    <ListItem disableGutters="x">
      <ListItemContent>
        {notification.key === 'DataImportFileUploadResult' ? (
          <Link
            to={'#'}
            onClick={() => {
              onNavigate?.();
              handleDownloadImportedFile();
            }}
          >
            {notification.title}
          </Link>
        ) : notification.key === 'DataImportFileUploadSuccessResult' ? (
          <Link to={'#'} onClick={() => onDataImportRollback?.(notification)}>
            {notification.title}
            {notification.title}
          </Link>
        ) : notification.key === 'ScenarioStatusCheck' ? (
          <Link
            to={`${notification.applicationPath}/scenarios/editor?scenarioId=${notification.resourceId}`}
            onClick={onNavigate}
          >
            {notification.title}
          </Link>
        ) : notification.key === 'RunPipelineJob' ? (
          <Link to={'#'} onClick={(e) => onPipelineNavigate(e, notification)}>
            {notification.title}
          </Link>
        ) : (
          <Link to="#" onClick={onNavigate}>
            {notification.title}
          </Link>
        )}

        <ListItemMeta>{notification.message}</ListItemMeta>
        <ListItemMeta>{formatDateDistanceToNow(notification.created)} ago</ListItemMeta>
      </ListItemContent>

      <ListItemAdornment css={{ minWidth: 30 }}>
        {notification.status === NotificationStatus.Unread && (
          <IconButton onClick={handleClick}>
            <FiCheck />
          </IconButton>
        )}
      </ListItemAdornment>
    </ListItem>
  );
}
