import { Children, MouseEvent, cloneElement, forwardRef, isValidElement } from 'react';
import { FiCheck } from 'react-icons/fi';

import { FlexItem } from 'components/flex';
import { useSelectionState } from 'hooks';

import { MenuItem, MenuItemProps } from './MenuItem';
import { MenuList, MenuListProps } from './MenuList';

export type MenuOptionItemProps<T = unknown> = MenuItemProps & {
  /**
   * The option value.
   */
  value?: T;

  /**
   * Whether the item is checked.
   */
  checked?: boolean;

  /**
   * The option type.
   */
  type?: 'checkbox' | 'radio';
};

export const MenuOptionItem = forwardRef<HTMLDivElement, MenuOptionItemProps>(
  function MenuOptionItem(props, forwardedRef) {
    const { children, checked = false, type = 'radio', ...rest } = props;

    return (
      <MenuItem
        ref={forwardedRef}
        role={`menuitem${type}`}
        aria-checked={checked}
        // TODO: Remove any cast
        {...(rest as any)}
      >
        <FlexItem shrink={0} css={{ flexBasis: '$5' }}>
          {checked ? <FiCheck /> : null}
        </FlexItem>

        {children}
      </MenuItem>
    );
  }
);

export type MenuOptionGroupProps<T = unknown> = MenuListProps & {
  /**
   * The selected value.
   */
  value?: T;

  /**
   * The default value.
   */
  defaultValue?: T;

  /**
   * Whether multiple values are supported.
   * @default false
   */
  multiple?: boolean;

  /**
   * Callback invoked when the value is changed.
   * @param value The new value.
   */
  onChange?(value: T): void;
};

export const MenuOptionGroup = forwardRef<HTMLDivElement, MenuOptionGroupProps>(
  function MenuOptionGroup(props, forwardedRef) {
    const {
      children: childrenProp,
      value,
      defaultValue,
      multiple = false,
      onChange,
      ...rest
    } = props;

    const { toggle, select, isSelected } = useSelectionState({
      initialValue: value ?? defaultValue,
      multiple,
      onChange,
    });

    const type = multiple ? 'checkbox' : 'radio';
    const children = Children.map(childrenProp, (child) => {
      if (!isValidElement(child)) {
        return null;
      }

      let childValue = child.props.value;

      const onClick = (event: MouseEvent<any>) => {
        multiple ? toggle(childValue) : select(childValue);
        child.props.onClick?.(event);
      };

      return cloneElement(child, {
        type,
        checked: isSelected(childValue),
        value: undefined,
        onClick,
      });
    });

    return (
      <MenuList ref={forwardedRef} {...rest}>
        {children}
      </MenuList>
    );
  }
);
