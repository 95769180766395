import { FiX } from 'react-icons/fi';

import { Box } from 'components/box';
import { Button, IconButton } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogDismiss,
  DialogHeader,
} from 'components/dialog';
import { Flex } from 'components/flex';
import { Switch } from 'components/switch';
import { Text } from 'components/text';
import { usePreferenceState } from 'data/preferences';

import { LayoutPreview } from './LayoutPreview';

export type PreferencesDialogProps = {
  isOpen?: boolean;
  onClose?(): void;
};

export function PreferencesDialog(props: PreferencesDialogProps) {
  const { isOpen, onClose, ...rest } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <PreferencesDialogContent {...rest} />
    </Dialog>
  );
}

function PreferencesDialogContent() {
  const [themeMode, setThemeMode] = usePreferenceState({
    key: 'themeMode',
    defaultValue: 'organization',
  });

  const [navOrientation, setNavOrientation] = usePreferenceState<'vertical' | 'horizontal'>({
    key: 'navbarOrientation',
    defaultValue: 'vertical',
  });

  return (
    <>
      <DialogHeader>
        <Flex align="center" justify="space-between">
          <Text variant="title">Preferences</Text>

          <DialogDismiss>
            <IconButton tabIndex={-1}>
              <FiX />
            </IconButton>
          </DialogDismiss>
        </Flex>
      </DialogHeader>

      <DialogContent>
        <Box gutterBottom="lg">
          <Text as="div" variant="subtitle" gutterBottom="lg">
            Theme
          </Text>

          <Switch
            checked={themeMode !== 'organization'}
            onChange={(value) => setThemeMode(value ? 'application' : 'organization')}
          >
            Disable organization theme
          </Switch>
        </Box>

        <Box>
          <Text as="div" variant="subtitle" gutterBottom="lg">
            Layout orientation
          </Text>

          <Box
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              gridAutoFlow: 'row',
              gap: '$6',
            }}
          >
            <LayoutPreview
              orientation="horizontal"
              selected={navOrientation === 'horizontal'}
              onSelect={() => setNavOrientation('horizontal')}
            />

            <LayoutPreview
              orientation="vertical"
              selected={navOrientation === 'vertical'}
              onSelect={() => setNavOrientation('vertical')}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <DialogDismiss>
          <Button type="button">Close</Button>
        </DialogDismiss>
      </DialogActions>
    </>
  );
}
