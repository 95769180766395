import { motion } from 'framer-motion';
import { ComponentProps, forwardRef } from 'react';

import { Fade } from 'components/transitions';
import { styled } from 'stitches';

export const StyledBackdrop = styled('div', {
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  display: 'flex',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,

  // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundColor: 'rgba(9, 30, 66, 0.54)',
  WebkitTapHighlightColor: 'transparent',

  variants: {
    transparent: {
      true: {
        backgroundColor: 'transparent',
      },
    },
  },
});

const AnimatedBackdrop = motion(StyledBackdrop);

export type BackdropProps = ComponentProps<typeof StyledBackdrop>;

export const Backdrop = forwardRef<HTMLDivElement, BackdropProps>(function Backdrop(
  props,
  forwardedRef
) {
  // Don't bother with the animation if using the transparent variant.
  // TODO: Remove any cast
  if (props.transparent) {
    return <StyledBackdrop ref={forwardedRef} {...(props as any)} />;
  }

  // TODO: Remove any cast
  return (
    <Fade in>
      {(motionProps) => (
        <AnimatedBackdrop ref={forwardedRef} {...(props as any)} {...motionProps} />
      )}
    </Fade>
  );
});
