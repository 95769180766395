import Fuse from 'fuse.js';
import { useMemo } from 'react';

// eslint-disable-next-line import/no-named-as-default-member
export interface UseFuseProps<T> extends Fuse.IFuseOptions<T> {
  items?: T[] | null;
}

/**
 * Returns a `Fuse` instance.
 */
export function useFuse<T>(props: UseFuseProps<T>): Fuse<T> {
  const { items, ...options } = props;

  const fuse = useMemo(() => new Fuse<T>(items || [], options), [items, options]);

  return fuse;
}
