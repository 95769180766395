import { styled } from 'stitches';

export const Card = styled('div', {
  backgroundColor: '#ffffffff',
  boxShadow: '$default',
  borderRadius: '$default',

  '&:not(:last-child)': {
    marginBottom: '$9',
  },
});

export const CardContent = styled('div', {
  padding: '$5',
});

export const CardActions = styled('div', {
  padding: '$4 $5',

  alignItems: 'center',
  display: 'flex',
  gap: '$4',

  backgroundColor: '$neutral-50',
  borderBottomLeftRadius: '$md',
  borderBottomRightRadius: '$md',

  variants: {
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
  },

  defaultVariants: {
    justify: 'end',
  },
});
