import { atom, atomFamily, selector, useRecoilValue } from 'recoil';

import { getApplication } from 'api/applications';
import { getOrganization } from 'api/organizations';
import { isAdminSelector } from 'auth';
import { Application, ApplicationId, Organization, ScenarioId, WorkflowId } from 'interfaces';
import { createAtomFamily, createLocalStorageEffect, createSessionStorageEffect } from 'utils';

import { queryClient } from './queryClient';

export const applicationPathState = atom<string | null>({
  key: 'currentApplicationPath',
  default: null,
});

export const currentApplication = selector<Application | null>({
  key: 'currentApplication',
  get: async ({ get }) => {
    const appPath = get(applicationPathState);
    if (!appPath) {
      return null;
    }

    const application = await queryClient.fetchQuery(
      ['applications', appPath],
      () => getApplication(appPath),
      {
        initialData: () =>
          queryClient
            .getQueryData<Application[]>('applications')
            ?.find((x) => x.slug === appPath.toLocaleLowerCase()),
      }
    );

    return application;
  },
});

export const organizationPathState = atom<string | null>({
  key: 'currentOrganizationPath',
  default: null,
});

export const currentOrganization = selector<Organization | null>({
  key: 'currentOrganization',
  get: async ({ get }) => {
    const orgPath = get(organizationPathState);
    if (!orgPath) {
      return null;
    }

    const organization = await queryClient.fetchQuery(
      ['organizations', orgPath],
      () => getOrganization(orgPath),
      {
        initialData: () =>
          queryClient
            .getQueryData<Organization[]>('organizations')
            ?.find((x) => x.path === orgPath.toLocaleLowerCase()),
      }
    );

    return organization;
  },
});

const currentApplicationMaintainer = selector<boolean>({
  key: 'currentApplicationMaintainer',
  get: ({ get }) => {
    const isAdmin = get(isAdminSelector);
    if (isAdmin) {
      return true;
    }

    const organization = get(currentOrganization);
    return !!organization?.permissions.includes('model.admin');
  },
});

const currentOrganizationMaintainer = selector<boolean>({
  key: 'currentOrganizationMaintainer',
  get: ({ get }) => {
    const isAdmin = get(isAdminSelector);
    if (isAdmin) {
      return true;
    }

    const organization = get(currentOrganization);
    return !!organization?.permissions.includes('organization.admin');
  },
});

export function useCurrentApplication(strict = true): Application {
  const application = useRecoilValue(currentApplication);
  if (!application && strict) {
    throw new Error(`Current application should not be null.`);
  }

  return application!;
}

export function useCurrentApplicationMaintainer() {
  return useRecoilValue(currentApplicationMaintainer);
}

export function useCurrentOrganizationMaintainer() {
  return useRecoilValue(currentOrganizationMaintainer);
}

export function useCurrentOrganization(strict = true): Organization {
  const organization = useRecoilValue(currentOrganization);
  if (!organization && strict) {
    throw new Error(`Current organization should not be null.`);
  }

  return organization!;
}

export type CurrentWorkflowIdParam = {
  applicationId: ApplicationId;
};

export const currentWorkflowIdState = atomFamily<WorkflowId | null, CurrentWorkflowIdParam>({
  key: 'currentWorkflowId',
  default: null,
  effects: (params) => [createLocalStorageEffect(`currentWorkflow.${params.applicationId}`)],
});

export type WorkflowStateParam = {
  workflowId: WorkflowId;
};

export type WorkflowState = {
  /**
   * The scenario associated with the workflow.
   */
  scenarioId: ScenarioId | null;
};

/**
 * Tracks the state of the particular workflow.
 */
const { atom: workflowState, createKeyedSelectorFamily } = createAtomFamily<
  WorkflowState,
  WorkflowStateParam
>({
  key: 'workflow',
  defaultValue: { scenarioId: null },
  effects: (params) => [createSessionStorageEffect(`workflowState.${params.workflowId}`)],
});

export { workflowState };

export const workflowScenarioIdState = createKeyedSelectorFamily('scenarioId');
